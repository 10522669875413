.Sidebar_container {
  position: absolute;
  left: 6%;
  top: 0;
  z-index: 2;
  width: 15vw;
  background-color: var(--primary2ndColor);
  padding: 1rem;
  border-radius: 6px;
  display: flex;
  gap: 0.7rem;
  flex-direction: column;
}

.sidebar_bottom {
  padding: 1rem;
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.sidebar_bottom button:nth-child(1) {
  font-size: 1rem;
  font-weight: 500;
  background: transparent;
  border: none;
  outline: none;
  color: var(--baseColor);
  cursor: pointer;
}

.sidebar_bottom button:nth-child(2) {
  font-size: 1rem;
  font-weight: 500;
  border-radius: 16px;
  padding: 0.3rem 0.8rem;
  cursor: pointer;
  color: var(--baseColor);
  border: 2px solid var(--baseColor);
  background-color: var(--primaryColor);
}

.applyNowButtonFilter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.applyNowButtonFilter button {
  width: 50%;
  cursor: pointer;
  font-size: 2vh;
  padding: 0.2vh 1.8vh;
  font-weight: 600;
  color: var(--primaryTextColor);
}

.slider_cross_btn {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 3%;
}

.slider_cross_btn p {
  color: var(--boxColor);
  font-size: 1rem;
  font-weight: 600;
  padding: 0 1%;
  cursor: pointer;
}

.slider_cross_btn p:hover {
  background-color: var(--primaryTextColor);
}

@media screen and (max-width: 768px) {
  .Sidebar_container {
    width: 80vw;
    left: 10%;
    top: 13%;
    position: fixed;
  }

  .applyNowButtonFilter {
    padding: 2% 3%;
    width: 100%;
    display: flex;
    gap: 1%;
  }

  .applyNowButtonFilter button {
    padding: 1% 2%;
    font-size: 1rem;
    font-weight: 500;
    border: none;
    width: 50%;
  }

  .applyNowButtonFilter button:hover {
    background-color: var(--primaryColor);
    color: var(--baseColor);
  }
}
