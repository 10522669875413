.address_container2 {
  display: flex;

  flex-direction: column;
  width: 84%;
  gap: 2%;
  overflow: hidden;
  height: 74vh;
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 2px solid var(--primaryTextColor);
  background-color: var(--secondaryColor);
  padding: 1%;
  border-radius: 8px;
  z-index: 5;
}
.address_container_slider {
  /* height: 90%;
  display: grid;
  overflow-y: auto;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  grid-gap: 0.9rem;
  grid-template-rows: 12.5rem; */
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
}

/* .address_container_slider {
    overflow-y: scroll;
    box-shadow: 2px 4px 5px var(--secondaryTextColor);
} */

.select_address_heading_popup {
  display: flex;
  align-items: center;
  color: var(--secondaryTextColor);
  justify-content: space-between;
}

.address_container2_cross {
  cursor: pointer;
  font-size: 4vh;
  font-weight: 500;
  color: var(--primaryTextColor);
  display: flex;
  width: 10%;
  justify-content: flex-end;
}

.address_container2::-webkit-scrollbar {
  width: 12px;
  border-radius: 8px;
}

.address_container2::-webkit-scrollbar-track {
  background: var(--baseColor);
}

.address_container2::-webkit-scrollbar-thumb {
  background: var(--secondaryColor);
  border-radius: 6px;
}

.address_item2 {
  display: flex;
  text-align: start;
  color: var(--primaryTextColor);
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  background-color: var(--thirdBackground);
  /* width: 100%; */
  height: 100%;
  padding: 1rem 1rem;
  box-shadow: 0 6px 8px var(--secondaryTextColor);
  cursor: pointer;
  min-height: fit-content;

  
  /* max-height: fit-content;
  background-color: var(--thirdBackground);
  padding: 1rem;
  border-radius: 2px; */
}
.MobileUser,
.emailUser {
  display: inline-block;
}
.address_item2 p {
  font-size: 2.3vh;
  font-weight: 500;
}
p.namOfUserInPopUp {
  font-weight: 700;
}
.userCityState {
  display: flex;
}

@media screen and (max-width: 764px) {
  .address_container2 {
    width: 90%;
  }
  .address_item2 {
    flex-wrap: wrap;
  }
  .address_item2 p {
    font-size: 1.5vh;
  }
  .address_container2 {
    height: 80vh !important;
    position: fixed;
    top: 500px !important;
  }
}
@media (max-width: 1180px) {
  .address_container_slider {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 1rem;
    grid-template-rows: 12.5rem;
    grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  }
}
