.productCategories {
  padding: 3rem;
  width: 100%;
  height: fit-content;
  background: var(--primaryColor);
}

.productCategories>h1 {
  text-align: center;
  color: var(--baseColor);
}

.categoriesCarousal {
  width: 100%;
  margin: 3rem 0;
}

.categoriesSlides{
    width: 95%;
    height: 16.875rem;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    row-gap: 1rem;
    background: var(--baseColor);
    margin: auto;
    border-radius: 3rem;
    cursor: pointer;
}

.categoriesSlides>div {
  width: 70px;
  height: 70px;
  margin: auto;
}

.categoriesSlides>div>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.categoriesSlides>button {
  width: fit-content;
  margin: auto;
  padding: 0.5rem 1rem;
  border-radius: 5rem;
  color: var(--primaryTextColor);
  border: 2px solid var(--primaryTextColor);
  font-weight: bold;
  cursor: pointer;
}
.categoriesSlides>button:hover{
  color: white;
  background-color:var(--primaryTextColor) ;
  transition: 1s ease-in-out;
}

.prevCat {
  left: -2rem !important;
}

.nextCat {
  right: -2rem !important;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .productCategories>h1 {
    font-size: 1.5rem;
  }

  .productCategories {
    padding: 3% 1% 1% 1%;
  }

  .categoriesSlides>button {
    padding: 0.3rem 0.4rem;
    font-size: 0.625rem;
  }

  .categoriesSlides {
    height: 11.625rem;
  }
.categoriesSlides p{
  font-size: 1.6vh;
  font-weight: 500;
}
  .categoriesCarousal {
    margin: 2rem 0;
  }
  .prevCat {
    left: 0 !important;
  }
  
  .nextCat {
    right: 0 !important;
  }
}