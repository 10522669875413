.section1-main {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin: 0 0 1rem 0;
}

.section1content {
  width: 100%;
  height: 70vh;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 2rem 0 0;
}

.section1text {
  width: 55%;
  padding: 0 3rem;
  overflow-y: scroll;
  height: 100%;
}

.section1text h3 {
  color: var(--primaryTextColor);
  font-family: Montserrat;
  font-size: 1.25rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}

.section1text h5 {
  color: var(--secondaryTextColor);
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2rem;
}

.section1text li {
  color: var(--secondaryTextColor);
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  position: relative;
  margin: 1rem;
}

.section1img {
  width: 50%;
}

.section1img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media screen and (max-width: 768px) {
  .section1content {
    flex-direction: column;
  }

  .section1text {
    width: 100%;
  }

  .section1text h3 {
    font-size: 1.25rem;
  }

  .section1img {
    width: 100%;
    order: -1;
  }

  .section1img img {
    height: 50vh;
  }
}

@media screen and (max-width: 465px) {
  .section1img img {
    height: 30vh;
  }

  .section1text {
    padding: 0 1.5rem;
  }
}