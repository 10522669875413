.function_card {
    width: 23.25rem;
    height: 26rem;
    overflow: auto;
    border-radius: 10px;
    background-color: var(--commonWhite);
}

.function_card_head {
    display: flex;
    justify-content: space-between;
}

.f_card_arrow {
    color: var(--secondaryTextColor);
    font-size: 1.8rem;
    cursor: pointer;
}

.f_card_cross {
    color: #F24E1E;
    font-size: 1.5rem;
    cursor: pointer;
}

/* /// form css /// */
.form_first {
    width: 100%;
}

.allLabService {
    width: 100%;
    height: 280px;
}

.form_data {
    padding: 1rem 1rem 0;
}

.labradio {
    width: 100%;
    display: flex;
    align-items: flex-start;
    column-gap: 1rem;


}

.radioButton {
    margin-top: 10px;
    font-size: 1rem;

}
.radioButton+.labCss:before{
    border: 2px solid #919daa;
}
.labCss {

    font-size: 1.5rem;
    color: var(--primaryTextColor);
}

.labCss>p {

    font-size: 1.5rem;
    color: var(--primaryTextColor);
}


.function_card h2 {
    font-size: 1.25rem;
    font-weight: 700;
    color: var(--primaryTextColor);
}


.process_accept {
    position: relative;
    display: flex;
    height: 1.8rem;
    width: 1.8rem;
    cursor: pointer;

}

.process_accept .process_circle {
    background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
    border-radius: 50%;
    height: 1.8rem;
    width: 1.8rem;
    cursor: pointer;
}

.process_check {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-40%, -40%);
    color: var( --commonWhite);
    cursor: pointer;
    display: none;
}

.process_check_active {
    display: block;
}

.function_card form label {
    font-size: 1rem;
    font-weight: 600;
    color: var(--primaryTextColor);
}


.form_data div:nth-child(2) {
    width: 70%;
}

/*//// form Two //// */
.form_two {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: fit-content;
    gap: 1rem !important;
    flex-direction: column;
    padding: 1rem;
}


.form_two_first_div {
    display: flex;
    column-gap: 2rem;
}

.form_two_first_div>div {
    display: flex;
    align-items: center;
}

/* Add this CSS to your stylesheet */
/* Hide the default checkbox */
.checkbox {
    display: none;
}

/* Style the custom checkbox container */
.checkboxContainer {
    display: flex;
    align-items: center;
}

/* Style the custom checkbox */
.checkboxLabel {
    display: inline-block;
    width: 30px;
    height: 30px;
    border: 2px solid var( --commonWhite);
    /* Border color */
    border-radius: 3px;
    margin-right: 10px;
    cursor: pointer;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkIcon {
    display: none;
}

/* Style the checked state of the custom checkbox */
.checkbox:checked+.checkboxLabel {
    background-color: var(--primaryTextColor);
    /* Change color as per your design */
    border-color: var(--secondaryColor);
    /* Change color as per your design */
}

/* Style the checked state of the custom checkbox */
.checkbox:checked+.checkboxLabel+.checkIcon {
    display: block;
    /* Show the check icon when checkbox is checked */
    color: var( --commonWhite);
    /* Change color as per your design */
}



.form_two_second_div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 2rem
}

.form_two_second_div div {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.form_two_second_div label {
    font-size: 1rem;
    font-weight: 600;
    color: var(--secondaryTextColor) !important;
}

.form_two_second_div input {
    width: 65%;
    border: none;
    outline: none;
    border-bottom: 2px solid var(--primaryTextColor);
}

.form_four,
.form_five {
    width: 100%;
    height: 90%;
    position: relative;
    overflow: hidden;
}

.form_four>img,
.form_five>img {
    width: 100%;
    height: 70%;
    object-fit: contain;
    position: absolute;
    bottom: 0;
    padding: 1rem;

}

.form_four>h4>span {
    color: var( --primaryColor);
}

.form_four>h4 {
    text-align: center;
}


/* ////// // */
.function_bottom {
    display: flex;
    justify-content: center;
    align-items: center;
}

.function_bottom>button,
.processButton {
    padding: 0.5rem 1.6rem;
    border-radius: 3.125rem;
    font-size: 1rem;
    font-weight: 500;
    color: var(--primaryTextColor);
    border: 2px solid var(--primaryTextColor);
    cursor: pointer;
    margin: 2rem 0 0 0;
}

.function_bottom button:hover,
.processButton:hover {
    background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
    color: var(--baseColor);
    box-shadow: 2px 4px 6px 1px var(--secondaryColor);
    border: none;
}

.processButton {
    margin-left: 7.5rem;
    margin-top: 2rem;
}

.dateForm {
    display: flex;
    column-gap: 1rem;
    align-items: center;
}

.dateForm>label {
   min-width: 100px;

}

.dateForm>input {
    padding: .5rem;
    font-size: .8rem;
    outline: none;
    width: 100%;

}

.selectTime {
    padding: .5rem 1.8rem;
    font-size: .93rem;
    outline: none;
}


/* Hide the default radio button */
.radioButton {
    display: none;
}

/* Style the custom radio button */
.labradio {
    margin-bottom: 10px;
    padding: 4px;

}

.labradio>div {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: green;
}

.labCss {
    display: flex;
    align-items: center;
    cursor: pointer;
    flex-wrap: wrap;
}

.payment>h3 {
    color: var(--secondaryTextColor);
}

.labCss:before {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid var(--commonWhite);
    margin-right: 10px;
}

.labLebel{
    color: var(--primaryTextColor);
    font-size: 1rem;
}
/* Style the checked state of the custom radio button */
.radioButton:checked+.labCss:before {
    background-color: #007bff;
    /* Change color as per your design */
    border-color: #007bff;
    /* Change color as per your design */
}

.priceCss {
    width: 100%;
    padding: 0 2rem;
    color: var(--secondaryTextColor);
}

@media only screen and (max-width: 768px) {
    .function_card {
        width: 100%;
       
    }
}