* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

#floating_icon_div {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3.125rem;
    width: 3.125rem;
    position: fixed;
    bottom: 8%;
    right: 3%;
    z-index: 10;
    color: var(--baseColor);
    background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
    border-radius: 50%;
    font-weight: 400;
    box-shadow: inset 2px 3px 5px var(--secondaryColor);
    cursor: pointer;
}

#floating_icon {
    font-size: 2rem;
    color: var(--baseColor);
}

#floating_icon_div_num{
    position: absolute;
    top: 0;
    color: var(--numColor);
    font-size: 1rem;
    font-weight: 600;
}