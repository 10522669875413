.blog_des_content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: url('../../../Assets/Images/blogs/hero2.jpeg');
    background-size: cover;
    background-position: center;
    filter: brightness(0.4);
    height: 100vh;
}

.blog_des_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    filter: brightness(1);
    text-align: center;
    width: 100%;
    padding: 0 2rem;
    line-height: 7rem;
}

.blog_des_text h1 {
    font-family: Montserrat;
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 1px;
    z-index: 100;
    filter: brightness(1);
    color: var(--buttonColor);
}

.blog_des_text p {
    font-size: 0.9rem;
    line-height: 1.3rem;
    color: var(--buttonColor);
}

/* Responsive adjustments */
@media screen and (max-width: 768px) {
    .blog_des_text {
        line-height: 3rem; 
        padding: 0;
    }
    .blog_des_content{
        padding:2%;

    }

    .blog_des_text h1 {
        font-size: 2.25rem; 
    }

}

@media screen and (max-width: 480px) {
}