.profile_container {
  width: 100%;
  padding: 2rem;
  position: relative;
}
.spinner {
  
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.profile_content {
  display: flex;
  gap: 2rem;
  padding: 2rem 0;
}

.profile_left_div {
  width: 50%;
}

.profile_card {
  display: flex;
  gap: 1vw;
  padding: 3% 1%;
  align-items: center;
  height: 34vh;
  border-radius: .5rem;
  background-color: var(--baseColor);
  box-shadow: 2px 3px 4px var(--boxColor);
  overflow: hidden;
}

.profile_card_img {
  height: 10em;
  width: 30%;
  width: 10em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid var(--primaryTextColor);
}

.EmailProfileScreenTextDiv {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 0.3rem;
  overflow: hidden;
}

.VerifyBtnBlue {
  padding: 8% 24%;
  font-size: 1vw;
  color: var(--primaryTextColor);
  font-weight: 600;
  border: 2px solid var(--primaryTextColor);
  background-color: transparent;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0.9rem;
}

.VerifyBtnBlue:hover {
  background-color: var(--primaryColor);
  color: var(--baseColor);
}

.VerifyBtnGreen {
  padding: 7% 14%;
  border: 2px solid rgb(32, 173, 32);
  color: rgb(32, 173, 32);
  background-color: transparent;
  font-weight: 600;
  font-size: 1vw;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.3);
  border-radius: 0.9rem;
}

.profile_card_img h2 {
  font-size: 11vh;
  font-weight: 600;
  color: var(--primaryTextColor);
  border-radius: 50%;
  text-shadow: 2px 3px 5px var(--primaryTextColor);
  cursor: pointer;
}

.profile_card_text {
  display: flex;
  flex-direction: column;
  gap: 1vh;
  width: 76%;
  justify-content: center;
}

.profile_card_text p span {
  font-size: 1vw;
  font-weight: 500;
  color: var(--secondaryTextColor);
}

.profile_card_text p {
  font-size: 1vw;
  padding: 1%;
  font-weight: 700;
  color: var(--primaryTextColor);
}

.profile_card_text input {
  width: 35%;
  border: 1px solid var(--primaryTextColor);
  background: none;
  outline: none;
  border-radius: 11px;
  height: 5vh;
  padding: 1rem;
}

#icon {
  font-size: medium;
  color: var(--primaryTextColor);
  border-radius: 11px;
  border: 2px solid var(--primaryTextColor);
  padding: 0.8% 1.4%;
  cursor: pointer;

}

#icon:hover {
  background-color: var(--primaryColor);
  color: var(--baseColor);
}

.profile_right_div {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.profile_right_div_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.profile_right_div_text h3 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primaryTextColor);
}

.profile_right_div_text h3 span {
  color: var(--secondaryTextColor);
}

.profile_right_div_text p {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--primaryTextColor);
}

.profile_right_div_text .status-container {
  width: 70%;
  font-weight: 500;
  color: var(--primaryTextColor);
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.profile_right_div_text progress {
  width: 70%;
  height: 4vh;
}

.profile_otp_div {
  margin: 1% 0;
  display: flex;
  align-items: center;
}
.use_email_span{
  width: 55% !important;
  /* background-color: rgb(244, 206, 206); */
  font-size: 1.5vh;
  font-weight: 500;
  color: var(--secondaryTextColor);
  overflow: hidden;      
  white-space: nowrap;   
  text-overflow: ellipsis;          
}

@media only screen and (max-width: 768px) {
  .profile_container {
    padding: 3%;
  }

  .profile_card_img h2 {
    font-size: 3rem;
  }

  .profile_content {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 2rem 0;
  }

  .profile_right_div {
    width: 100%;
  }
  .profile_left_div{
    width: 100%;
    height: 30vh;
    padding: 0rem;
  }

  .profile_card {
    padding: 3% 0%;
    gap: 2vw;
    height: auto;
    flex-direction: column;
    border-radius:0.600rem;
  }

  .VerifyBtnGreen,
  .VerifyBtnBlue {
    font-size: 1.3vh;
    padding: 8%;
    border: 1px solid var(--primaryTextColor);
    border-radius: 4px;
  }

  .profile_card_img {
    height: 4rem;
    width: 4rem;
    padding: 5%;
    align-items: center;
  }

  .profile_card_img img {
    width: 100%;
    border-radius: 100%;
    border: 2px solid var(--primaryTextColor);
  }

  .profile_card_text {
    width: 90%;
    gap: 0.4vh;
  }

  .profile_right_div_text {
    gap: 1rem;
    padding-top: 2vh;
  }

  .profile_right_div_text h3 {
    font-size: 2rem;
  }

  .profile_right_div_text p {
    font-size: 1rem;
  }

  .profile_card_text p span {
    /* background-color: red; */
    font-size: 1.5vh;
    font-weight: 500;
    color: var(--secondaryTextColor);
  }
  .use_email_span{
    width: 45% !important;
    /* background-color: rgb(244, 206, 206); */
    font-size: 1.5vh;
    font-weight: 500;
    color: var(--secondaryTextColor);
    overflow: hidden;      
    white-space: nowrap;   
    text-overflow: ellipsis;          
  }

  #user_email {
    width: unset;
    overflow: hidden;
  }

  /* #user_email:hover {
    width: 100%;
    overflow: visible;
  } */

  .profile_card_text p {
    font-size: 1.5vh;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 0.3rem;
    font-weight: 700;
    color: var(--primaryTextColor);
  }

  #icon {
    font-size: x-small;
    border: 1px solid var(--primaryTextColor);
    border-radius: 4px;
    font-weight: 500;
  }

  .profile_right_div_text .status-container {
    width: 100%;
    gap: 1%;
  }

  .profile_right_div_text progress {
    width: 100%;
  }

  .profile_card_text input{
    width: 23%;
    border-radius: 3px;
    height: 3vh;
    font-size: 1vh;
    font-weight: 600;
    padding: 0.625rem;
  }
}