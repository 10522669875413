.book_popup_container {
  width: 50vw;
  height: fit-content;
  position: fixed;
  /* top: 50%;
  left: 50%; */
  transform: translate(-50%, 8%);
  z-index: 1000;
  background-color: var(--commonWhite);
}

.popup_header {
  width: 100%;
  text-align: center;
  background-color: var(--primaryTextColor);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  padding: 0.7rem;
}

.popup_header img {
  height: 2.2rem;
  width: 1.9rem;
}

.popup_header h3 {
  font-size: 1.8rem;
  font-weight: 500;
  color: var(--commonWhite);
}

.popup_first_form {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1.5rem 0;
  gap: 1rem;
}

.popup_first_form div {
  width: 85%;
  display: flex;
  gap: 2rem;
  align-items: center;
  justify-content: center;
}

.popup_first_div_btn {
  justify-content: end !important;
  cursor: pointer;
}

.popup_first_form div h3 {
  color: var(--secondaryTextColor);
  font-size: 0.938rem;
  font-weight: 500;
  border-bottom: 2px solid var(--secondaryTextColor);
}

.popup_first_form label {
  align-self: center;
  width: 24%;
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--primaryTextColor);
}

.popup_first_form input {
  padding: 0.8rem;
  width: 62%;
  border: 2px solid var(--primaryTextColor);
  border-radius: 4px;
}

.popup_first_form input::placeholder {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--secondaryTextColor);
}

.popup_first_form textarea {
  border: 2px solid var(--primaryTextColor);
  padding: 1.2rem;
  border-radius: 4px;
  width: 62%;
}

.popup_first_form textarea::placeholder {
  font-size: 0.938rem;
  font-weight: 500;
  color: var(--secondaryTextColor);
}

.popup_first_form button {
  border-radius: 3.125rem;
  padding: 1rem 3rem;
  border: none;
  outline: none;
  background-color: var(--primaryTextColor);
  color: var(--commonWhite);
  align-items: self-end;
  cursor: pointer;
}

.popup_first_form .popup_arrow {
  padding-top: 0.2rem;
  width: 1rem;
}

.doctor-selection {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.doctor-selection > span {
  width: 42.5%;
  background-color: var(--baseColor);
  display: flex;
  flex-direction: column;
  padding: 0.5%;
  color: var(--secondaryTextColor);
  cursor: pointer;
}

.selected-doctor {
  background-color: var(--secondaryColor) !important;
}

.doctor-selection > span > p {
  font-size: 12px;
  font-weight: 300;
}

.popup_second_form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2rem 0;
  gap: 3rem;
}

.second_form_content {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 0 1%;
  gap: 1%;
}

.popup_second_form label {
  font-size: 1.563rem;
  font-weight: 500;
  color: var(--primaryTextColor);
  align-self: self-start;
}

.popup_second_form_div1 {
  width: 50%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

.popup_second_form_div1 label {
  padding-left: 1rem;
}

.popup_second_form_div2 {
  width: 50%;
  justify-content: center;
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
}

.popup_second_form_div2 p {
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--primaryTextColor);
}

.time_slot_div {
  display: grid;
  grid-template-columns: repeat(3, 32.7%);
  gap: 5px;
}

.time_slot_div .time_div {
  color: var(--primaryTextColor);
  background-color: var(--secondaryColor);
  font-size: 1.2rem;
  font-weight: 500;
  border-radius: 4px;
  padding: 0.4rem 0.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.time_slot_div .time_div:active {
  background-color: var(--primaryTextColor);
  color: var(--baseColor);
}

.selected-time {
  background-color: var(
    --primaryTextColor
  ) !important; /* Change this to your desired background color */
  color: var(
    --commonWhite
  ) !important; /* Change this to your desired text color */
}

.popup_second_form_btn button {
  color: var(--baseColor);
  background-color: var(--primaryTextColor);
  border-radius: 50px;
  font-size: 0.9rem;
  border: none;
  padding: 0.8rem 2.2rem;
  cursor: pointer;
}

/*///////       calender css         /////// */

.customCalendar .react-calendar__navigation__label,
.customCalendar .react-calendar__month-view__weekdays__weekday {
  color: var(--primaryTextColor);
  font-weight: 600;
}

.customCalendar .react-calendar__month-view__days {
  background-color: var(--secondaryColor);
  border-radius: 4px;
}

.customCalendar .react-calendar__tile--now {
  font-weight: 600;
  font-size: 1rem;
}

.react-calendar button {
  color: black;
  font-weight: 600;
}

.react-calendar {
  border: 1px solid var(--primaryTextColor);
  border-radius: 4px;
}

.selected-date {
  background-color: var(--secondaryColor);
}
/* responsive */
/*----- respnsive ----- */
@media only screen and (max-width: 768px) {
  .book_popup_container {
    width: 90vw;
    height: auto;
    transform: translate(-50%, 0);
  }
  .popup_header h3 {
    font-size: 20px;
  }
  .popup_first_form label {
    width: 33%;
    font-size: 0.725rem;
  }
  .popup_first_form input::placeholder {
    font-size: 0.738rem;
  }
  .popup_first_form textarea::placeholder {
    font-size: 0.738rem;
  }
  .popup_first_form button {
    padding: 0.7rem 2.5rem;
  }
  .popup_first_form input {
    padding: 0.4rem;
  }
 
.doctor-selection > span {
  width: 50.5%;
  height: 85px;
  cursor: pointer;
  padding: 1% 0.5%;
  
}
.doctor-selection > span > h4 {
  font-size: 12px;
  font-weight: 300;
  font-weight: 400;
}
.doctor-selection > span > p {
  font-size: 10px;
}

.popup_first_form textarea {
  resize: none;
}
  /* ------- form section second start ----- */
  .popup_second_form {
    width: 100%;
    height: 500px;
    gap: 1rem;
  }

  .second_form_content {
    flex-direction: column;
  }
  .popup_first_form div {
    width: 95%;
    gap: 1%;
  }
  .popup_second_form_div1 {
    width: 50%;
  }
  .popup_second_form_div1 label {
    font-size: 20px;
  }

  .react-calendar {
    width: 300px !important;
    height: 282px;
  }

  .popup_second_form_div2 p {
    margin-top: 20px;
    text-align: center;
  }

  .time_slot_div {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(6, 27%);
    gap: 5px;
    justify-content: center;
  }
  .popup_second_form label {
    display: none;
  }
  .popup_second_form_div2 {
    gap: 9px;
  }

  .time_slot_div .time_div{
    font-size: .8rem;
  }
}
