.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background: linear-gradient(var(--primaryColor), var(--secondaryColor));
  color: var(--baseColor);
  height: 4.5rem;
  width: 100%;
  font-weight: var(--headingFontWeight);
}

.headerText {
  width: 100%;
  margin: 0 2% 0 18%;
  display: flex;
  gap: 1.875rem;
}

.headerText ul {
  width: 55%;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.headerText ul li {
  cursor: pointer;
}

.headerText ul li h4 {
  font-size: 1.5vh;
}

.headerIcon {
  margin: 0 2% 0 2.3%;
}

.headerIcon ul {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.headerIcon ul li {
  padding: 0 1rem 0 1rem;
}

.headerBtn {
  display: flex;
  align-items: center;
  gap: 2vh;
  position: relative;
}

.headerBtnTwo {
  display: flex;
  gap: 2vh;
}

.headerBtn button {
  background-color: var(--secondaryColor);
  border: none;
  border-radius: 8px;
  color: var(--primaryTextColor);
  cursor: pointer;
  font-weight: 700;
  opacity: 1;
  padding: 1vh 2.5vh;
  text-align: center;
  user-select: none;
  white-space: nowrap;
}

/* .headerBtn button:nth-child(2) {
  padding: 0.8rem 1rem;
} */

.mobile-hide-two {
  background-color: var(--secondaryColor);
  color: var(--primaryTextColor);
  border-radius: 50%;
  border: none;
  outline: none;
  width: 2.5rem;
  height: 2.5rem;
  font-size: 1.25rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.dropdown-header {
  display: flex;
  position: absolute;
  height: 4rem;
  bottom: -170%;
  right: -9%;
  background-color: var(--secondaryColor);
  cursor: pointer;
  width: 5rem;
  flex-direction: column;
  justify-content: center;
  gap: 0.3rem;
  align-items: center;
  border-radius: 4px;
}

.dropdown-header div {
  cursor: pointer;
}

/*/////   popup form css ///// */

.Form {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;
  z-index: 1000;
}

.Form.open {
  display: block;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  min-width: 99.5vw;
  min-height: 120vh;
  background: rgb(52 52 53 / 50%);
  filter: blur(5px);
  z-index: 999;
}

.hame-burger-menu {
  width: 4rem;
  height: 4.5rem;
  display: flex;
  align-items: center;
  display: none;
}

.menu {
  font-size: 2rem;
  cursor: pointer;
}

.mobile-hide {
  display: block;
}

.show-btn {
  display: none;
}

.close-tab {
  width: 100%;
  height: auto;
  display: flex;
}

.header-toggle-btn {
  cursor: pointer;
  font-size: 2.2rem;
  display: flex;
  align-items: center;
}
.header_num {
  color: var(--numColor);
  font-size: 1rem;
  position: absolute;
  top: 15%;
}

.header_logIn_btn {
  padding: 2.2vh 3vh !important;
}

@media only screen and (max-width: 768px) {
  .header {
    justify-content: space-between;
  }

  .headerText {
    width: 100%;
    height: auto;
    left: 0;
    top: -100vh;
    z-index: -999;
    gap: 1.3rem;
    flex-direction: column;
    position: absolute;
    background-color: rgb(255, 255, 255);
    margin: 0;
    padding: 0 5% 3% 5%;
    transition-duration: 0.25s;
    padding: 0.6rem;
  }

  .headerText ul {
    width: 100%;
    flex-direction: column;
    gap: 20px;
  }

  .headerText ul li h4 {
    color: var(--secondaryTextColor);
    font-size: 1.9vh;
  }

  .headerText ul li {
    width: 100%;
    height: 40px;
    border: 2px solid var(--secondaryColor);
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 0 2%;
  }

  .headerBtn {
    gap: 0.5rem;
  }

  .headerBtn button {
    padding: 0.5rem 0.4rem;
  }

  .headerBtn button:nth-child(2) {
    padding: 0.5rem 1rem;
  }

  .hame-burger-menu {
    display: block;
  }

  .menu {
    font-size: 2rem;
    padding: 2%;
    margin-top: 18px;
  }

  .show-btn {
    width: 100%;
    height: auto;
    display: block;
    margin-top: 15px;
  }

  .show-btn button {
    border: none;
    outline: none;
    padding: 0.8rem 1.3rem;
    border-radius: 8px;
    opacity: 1;
    color: var(--primaryTextColor);
    font-weight: 700;
    background-color: var(--secondaryColor);
    cursor: pointer;
  }

  .toggle {
    top: 100%;
    border-bottom: 2px solid var(--primaryTextColor);
  }

  .header-toggle-btn {
    color: var(--secondaryColor);
    position: relative;
  }

  .close-tab {
    width: 100%;
    height: 100vh;
    margin-top: 680px;
    z-index: -999999;
    position: absolute;
    display: none;
  }
  .dropdown-header {
    bottom: -147%;
    right: 25%;
  }
}

@media only screen and (min-width: 1280px) {
  .headerText ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }
  .headerText {
    margin: 0 2% 0 10%;
  }

  .headerText ul li h4 {
    font-size: 1.8vh;
  }

  .headerBtn button {
    font-size: 1.7vh;
  }

  .header-toggle-btn {
    width: 80%;
    font-size: x-large;
  }
  .headerText {
    gap: 1rem;
    margin-left: 4%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .headerText ul {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
  }

  .headerText {
    gap: 1rem;
    margin-left: 7%;
  }
  .headerText ul li h4 {
    font-size: 10px;
  }

  .headerBtn button {
    font-size: xx-small;
  }

  .header-toggle-btn {
    font-size: x-large;
  }

  .headerIcon img {
    width: 180px;
  }
}
@media only screen and (min-width: 1700px) {
  .headerBtn button {
    padding: 0.5vh 2.5vh;
    font-size: 1.2vh;
  }
  .header_logIn_btn {
    padding: 1.4vh 2.5vh !important;
  }
}
