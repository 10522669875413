.medical_test_hero {
  height: 90vh;
  background: url("../../Assets/Images/medicalTest/hero.png");
  background-size: cover;
  background-position: center;
  /* background-color: pink; */
}

.medical_content {
  padding: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 70%;
  height: 100%;
}

.medical_content h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primaryTextColor);
}

.medical_content h2 {
  font-size: 3rem;
  font-weight: 600;
  color: var(--primaryTextColor);
}

.medical_content h2 span {
  color: var(--secondaryTextColor);
}

/*----- Mobile respnsive ----- */
@media only screen and (max-width: 768px) {
  .medical_test_hero {
    /* width: 100%; */
    height: 35vh;
    background: url("../../Assets/Images/medicalTest/hero.png");
    background-size: cover;
    object-fit: cover;
    background-position: center;
  }

  .medical_content {
    width: 100%;
    padding: 1%;
  }

  .medical_content h3 {
    text-align: center;
    font-size: 1rem;
  }

  .medical_content h2 {
    text-align: center;
    font-size: 1.25rem;
    /* padding: 0.625rem; */
  }
}