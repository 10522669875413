.cutEditIconDiv {
  height: 2.3vw;
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
  cursor: pointer;
}

.crossIconsEdit {
  height: 100%;
  width: 5%;
  color: var(--primaryTextColor);
  font-size: 2vw;
}

.addressUpdateModal {
  width: 60vw;
  height: 36vw;
  background-color: var(--thirdBackground);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1vw 2vw;
  position: fixed;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  z-index: 111;

}

.containerUpdateAddress {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-around;
  gap: 0.5vw;
  /* padding: 1vw; */
  height: 100%;
  width: 100%;
  /* border: 1px solid red; */
}

.mobileEmailDiv,
.stateCountryDiv,
.pinCityDiv {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 1vw;
  justify-content: space-between;
}

.xyzq {
  display: flex;
  align-items: start;
  flex-direction: column;
}

.mobileEdit,
.emailEdit,
.pinDiv,
.cityDiv,
.stateDiv,
.countryDiv {
  width: 50%;
}

.AddressDiv1,
.AddressDiv2,
.NameDiv {
  width: 100%;
}

.xyzq>input {
  width: 100%;
  height: 3vw;
  outline: none;
  border: 1px solid var(--secondaryColor);

  padding: 1vw 1.4vw;
}

label {
  font-weight: 600;
  color: var(--primaryTextColor);
  margin-bottom: 2px;
}

input::placeholder {
  font-weight: 400;
}

.saveBtnEdit {
  border: none;
  padding: 0.7vw 2.3vw;
  background-color: var(--primaryTextColor);
  color: var(--commonWhite);
  cursor: pointer;
  border-radius: 0.5rem;
}

@media only screen and (max-width: 768px) {
  .addressUpdateModal {
    height: 84vh;
    position: fixed;
    transform: none;
    top: 7vh;
    left: 2.7vw;
    width: 95vw;
    padding: 2vw 2vw;
  
  }

  .containerUpdateAddress {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1.2vh;
  }

  .xyzq>input {
    width: 100%;
    height: 3vw;
    outline: none;
    border: 1px solid var(--secondaryColor);
  
    padding: 4vw 1.4vw;
  }
 

  label {
    font-size: 1.7vh;
  }

  input::placeholder {
    font-size: 1.4vh;
  }

  .mobileEmailDiv,
  .stateCountryDiv,
  .pinCityDiv {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1.2vh;
  }

  .mobileEdit,
  .emailEdit,
  .pinDiv,
  .cityDiv,
  .stateDiv,
  .countryDiv {
    width: 100%;
  }

  .saveBtnEdit {
    height: 5vh;
    width: 20vw;
  }

  .cutEditIconDiv {
    height: 3vh;
  }

  .crossIconsEdit {
    height: 100%;
    width: 10%;
    font-size: 4vh;
  }
}