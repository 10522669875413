.exploreprinciple-main {
  width: 100%;
  /* height: fit-content; */
  background: rgba(114, 113, 113, 0.1);
  display: flex;
  align-items: center;
  padding:2rem 1rem 1rem 1rem;
  justify-content: center;
 
}

.exploreprinciple-content {
  width: 90%;
 margin: 1rem 0rem 1rem 0rem;
}
.exploreprinciple-content-explor {
  width: 90%;
  margin: 0rem 0rem 1rem;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.exploreprinciple-content-explor .heading {
  width: 100%;
  color: var(--secondaryTextColor);
  font-size: 1.25rem;
  font-weight: 500;
  width: 45%;
  margin-bottom: 2rem;
}
.exploreprinciple-content-explor .heading span {
  color: var(--primary2ndColor);
}
.exploreprinciple-content p {
  margin-top: 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--secondaryTextColor);
}
.exploreprinciple-content-explor .authorHeading {
  color: var(--primary2ndColor);
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 2rem;
}
.ul-style {
  padding: 1%;
}
.li-style {
  padding: 1%;
  list-style-type: circle;
  font-size: 0.9375rem;
  color: var(--secondaryTextColor);
}

/*------- media query -------  */
@media only screen and (max-width: 768px) {
  
  .exploreprinciple-main {
   /* height: 50vh; */
    padding: 0;
  }
  .exploreprinciple-content-explor {
    flex-direction: column;
  }
  .li-style {
    font-size: 0.6375rem;
  }
  .heading span {
    font-size: 0.925rem;
  }
  .exploreprinciple-content-explor .authorHeading {
    font-size: 0.925rem;
  }
  .exploreprinciple-content-explor p {
    font-size: 0.725rem;
  }
}
