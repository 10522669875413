.about-ETP {
  height: 100vh;
  width: 100%;
  padding: 0 1%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.ExploringThePrinciples-Legacy-Heading{
  font-size: 3vw !important;
}
.about-ETP-left {
  width: 35%;
  border-radius: 33.188rem;
  background: linear-gradient(
    180deg,
    rgba(64, 147, 197, 0.2) 0%,
    rgba(165, 220, 251, 0.2) 100%
  );
  border-radius: 50%;
  padding: 1.75rem 0.625rem;
}
.about-ETP-left img {
  width: 90%;
  height: 100%;
  padding: 10% 5%;
}
.about-ETP-right {
  width: 58%;
  display: flex;
  flex-direction: column;
  margin-left: 3%;
}
.about-ETP-right h3 {
  color: var(--primary2ndColor);
}
.about-ETP-right h1 {
  font-size: 3rem;
  font-weight: 500;
  /* margin: 0% 0; */
}
.about-ETP-right p {
  font-size: 1vw;
  font-weight: 400;
  color: var(--secondaryTextColor);
  /* margin-bottom: 2%; */
}
.about-ETP-right-logo {
  width: 80%;
  display: flex;
  text-align: center;
}
.about-ETP-right-logo h3 {
  color: var(--primaryTextColor);
}
.about-ETP-right-logo img {
  margin: 3% 0;
  height: 6.063rem;
  width: 6.063rem;
  background-color: #a5dcfb80;
  padding: 20px;
  border-radius: 50%;
  opacity: 80%;
}
.mobile-view {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.resposiveimg{
  display: none;
}
/*..... media query ..... */
@media only screen and (max-width: 768px) {
    .about-ETP{
        height: auto;
        padding: 4% 0 0;
    }
  .about-ETP {
    flex-direction: column;
  }
  .about-ETP-right {
    width: 90%;
  }
  .about-ETP-right h3 {
    text-align: center;
    font-size: 0.625rem;
  }
  .about-ETP-right h1 {
    font-size: 5vw;
    text-align: center;
  }
  .fade-down-paragraph{
    padding: 0 1rem;
   
  }
  .fade-down-paragraph p{
    display: flex;
    align-items:start ;
    justify-content: start;
  }
  .about-ETP-right p {
    text-align: start;
    font-size: 0.625rem;
    padding: 0 1rem;
    padding: 2% 0 0;
  }
  .about-ETP-left {
    display: none;
  }
  .ETP-responsive{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .resposiveimg {
    display: block;
    width: 11.763rem;
    height:11.763rem;
    /* border-radius: 531px; */
    background: linear-gradient(
      180deg,
      rgba(64, 147, 197, 0.2) 0%,
      rgba(165, 220, 251, 0.2) 100%
    );
    border-radius: 100%;
    padding: 0.75rem 0.938rem;
  }
  .resposiveimg img {
    width: 9.438rem;
    height: 9.438rem;
  }
  .about-ETP-right-logo {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    /* background-color: red; */
  }
}
@media screen and (min-width:1600px){
  .ExploringThePrinciples-Legacy-Heading{
    font-size: 3.5vw !important;
  }
}
