.principlessecond-main {
  width: 100%;
  /* background-color: var(--baseColor); */
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
}

.principlessecond-content {
  margin: 2rem 0;
  display: flex;
  width: 100%;
  height: 70vh;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
}
.principlessecond-content-p{
  margin: 2rem 0;
  display: flex;
  width: 100%;
  height: 75vh;
  align-items: center;
  justify-content: center;
  column-gap: 2rem;
}
.principlessecond-img {
  width: 40%;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.principlessecond-img .t-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.principlessecond-img .p-img{
  width: 100%;
  height: 60%;
  object-fit: cover;
}

.principlessecond-text {
  width: 50%;
  padding: 0 0 11rem;
}
.principlessecond-text1 {
  width: 50%;
}
.principlessecond-text1 .heading {
  color: var(--primary2ndColor);
  font-size: 0.9375rem;
  font-weight: 400;
}
.principlessecond-text1 .subheading {
  color: var(--secondaryTextColor);
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 1rem;
  width: 90%;
}

.principlessecond-text1 .subheading span {
  color: var(--primary2ndColor);
  font-size: 1.25rem;
  font-weight: 500;
}

.principlessecond-text1 p {
  margin-top: 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--secondaryTextColor);
}

.principlessecond-text .heading {
  color: var(--primary2ndColor);
  font-size: 0.9375rem;
  font-weight: 400;
}

.principlessecond-text .subheading {
  color: var(--secondaryTextColor);
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 1rem;
  width: 90%;
}

.principlessecond-text .subheading span {
  color: var(--primary2ndColor);
  font-size: 1.25rem;
  font-weight: 500;
}

.principlessecond-text p {
  margin-top: 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--secondaryTextColor);
}

/* media query */
@media only screen and (max-width: 768px) {
  .principlessecond-main{
    padding-bottom: 0rem;
    height: 100%;
    margin-bottom: 0;
  }
 .principlessecond-img{
    /* margin-bottom: 0; */
    padding-bottom: 1rem 1rem 0rem 1rem;
  }
  .principlessecond-content {
    flex-direction: column-reverse;
  }
  .principlessecond-content-p{
    flex-direction: column-reverse;
    height: 50vh;
    
  }
  .principlessecond-text,
  .principlessecond-text1 {
    width: 100%;
    /* margin-top: 0px; */
    padding: 0 0.625rem;
  }
  .principlessecond-text .heading {
    text-align: center;
    font-size: 0.625rem;
  }
  .principlessecond-text .subheading {
    text-align: center;
    font-size: 0.625rem;
    width: 100%;
  }

  .principlessecond-text .subheading span {
    text-align: center;
    font-size: 0.625rem;
  }
  .principlessecond-text p {
    text-align: center;
    font-size: 0.625rem;
  }
  /*  */
  .principlessecond-text1 .heading {
    text-align: center;
    font-size: 0.625rem;
  }
  .principlessecond-text1 .subheading {
    text-align: center;
    font-size: 0.625rem;
    width: 100%;
  }

  .principlessecond-text1 .subheading span {
    text-align: center;
    font-size: 0.625rem;
  }
  .principlessecond-text1 p {
    text-align: center;
    font-size: 0.625rem;
  }
  /*  */
  .principlessecond-img {
    width: 100%;
    padding: 1rem;
  }

  .principlessecond-img .t-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .principlessecond-img .p-img{
    width: 100%;
    height: auto;
    object-fit: cover;
    /* jhgh */
  }
}
