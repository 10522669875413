.medicineSection {
  width: 100%;
  height: fit-content;
  background: var(--commonWhite);
  padding: 3rem 2rem;
}

div.medicines-by-disease-content-right::-webkit-scrollbar {
  width: 0.7rem;
}
div.medicines-by-disease-content-right::-webkit-scrollbar-track {
  background-color: var(--baseColor);
}
div.medicines-by-disease-content-right::-webkit-scrollbar-thumb {
  background-color: var(--primary2ndColor);
  border-radius: 1rem;

  /* border: 0.2rem solid transparent; */
  /* background-clip: content-box; */
}

.medicines-innerContainer {
  width: 100%;
  display: flex;
  height: 30vh;
  justify-content: space-between;
  padding: 0 2rem;
  margin-bottom: 2rem;
}

.medicineImgsLeft {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  column-gap: 2rem;
}

.medIconsFirst {
  width: 3.125rem;
  height: 3.75rem;
}

.medIcons {
  width: 50px;
  height: 60px;
}

.medicinesSearch {
  width: 90%;
  height: 100%;
  text-align: center;
}

.medicineHeadingSec {
  display: flex;
  justify-content: space-around;
}

.medicineHeadingSec > h2 {
  margin: 1rem 0 3rem;
  color: var(--primaryColor);
  font-weight: 700;
  font-size: 3rem;
}

.medicineHeadingSec > h2 > span {
  color: var(--secondaryTextColor);
}

.searchBox {
  width: 70%;
  border-radius: 3rem;
  position: relative;
}

.searchBox > input {
  width: 100%;
  padding: 1rem 3rem;
  outline: none;
  font-size: 1rem;
  border-radius: 3rem;
  border: 2px solid var(--primaryColor);
  background: var(--thirdBackground);
}

.searchIcon {
  position: absolute;
  top: 0.7rem;
  left: 0.6rem;
  font-size: 2rem;
  color: var(--primaryColor);
}

.medicinesImgsRight {
  width: 25%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  column-gap: 3rem;
}

.carousal {
  width: 100%;
  padding: 1rem;
  margin: 2rem 0;
  text-align: center;
}

.carousalBox {
  width: 100%;
  margin: 3.5rem 0;
  column-gap: 1rem;
}

.sliderContentBox {
  width: 14vw !important;
  height: 25vh;
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  row-gap: 0.4rem;
  padding: 0.5rem;
  margin: 0.1rem auto;
  text-align: center;
  border: 1px solid var(--primaryColor);
  cursor: pointer;
}

.sliderContentBox > div {
  width: 6vw;
  height: 13vh;
  border-radius: 50%;
  padding: 0.5rem;
}

.sliderContentBox > div > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.prevButton {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: var(--buttonColor);
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  left: 0rem;
  border: none;
  z-index: 1;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.nextButton {
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: var(--buttonColor);
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0rem;
  z-index: 0;
  border: none;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.prevIcon {
  font-size: 2rem;
  margin: auto;
  color: var(--secondaryTextColor);
}

@media only screen and (max-width: 768px) {
  .medicines-innerContainer {
    padding: 0;
    height: 13vh;
    padding: 2% 0 0 0;
  }

  .medicinesSearch h2 {
    font-size: 0.9rem;
    margin: 0;
  }

  .medicinesSearch {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .medicineHeadingTop {
    width: 100%;
  }

  .medicineHeadingSec {
    justify-content: space-between;
  }

  .sliderContentBox {
    width: 7.25rem !important;
    height: 7.25rem;
  }

  .medicineSection {
    padding: 1% 0;
  }

  .image_first {
    height: 4.75rem;
    width: 4.813rem;
  }

  .medicineImgsLeft {
    align-items: end;
  }

  .medicinesImgsRight {
    align-items: end;
  }

  .searchBox {
    margin: 0;
    width: 100%;
  }

  .searchIcon {
    font-size: 1.313rem;
  }

  .searchBox > input {
    font-size: 0.625rem;
    padding: 0.8rem 2rem;
  }

  .medIconsFirst {
    height: 2.125rem;
    width: 2.125rem;
  }

  .medIcons {
    height: 2.5rem;
    width: 2.125rem;
  }

  .sliderContentBox > div {
    width: 3.75rem;
    height: 3.75rem;
    /* background: red; */
    padding: unset;
  }

  .sliderContentBox p {
    font-size: 2vh;
    font-weight: 500;
  }

  .nextButton {
    width: 1.8rem;
    height: 1.8rem;
  }

  .prevButton {
    width: 1.8rem;
    height: 1.8rem;
  }

  .prevIcon {
    font-size: 1rem;
  }

  .medicineImgsLeft {
    height: 100%;
  }

  .medicinesImgsRight {
    height: 100%;
  }
}

@media screen and (min-width: 1000px) {
  .sliderContentBox {
    width: 14vw !important;
    height: 20vh;
  }

  .sliderContentBox > div {
    width: 8vw;
    height: 11vh;
  }
}

@media screen and (min-width: 1100px) and (min-height: 700px) {
  .sliderContentBox {
    width: 14vw !important;
    height: 25vh;
  }

  .sliderContentBox > div {
    width: 6vw;
    height: 13vh;
  }
}

@media screen and (min-width: 1100px) and (min-height: 800px) {
  .sliderContentBox {
    width: 14vw !important;
    height: 25vh;
  }

  .sliderContentBox > div {
    width: 8vw;
    height: 13vh;
  }
}

@media screen and (min-width: 1100px) and (min-height: 1000px) {
  .sliderContentBox {
    width: 14vw !important;
    height: 20vh;
  }

  .sliderContentBox > div {
    width: 8vw;
    height: 11vh;
  }
}

@media screen and (min-width: 1360px) {
  .sliderContentBox {
    width: 14vw !important;
    height: 25vh;
  }

  .sliderContentBox > div {
    width: 6vw;
    height: 13vh;
  }
}

@media screen and (min-width: 1900px) {
  .sliderContentBox {
    width: 14vw !important;
    height: 27vh;
  }

  .sliderContentBox > div {
    width: 8vw;
    height: 15vh;
  }
}
