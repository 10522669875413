:root{
    --primaryTextColor:#196EA4;
    --secondaryTextColor:#727171;
    --primaryColor:#4093C5;
    --primary2ndColor:#6CB5E1;
    --secondaryColor:#A5DCFB;
    --thirdBackground:#EDF8FE;
    --baseColor:#F2EFEF;
    --commonWhite:#fff;
    --buttonColor:#F2EFEF;
    --headingFontWeight:700;
    --BackgroundWithOpacity:rgba(165, 220, 251, 0.2);
    --cardBackground:#50748A;
    --boxShadow:0px 2px 2px 0px rgba(0, 0, 0, 0.25) inset;
    --boxColor:rgba(3, 3, 3, 0.5);
    --numColor: #b50c0c;
}