.medical_test_lab {
    width: 100%;
}

.medical_lab_content {
    padding: 3rem;
    display: flex;
    flex-direction: column;
}

.labsSlider{
    width: 100%;
    overflow: hidden;
}

.lab_head {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.lab_head h3 {
    font-size: 1.5rem;
    font-weight: 600;
    color: var(--primaryTextColor);
}

.lab_head h2 {
    font-weight: 3rem;
    font-weight: 700;
    color: var(--primaryColor);
}

.lab_item {
    display: flex;
    gap: 1rem;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    
}

.lab_card {
    height: 16.75rem;
    width: 100%;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 2px 3px var(--boxColor);
}

.lab_card p {
    font-size: 1.8rem;
    font-weight: 500;
    color: var(--baseColor);
}

.lab_card_left {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primaryTextColor);
    position: absolute;
    z-index: 2;
  
}

.lab_card_right {
    width: 60%;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
 
}

.labImageContainer {
    width: 50%;
    height: 100%;
   
}

.labImageContainer>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    padding: 2rem;
}

/* check this style  */
.lab_card_item {
    width: 50%;
    overflow: visible;
    padding: 1%;
    height: 100%;
    background-color: var(--primaryTextColor);
}

.lab_card_item>span {
  margin: 1rem 0;
  color: var(--baseColor);
  font-size: 0.9rem;
}

.lab_card:hover .lab_card_item {
    width: 0;
    overflow: hidden;
    transition: width .4s;
    display: none;

}

.lab_card:hover .lab_card_left {
    width: 40%;
    transition: width .4s;
}

.lab_card:hover .labImageContainer {
    width: 100%;
    transition: width .4s;
}

.lab_card_hide {
    background-color: var(--baseColor);
    position: absolute;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1% 1% 0% 1%;
}

.lab_hide_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 90%;
}

.lab_hide_text h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--primaryTextColor);
}

.lab_hide_text p span {
    font-size: 0.938rem;
    font-weight: 600;
    color: var(--secondaryTextColor);
}

.lab_hide_text p {
    color: var(--secondaryTextColor);
    font-size: 0.938rem;
    font-weight: 400;
}


.lab_card_hide button {
    margin: 1rem;
    padding: 0.5rem 0.5rem;
    border-radius: 3.125rem;
    font-size: 1rem;
    font-weight: 600;
    color: var(--primaryTextColor);
    border: 2px solid var(--primaryTextColor);
    cursor: pointer;
    align-self: flex-end;
}

.lab_card_hide button:hover {
    background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
    color: var(--baseColor);
    box-shadow: 2px 4px 6px 1px var(--secondaryColor);
    border: none;
}
/*----- Mobile respnsive ----- */
@media only screen and (max-width: 768px){
    .lab_head h3 {
        font-size: 0.625rem;
    }
    
    .lab_head h2 {
        font-weight: 0.625rem;
    }
    .lab_item {
        display: flex;
        gap: 1rem;
        justify-content: center;
        align-items: center;
        padding: 0;   
    }
    .medical_lab_content {
        padding: 0.625rem;
        display: flex;
        flex-direction: column;
        gap: 0rem;
    }
  
    /* ............... */
    .lab_card p {
        font-size: 2.2vh;
    }
    .lab_hide_text{
        gap: 0;
    }
    .lab_hide_text h3{
        font-size: 0.9rem;
    }
    .lab_card {
        height: 16.75rem;
        width: 100%;
        overflow: hidden;
        cursor: pointer;
        position: relative;
        border-radius: 10px;
        box-shadow: 0 2px 3px var(--boxColor);
    }
    .labImageContainer>img {
        padding: 1%;
    }
    .lab_card_item {
        padding: 1%;
        overflow-y: scroll;
    }
    .lab_card_item>span {
        font-size: 1.3vh;
        line-height: 0;
        padding: 0;
        margin: 0;
    }
}