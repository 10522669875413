.wish_list_container {
    width: 100%;
    padding: 1.5rem 0;
}

.wish_list_nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primaryTextColor);
    padding: 1rem 2rem;
}

.wish_list_nav p {
    color: var(--commonWhite);
    font-size: 1.25rem;
    font-weight: 500;
}

.wishlist_content {
    padding: 1rem;
}

.wishlist_content_card {
    border: 1px solid var(--primaryTextColor);
    border-radius: 4px;
}

.wishlist_card {
    width: 100%;
    border-bottom: 1px solid var(--secondaryTextColor);
    padding: 1rem;
    display: flex;
    justify-content: space-between;
}

.wishlist_left_div {
    display: flex;
    gap: 2rem;
}

.wishlist_img {
    height: 15.5rem;
    width: 30%;
    border: 1px solid var(--primaryTextColor);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    padding: 0.5rem;
}

.wishlist_img img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 4px;

}

.wishlist_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 70%;
}

.wishlist_text h4 {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--primaryTextColor);
    cursor: pointer;
}

.wishlist_text h4:hover {
    text-decoration: underline;
}

.wishlist_text p {
    font-size: 0.938rem;
    font-weight: 400;
    color: var(--secondaryTextColor);
}

.wishlist_text .wishlist_rating {
    display: flex;
    gap: 1rem;
}

.wishlist_text .wishlist_rating span:nth-child(2) {
    font-size: 0.938rem;
    font-weight: 400;
    color: var(--secondaryTextColor);
}

.wishlist_potency {
    display: flex;
    gap: 1rem;
    flex-direction: column;
}

.wishlist_text .wishlist_potency p {
    font-size: 0.938rem;
    font-weight: 600;
    color: var(--secondaryTextColor);
}

.wishlist_item {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.wishlist_item_more {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.wishlist_item_more span {
    font-size: 0.938rem;
    font-weight: 400;
    color: var(--secondaryTextColor);
}

.wishlist_discount {
    display: flex;
    gap: 1rem;
    align-items: center;
}

.wishlist_discount span:nth-child(1) {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--secondaryTextColor);
    text-decoration: line-through;
}

.wishlist_discount span:nth-child(3) {
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 600;
    color: var(--primaryTextColor);
}

.potency-btn p:nth-child(2) {
    font-size: 0.938rem;
    font-weight: 700;
    color: var(--primaryTextColor);
}

.wishlist_btn {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;
}

.wishlist_dlt_btn {
    color: var(--primaryTextColor);
    height: 3rem;
    width: 3rem;
    border: 1px solid var(--primaryTextColor);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0 0 0;
    cursor: pointer;
}

.wishlist_dlt_btn p {
    font-size: 1.5rem;
    font-weight: 700;
}

.wishlist_plush_btn {
    height: 3.75rem;
    width: 3.75rem;
    color: var(--commonWhite);
    font-size: 2rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(180deg, #6CB5E1 0%, #A5DCFB 100%);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.wishlist_cart_btn {
    display: flex;
    gap: 1rem;
    width: 82%;
    height: 24%;
    justify-content: center;
}


/*////// Responsive design /////// */

/* Media Query for Mobile */
@media only screen and (max-width: 600px) {
    .wishlist_content {
        padding: 1rem 0.5rem;
    }

    .wish_list_nav {
        padding: 1rem;
    }

    .wishlist_card {
        flex-direction: column;
        gap: 1rem;
        position: relative;
        padding: 1.3rem;
    }

    .wishlist_left_div {
        flex-direction: column;
        gap: 1rem;
    }

    .potency-btn,
    .show-more-btn {
        width: 98px;
        height: 50px;
        display: flex;
        flex-direction: column;
        gap: 0.5rem;
    }

    .wishlist_text {
        width: 100%;
    }

    .wishlist_img {
        width: 100%;
        height: auto;
        border: none;
    }

    .primaryButton {
        padding: 1.8rem 1rem;
    }

    /* .wishlist_text h4 {
        font-size: 1rem;
    } */

    /* .wishlist_text p {
        font-size: 0.75rem;
    } */

    /* .wishlist_discount span:nth-child(1) {
        font-size: 1rem;
    } */

    .wishlist_dlt_btn {
        height: 2.5rem;
        width: 2.5rem;
        font-size: 1rem;
        position: absolute;
        top: 3%;
    }

    .wishlist_plush_btn {
        height: 3rem;
        width: 3rem;
        font-size: 1.5rem;
    }

    .wishlist_cart_btn {
        width: 63%;
        margin-right: 8rem;
    }
}

/* Media Query for Tablet */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .wishlist_content {
        padding: 1rem;
    }
}

/* Media Query for Big Desktop */
/* @media only screen and (min-width: 1025px) {
    .wishlist_content {
        padding: 2rem;
    }
} */