.Trading_Container {
    width: 100%;
    padding: 5% 3%;
}

.Trading_Content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.Trading_Head h2 {
    font-family: Montserrat;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--primaryColor);
}

.Trading_Head h2 span {
    color: var(--secondaryTextColor);
}

.Trading_Middle {
    width: 100%;
    display: grid;
    column-gap: 3rem;
    row-gap: 3rem;
    grid-template-columns: repeat(auto-fill, minmax(26.688rem, 1fr));
    position: relative;
}

.Trading_Middle_Content {
    height: 30rem;
    border-radius: 14px;
    display: flex;
    flex-direction: column;
    position: relative;
    background-color: var(--commonWhite);
}

.Trading_Middle_Content:hover .Trading_Middle_Text {
    background-color: var(--primaryTextColor);
    transition: 0.5s ease;
    overflow: hidden;
}

.Trading_Middle_Content:hover .Trading_Middle_Text h4,
.Trading_Middle_Content:hover .Trading_Middle_Text p {
    color: var(--commonWhite);
    transition: 0.5s ease;
}

.Trading_Middle_Content:hover .Read_More_Button {
    background-color: var(--primaryTextColor);
    color: var(--commonWhite);
    border: 2px solid var(--commonWhite);
    transition: 0.5s ease;
}

.Trading_Middle_Text {
    width: 22.688rem;
    height: 17.563rem;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    color: var(--commonWhite);
    background-color: var(--buttonColor);
    overflow: hidden;
    text-align: center;
}

.Trading_Middle_Content img {
    height: 15.25rem;
    width: 100%;
    max-width: 100%;
    object-fit: cover;
}

.Trading_Middle h4 {
    font-size: 1.25rem;
    font-weight: 500;
    font-family: Montserrat;
    color: var(--primaryTextColor);
}

.Trading_Middle p {
    font-size: 0.938rem;
    font-weight: 400;
    padding: 0 0.7rem;
    font-family: Montserrat;
    text-align: center;
    color: var(--secondaryTextColor);
}

.Read_More_Button {
    font-size: 0.938rem;
    font-weight: 500;
    font-family: Montserrat;
    color: var(--primaryTextColor);
    border-radius: 50px;
    padding: 0.8rem 1.2rem;
    border: 2px solid var(--primaryTextColor);
    cursor: pointer;
}

@media screen and (max-width: 768px) {
    .Trading_Head h2 {
        font-size: x-large;
    }

    .Trading_Middle {
        grid-template-columns: repeat(auto-fill, minmax(19.688rem, 1fr));
    }

    .Trading_Middle_Content img {
        height: 12.25rem;
    }

    .Trading_Middle_Content {
        width: 100%;
        height: 23rem;
    }

    .Trading_Middle_Text {
        width: 80%;
        gap: 6%;
        height: 12.563rem;
    }
    .Trading_Middle h4 {
        font-size: 2vh;
    }
    .Trading_Middle p {
        font-size: 1.5vh;
    }

    .Read_More_Button {
        padding: 0.5rem 0.6rem;
    }
}