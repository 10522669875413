.fullheight {
  height: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.fullheight-main {
  text-align: center;
  width: 90%;
  margin: 1rem;
}

.gallery-text h1 {
  color: var(--primaryTextColor);
  text-align: center;
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.gallery-text p {
  color: var(--secondaryTextColor);
  text-align: center;
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.gallery-img {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
  margin: 1rem;
}

.gallery-img div {
  width: 100%;
  height: 100%;
}

.gallery-img div>img {
  width: 100%;
  height: 100%;
  object-fit: cover;

}

.gallery-img div:nth-child(1) {
  grid-column: span 2;
  grid-row: span 2;
  height: 100%;
}

.gallery-img div:nth-child(10) {
  grid-column: 3 / span 2;
  grid-row: 3 / span 2;
}

.ourGallery_view_more {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.ourGalleryButton {
  border: 3px solid var(--primaryTextColor);
  cursor: pointer;
  border-radius: 50px;
  text-align: center;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 1.2rem 1.3rem;
  transition: 0.5s;
  align-self: flex-end;
  color: var(--primaryTextColor);
}

.ourGalleryButton:hover {
  background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
  color: var(--baseColor);
  box-shadow: 0 -14px -14px var(--primaryTextColor), -8px -18px -24px var(--primaryTextColor);
  border: 3px solid linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
}

@media screen and (max-width: 768px) {
  .fullheight-main {
    width: 100%;
    margin: 0;
  }

  .gallery-text h1 {
    font-size: 1.25rem;
  }

  .gallery-text p {
    font-size: 0.6rem;
  }

  .gallery-img {
    grid-template-columns: repeat(auto-fill, minmax(55px, 1fr));
    gap: 0.5rem;
    /* Reduced gap for smaller screens */
    margin: 0.5rem;
  }

  .ourGalleryButton {
    font-size: 0.8rem;
    padding: 0.5rem 0.9rem;
    margin: 0 2% 2% 0;

  }

}


@media screen and (min-width: 1500px) {
  .gallery-img div:nth-child(1) {
    grid-column: span 2;
    grid-row: span 2;
  }

  .gallery-img div:nth-child(2) {
    grid-row: span 1;
    grid-column: span 1;
  }

  .gallery-img div:nth-child(3) {
    grid-row: span 1;
    grid-column: span 1;
  }

  .gallery-img div:nth-child(4) {
    grid-row: span 1;
    grid-column: span 1;
  }

  .gallery-img div:nth-child(5) {
    grid-row: span 1;
    grid-column: span 1;
  }

  .gallery-img div:nth-child(6) {
    grid-row: span 1;
    grid-column: span 1;
  }

  .gallery-text p {
    color: var(--secondaryTextColor);
    text-align: center;
    font-family: Montserrat;
    font-size: 1.1vw !important;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  .gallery-img div:nth-child(7) {
    grid-row: span 1;
    grid-column: span 1;
  }

  .gallery-img div:nth-child(8) {
    grid-row: span 1;
    grid-column: span 1;
  }

  .gallery-img div:nth-child(9) {
    grid-row: span 1;
    grid-column: span 1;
  }

  .gallery-img div:nth-child(10) {
    grid-row: span 1;
    grid-column: span 1;
  }
}