.testimonials {
  height: 100vh;
  background: linear-gradient(180deg,
      var(--primaryColor) 0%,
      var(--primary2ndColor) 100%);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20%;
}

.testimonials_card {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.testimonials-photo {
  margin-right: -15%;
  z-index: 0;
  height: 40vh;
}

.testimonials-photo img {
  height: 100%;
}

.testimonials-content {
  background: var(--baseColor);
  height: 50vh;
  width: 50vw;
  padding-left: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 20px;
  align-items: flex-start;
  padding-right: 8%;
}

.testimonials-content div {
  color: var(--secondaryTextColor);
  font-size: 20px;
  font-weight: 400;
}

.testimonials-content h3 {
  margin: 1rem 0;
  color: var(--secondaryTextColor);
}

.testimonials-content p {
  color: var(--secondaryTextColor);
}

.testimonials-content button {
  margin-top: 1rem;
  width: 140px;
  height: 50px;
  border-radius: 50px;
  border: none;
  color: var(--baseColor);
  background: var(--primary2ndColor);
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
  display: none;
}

.testimonials-page-points {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: -5%;
 
}

.testimonials-page-point {
  width: 10px;
  height: 10px;
  background-color: var(--secondaryColor);
  margin: 10px 0;
  border-radius: 50%;
  cursor: pointer;
}

.testimonials-active-point {
  width: 1rem;
  height: 3.125rem;
  background-color: var(--primaryTextColor);
  border-radius: 50px;
  cursor: pointer;
}


@media only screen and (max-width: 768px) {
  .testimonials {
    flex-direction: column;
    height:70vh;
    display: flex;
    align-items: center;
    padding: 38% 20% 8% 20%;
  }

  .testimonials-content {
    background: var(--baseColor);
    padding-left: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 20px;
    align-items: flex-start;
    padding-right: 8%;
    width: 83vw;
    height: 43vh;
  }

  .testimonials-content .testimonials-content-para{
    font-size: 2.8vw;
    word-spacing: 0.1rem;
  }

  .testimonials-photo {
    height: 26vh;
    margin-right: 0;
    position: absolute;
    top: -30%;
  }

  .testimonials_card {
    flex-direction: column;
  }

  .testimonials-page-points {
    flex-direction: row;
    position: absolute;
    bottom: 5%;
    gap: 8px;
  }
  .testimonials-page-point span{
    width: 10px;
    height: 10px;
  }


  .testimonials-active-point {
    width: 1rem;
    height: 1rem;
  }

  .testimonials-content p{
    font-size: 0.65rem;
    font-weight: 500;
  }
  .testimonials-content h3{
    margin:2rem 0 0.2rem 0;
  }

}

@media only screen and (min-width:1920px){
  
  .testimonials-content p{
    font-size: 1.3vw;
    word-spacing: 0.4rem;
  }
  .testimonials-content h3{
    font-size:3vw;
  }
}
@media only screen and (min-width:1280px){
  .testimonials{
    height: 70vh;  
  }
  .testimonials-content p{
    font-size: 1vw;
    word-spacing: 0.2rem;
  }
  .testimonials-content h3{
    font-size:3vw;
  }
}
