.lab_map_container {
  padding: 2rem 0;
  height: 85vh;
  display: flex;
}
.location-map {
  width: 100%;
  height: 100%;
}

.lab_map_distance {
  width: 50%;
  height: 100%;
  background-color: var(--primaryTextColor);
  padding: 2rem 1.5rem;
  position: relative;
}
.lab_map_distance h3 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--baseColor);
}

.lab_distance_count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 24.875rem;
  height: 24.875rem;
  border-radius: 50%;
  background: linear-gradient(180deg, #a5dcfb 100%, #a5dcfb 100%);
  box-shadow: inset 2px 2px 3px var(--secondaryTextColor);
}
/* .lab_distance_count::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: red;
  width: 5rem;
  border-radius: 50%;
  height: 100%;
  animation: animate 3s linear forwards;
} */
@keyframes animate {
  0% {
    transform: translate(0deg);
  }
  100% {
    transform: translate(360deg);
  }
}

.lab_distance_count_2 {
  position: absolute;
  z-index: 5;
  inset: 38px;
  border-radius: 50%;
  background-color: var(--primaryTextColor);
  box-shadow: 0 0 36px 21px var(--primaryColor);
}

.lab_distance_count_3 {
  position: absolute;
  z-index: 10;
  inset: 28px;
  border-radius: 50%;
  background: linear-gradient(180deg, #4093c5 100%, #6cb5e1 100%);
  box-shadow: 2px 3px 4px var(--boxColor);
}

.lab_distance_count_3 h2 {
  color: var(--baseColor);
  font-size: 2rem;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

/*----- Mobile respnsive ----- */
@media only screen and (max-width: 768px) {
  .lab_map_distance {
    width: 50%;
  }
  .lab_map_container {
    height: 42vh;
  }
  .location-map {
    width: 100%;
    height: 100%;
  }
  .lab_distance_count {
    width: 10.875rem;
    height: 10.875rem;
  }
  .lab_distance_count_3 h2 {
    font-size: 1rem;
  }
  .lab_map_distance h3 {
    font-size: 1rem;
    margin-top: -20px;
  }
}
