.addcart_container {
    padding: 1rem 0;
}

.addcart_content {
    padding: 1rem;
    display: flex;
    height: 87vh;

}

.addcart_left_div {
    width: 63%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    overflow: auto;

    /* background-color: green; */
}

.addcart_text>p {
    font-size: 1.25rem;
    font-weight: 500;
    padding: 0 1rem;
    color: var(--primaryTextColor);

}

.addcart_card {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 1rem;
    border: 1px solid var(--primaryTextColor);
    border-radius: 4px;
    position: relative;
}

.addcart_dlt_btn {
    color: var(--primaryTextColor);
    height: 3rem;
    width: 3rem;
    border: 2px solid var(--primaryTextColor);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.3rem 0 0 0;
    cursor: pointer;
    position: absolute;
    bottom: 6%;
    right: 2%;
}

.addcart_dlt_btn p {
    font-size: 1.5rem !important;
    font-weight: 700 !important;
    color: var(--primaryTextColor) !important;
}

.addcart_card_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.addcart_card_text P:nth-child(1) {
    font-size: 0.938rem;
    font-weight: 600;
    color: var(--primaryTextColor);
}

.addcart_card_text P:nth-child(2) {
    font-size: 0.938rem;
    font-weight: 400;
    color: var(--secondaryTextColor);
}

.addcart_right_div {
    width: 38%;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.3rem;
}

.add_right_first_div {
    display: flex;
    align-items: center;
    background-color: var(--primaryColor);
    border-radius: 5px;
    height: 6.25rem;
}

.add_right_first_div div:nth-child(1) {
    padding: 0.9rem;
    background-color: var(--commonWhite);
    border-radius: 50%;
    margin: 1rem;
}

.add_right_first_div div:nth-child(2) {
    padding: 0.5rem 0.2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.add_right_first_div div:nth-child(2) p {
    width: 85%;
}

.add_right_first_div h4 {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--commonWhite);
}

.add_right_first_div p {
    font-size: 0.938rem;
    font-weight: 400;
    color: var(--commonWhite);
}

.add_right_first_div div:nth-child(3) {
    padding: 1.2rem;
    border-radius: 4px;
    height: 100%;
    display: flex;
    background-color: var(--primaryTextColor);
    justify-content: center;
    align-items: center;
}

.add_right_second_div {
    border: 1px solid var(--secondaryColor);
    border-radius: 4px;
    padding: 0.8rem;
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.add_right_second_div h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--primaryTextColor);
}

.cart_promo_code {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.cart_promo_code span:nth-child(1) {
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    border: 1px solid var(--primaryTextColor);
}

.cart_promo_code span:nth-child(2) {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--primaryTextColor);
}

.cart_promo_code div p:nth-child(1) {
    font-size: 1.25rem;
    font-weight: 500;
}

.add_right_second_div p {
    font-size: 0.938rem;
    font-weight: 400;
    color: var(--primaryTextColor);
}

.add_right_third_div {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 4px;
    border: 1px solid var(--secondaryColor);
}

.add_right_third_div h4 {
    padding: 1rem 1rem 0;
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--secondaryTextColor);
}

.add_right_third_div span {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--primaryTextColor);
    display: flex;
    align-items: center;
}

.add_right_div_text {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem 0.5rem;
}

.add_right_div_text div {
    display: flex;
    justify-content: space-between;
    /* padding: 0 1rem; */
}

.add_right_div_text div:nth-child(5) span {
    font-weight: 700;
    font-size: 1.25rem;
}

.add_right_div_text div:nth-child(6) {
    padding: 0.6rem 1rem;
    background-color: var(--secondaryColor);
    box-shadow: 2px 4px 4px var(--boxColor);
}

.add_right_div_text div:nth-child(6) span {
    font-weight: 600;
    font-size: 1.25rem;
}

.add_right_div_text div:nth-child(7) {
    padding: 1rem;
    display: flex;
    align-items: center;
}

.add_right_div_text div:nth-child(7) span {
    font-size: 1.5rem;
    font-weight: 600;
}

.add_right_third_div button {
    background-color: var(--primaryTextColor);
    color: var(--commonWhite);
    padding: 3.8% 11%;
    border: none;
    font-size: 1.25rem;
    font-weight: 600;
    cursor: pointer;

}

.add_right_forth_div {
    height: 5.5rem;
    border: none;
    display: flex;
    align-items: center;
    border-radius: 4px;
    background-color: var(--primaryColor);
}


.add_right_forth_div div:nth-child(1) {
    height: 3rem;
    width: 3rem;
    background-color: var(--commonWhite);
    border-radius: 50%;
    margin: 1rem;
    padding: 0.5rem 0 0 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.add_right_forth_div div:nth-child(2) {
    display: flex;
    flex-direction: column;
}

.add_right_forth_div span:nth-child(1) {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--commonWhite);
}

.add_right_forth_div span:nth-child(2) {
    font-size: 0.938rem;
    font-weight: 400;
    color: var(--commonWhite);
}



/* Media Queries for Tablet Devices */
@media screen and (min-width: 768px) {
    .addcart_content {
        flex-direction: row;
    }

    .addcart_left_div {
        width: 63%;
    }

    .addcart_right_div {
        width: 38%;
    }
}

/* Media Queries for Mobile Devices */
@media screen and (max-width: 576px) {
    .addcart_content {
        flex-direction: column;
        gap: 0.5rem;
        height: fit-content;
    }

    .addcart_card {
        flex-direction: column;
    }

    .addcart_dlt_btn {
        top: 2%;
    }

    .addcart_left_div {
        width: 100%;
    }

    .addcart_right_div {
        width: 100%;
        padding: 0;
    }

    .add_right_first_div h4 {
        font-size: 0.983rem;
    }

    .add_right_first_div div:nth-child(2) p {
        width: 100%;
        font-size: 0.6rem;
        font-weight: 400;
    }

    .add_right_second_div h3 {
        font-size: 0.93rem;
    }

    .cart_promo_code div p:nth-child(1) {
        font-size: 0.93rem;
    }

    .cart_promo_code span:nth-child(1) {
        height: 1.2rem;
        width: 1.6rem;
    }

    .add_right_div_text div:nth-child(7) {
        display: flex;
    }

    /* .add_right_forth_div {
        display: none;
    } */

    .add_right_forth_div .delivery_charge_content span{
        font-size: 1rem !important; 

    }
}