.allProductContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--commonWhite);
    height: 100%;
    flex-direction: column;
    padding: 0 3rem;
    position: relative;
}

.hover-content-btn {
    display: flex;
    justify-content: center;
    align-items: center;
}

.allProductHeading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.filterIcon {
    position: absolute;
    left: 2%;
    top: 0;
    height: 3.5rem;
    width: 3.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
}

.filterLogo {
    font-size: 2rem;
    color: var(--baseColor);
}

.filterList {
    /* position: absolute; */
    top: 0;
    left: 6%;
}


.allProductHeading h2 {
    color: var(--primaryTextColor);
    text-align: center;
    font-family: Montserrat;
    font-size: 3rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.allProductItem {
    margin: 1rem 0;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    row-gap: 3rem;
    width: 100%;
}

.allProductBtn {
    background-color: transparent;
    color: var(--primaryTextColor);
    border: 3px solid var(--primaryTextColor);
    cursor: pointer;
    border-radius: 50px;
    text-align: center;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 1.2rem 1.3rem;
    transition: 0.5s;
    align-self: flex-end;
    margin: 3rem 0 3rem 0;
}

.allProductBtn:hover {
    background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
    color: var(--baseColor);
    box-shadow: 0 -14px -14px var(--primaryTextColor), -8px -18px -24px var(--primaryTextColor);
    border: 3px solid linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
}

.sectionitem4box-btn img {
    width: 8.875rem;
    height: 8rem;
    object-fit: contain;
}


@media screen and (max-width: 768px) {
    .allProductItem {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.5rem;
    }

    .productherocontent {
        flex-direction: column;
    }

    .allProductContainer {
        padding: 0;
        margin-top: 16px;
        position: static;
    }

    .allProductHeading h2 {
        font-size: 2rem;
    }

    .allProductBtn {
        font-size: 16px;
        padding: 0.5rem 0.5rem;
        margin: 2rem 0 2rem 0.9rem;
        align-self: self-start;
    }

    .filterIcon {
        left: 2%;
        bottom: 2%;
        position: fixed;
        z-index: 10;
        top: 90%;
    }
}