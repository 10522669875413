.medical_content p{
    width: 84%;
    padding: 0.8rem 0;
    line-height: 1.3rem;
    font-size: 0.98rem;
    font-weight: 400;
    color: var( --secondaryTextColor);
}

.call_btn{
    padding: 1rem 2.5rem;
    font-size: 1.3rem;
    font-weight: 500;
    color: var(--primaryTextColor);
    font-family: Montserrat;
    border: 2px solid var(--primaryTextColor);
    border-radius: 50px;
    align-self: self-start;
    transition: 0.5s;
    margin: 1rem 0;
    background: transparent;
}
.call_btn:hover{
    /* background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%); */
    background-color: var(--primaryTextColor);
    color: var(--baseColor);
    box-shadow: 2px 4px 6px 1px var(--secondaryColor);
}
/*----- Mobile respnsive ----- */
@media only screen and (max-width: 768px) {
    .medical_content p{
        width: 100%;
        text-align: center;
        padding: 0.1rem 0;
        line-height: 1rem;
        font-size: 0.8rem;
    }
    .btn-box{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .call_btn{
        padding: 0.625rem 2rem;
        font-size: 1rem;
        margin: 0.925rem 0;
    
    }
}