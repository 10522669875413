.principles-main {
  width: 100%;
  /* background-color: var(--baseColor); */
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  padding:3rem 0;
}

.principles-content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  column-gap: 8%;
}

.principles-text {
  width: 50%;
  height: 50%;
}

.principles-text .heading {
  color: var(--primary2ndColor);
  font-size: 0.9375rem;
  font-weight: 400;
}

.principles-text .subheading {
  color: var(--secondaryTextColor);
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 1rem;
  width: 90%;
}

.principles-text .subheading span {
  color: var(--primary2ndColor);
  font-size: 1.25rem;
  font-weight: 500;
}

.principles-text p {
  margin-top: 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--secondaryTextColor);
}
.principles-img {
  width: 35%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.principles-img img {
  width: 100%;
  padding: 1rem;
  object-fit: cover;
}
/*------- media query -------  */
@media only screen and (max-width: 768px) {
  .principles-main {
    padding: 5% 2%;
  }
  .principles-content {
    display: flex;
    width: 95%;
    /* height: 450px; */
    align-items: center;
    /* justify-content: center; */
    gap: 1rem;
    column-gap: 8%;
    flex-direction: column;
  }

  .principles-text {
    width: 100%;
    height: auto;
  }
  .principles-text .heading {
    text-align: center;
    font-size: 0.625rem;
    width: 100%;
  }
  .principles-text .subheading {
    text-align: center;
    font-size: 0.625rem;
    width: 100%;
    margin: 0;
  }

  .principles-text .subheading {
    text-align: center;
    font-size: 0.6rem;
  }
  .principles-text .subheading span {
    text-align: center;
    font-size: 0.6rem;
  }
  .principles-text p {
    text-align: center;
    font-size: 0.625rem;
  }
  .principles-img {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .principles-img img {
    /* width: 75%;
    padding-top: 2rem; */
    padding: 0;
    object-fit: cover;
  }
}
