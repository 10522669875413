.otpInput {
    width: 40px !important;
    height: 40px !important;
    margin: 5px !important;
    text-align: center !important;
    font-size: 1.2em !important;
  }
.input_phone_otp{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 5%;
}