.primaryButton {
  position: relative;
  border: none;
  z-index: 0;
  color: var(--baseColor);
  text-align: center;
  font-family: Montserrat;
  line-height: normal;
  background: var(--primaryTextColor);
  flex: 1;
  padding: 1.2rem 1rem;
  cursor: pointer;
  border-radius: 50px;
  width: 15rem !important;
}

.pText {
  position: absolute;
  color: var(--baseColor) !important;
  transform: translateY(-50%);
  top: 50%;
  left: 1rem;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  transition: left 0.5s ease, color 0.5s ease;
}

.pButton {
  width: 4.4rem;
  height: 4.4rem;
  padding: 2px;
  border-radius: 50%;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: -2%;
  transition: right 0.5s ease;
  overflow: hidden;
}

.addIcon {
  width: 100%;
  height: 100%;
  font-size: 2rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(180deg, #6CB5E1 0%, #A5DCFB 100%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.plusIcon {
  font-size: 2rem;
  color: var(--primary-background);
}

.primaryButton:active .pText {
  left: 4.2rem;
}

.primaryButton:active .pButton {
  right: 8.3rem;
}

@media screen and (max-width: 768px){
  .pButton{
    width: 3.4rem;
    height: 3.4rem;
  }
  .pText{
    font-size: 14px;
    left: 4%;
  }
  .primaryButton{
    padding: 1.5rem 1.6rem !important;
  }
  .addIcon{
    font-size: 1.5rem;
  }
}