/* .medicine-section-bottom-box {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  padding: 1vw;
  position: relative;
} */

.innerProductBox {
  box-sizing: border-box;
  width: 93%;
  height: 37vh ;
  border: 1px solid var(--primaryTextColor);
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-evenly; */
  position: relative;
}

.discountCont {
  position: absolute;
  top: 0vw;
  left: 0vw;
  padding: .5vw;
  font-size: .8vw;
  height: 30px;
  background-color: var(--primaryTextColor);
  color: var(--commonWhite);
  display: flex;
  align-items: center;
  justify-content: center;
}

.medicine-img-allProduct-home{
  height: 3vh;
  width: 50%;
}
.medicine-commonCard-content-mrp-discount{
  width: 100%;
  display: flex;
  flex-direction: column;
  /* gap: 0.2rem; */
}
.mobile-section-bottom{
 margin: auto;
 height: 35vh;
}


.medicine-commonCard-productName{
  
  cursor: pointer;
  text-align: start;
}
.medicine-commonCard-productName:hover{
  text-decoration: underline;
}

.innerProductBox p {
  font-size: 2vh;
}

.actionButtons {
  width: 100%;
  font-size:1vw;
  color: var(--commonWhite);
  background-color: var(--primaryTextColor);
  padding: .5vw 1vw;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
}
.medicine-commonCard-sellingPrice,.medicine-commonCard-productName,.mrpCut{
  display: flex;
  align-items: center;
  justify-content: start;
}

.wishlistIconsHeartOutline {
  position: absolute;
  top: 1vw;
  right: 1vw;
  color: var(--primaryTextColor);
  font-size: 1.8vw;
  cursor: pointer;
}

.wishlistIconsHeart {
  position: absolute;
  top: 1vw;
  right: 1vw;
  color: var(--primaryTextColor);
  font-size: 1.8vw;
  cursor: pointer;
}


.medicine-commonCard-sellingPrice{
 display: flex;
 font-size: 0.9vw !important;
 align-items: center;
 justify-content: start;
}

.mrpCut {
  font-size: 1vw;
  color: var(--secondaryTextColor);

}

.needtowarp {
  width: fit-content;
  position: relative;
  text-decoration: line-through;
}

.productImageContainer{
  width: 100%;
  height: 12vh;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  object-fit:contain; 
}

.productImageContainer>img{
  height: 12vh; 
    width: 80%;
    margin: 0;
    object-fit: contain;
    background-size: 100% 100%;
}

.Medical-commonCard-img-content-button{
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  margin: 0rem 0.8rem 0rem 0.8rem;
  position: absolute;
  bottom: 0.9vh;
 gap:0.8rem;
 width: 90%;
}
.commonCard-wishlistIconsHeart-positioning{
  position: absolute;
  top: -0.5rem;
  right: 0rem;
}


 
.innerProductBox-img-slider{
  width: 100%;
  height: 80%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  object-fit:contain;
}

.innerProductBox>h4 {
  color: var(--secondaryTextColor);
  margin: .3vw 0;
}

.Medical-section-img-content-button{
  display: flex;
  align-items: center;
  flex-direction: column;
  align-items: center;
  margin: 0rem 0.8rem 0rem 0.8rem;
  position: absolute;
  bottom: 0.9vh;
 gap:0.6rem;
 width: 90%;
}

.medicine-section-content-mrp-discount{
  width: 100%;
  display: flex;
  flex-direction: column;

}

.innerProductBox-img-slider>img{
  height: 14vh; 
    width: 80%;
  margin: 0;
  object-fit: contain;
  background-size: 100% 100%;
}
@media only screen and (max-width: 768px) {
  .innerProductBox {
    box-sizing: border-box;
    width: 100%;
   height: 33vh;
    border: 1px solid var(--primaryTextColor);
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
    position: relative;
    margin-top: 0.8rem;
  }

  .medicine-section-bottom-box {
    display: flex !important;
    flex-direction: column;
    align-items: center;
    padding: 1vw;
    position: relative;
  }

  .innerProductBox-img-slider{
    width: 100%;
    height: 12vh;
    display: flex;
    align-items: end;
    /* justify-content: center; */
    margin: 0 auto;
    object-fit:contain;
  }

  .innerProductBox-img-slider>img{
    height:10vh; 
    width: 100%;
    margin: 0;
    object-fit: contain;
    background-size: 100% 100%;
  }

  .medicine-commonCard-productName{
    font-size: 1.5vh !important;
  }
  .mrpCut{
    font-size: 1.5vh !important;
    /* width: 10% !important; */
  }

  .medicine-commonCard-sellingPrice>h4{
    display: flex;
    font-size: 2vh !important;
    align-items: center;
    justify-content: start;
   }

  .actionButtons {
    /* height: 1vh; */
    font-size:1.8vh !important;
    color: var(--commonWhite);
    background-color: var(--primaryTextColor);
    padding: .5vw 1vw;
    border: none;
    cursor: pointer;
    width: 100%;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }

  .Medical-commonCard-img-content-button{
    display: flex;
    align-items: center;
    flex-direction: column;
    align-items: center;
    margin: 0rem 1.5vh 0rem 1.5vh;
    position: absolute;
    bottom: 0.9vh;
   gap: 1vh !important;;
  }

  .productImageContainer{
    width: 100%;
    height: 12vh;
    display: flex;
    align-items: end;
    margin: 0 auto;
    object-fit:contain;
  }

  .productImageContainer>img{
    height: 10vh; 
      width: 100%;
      margin: 0;
      object-fit: contain;
      background-size: 100% 100%;
  }

  .commonCard-wishlistIconsHeart-positioning{
    position: absolute;
    top: 0rem;
    right: 0rem;
  }

  .innerProductBox p {
    font-size: 1.7vh !important; 
    font-weight: 600;
    display: flex;
  }
  .mrpCut {
    font-size: 1.6vh !important;
    color: var(--secondaryTextColor);
  
  }
  
  
  .wishlistIconsHeartOutline,
  .wishlistIconsHeart {
    font-size: 2rem; 
  }

  .discountCont {
    height: 3vh;
    font-size: 3vw !important; 
    padding: 0.5rem; 
  }
}
@media only screen and (max-width: 320px) {
  .innerProductBox {
    width: 100%;
    height: 30vh; 
    margin-top: 0.5rem; 
  }

  .innerProductBox-img-slider {
    height: 7vh; 
  }

  .mrpCut {
    font-size: 3.5vw; 
  }


  .Medical-commonCard-img-content-button {
    gap: 0.4rem; 
  }

  .productImageContainer {
    height: 9vh;
  }

  .productImageContainer > img {
    height: 100%;
    width: 100%;
  }

  .commonCard-wishlistIconsHeart-positioning {
    top: 0.2rem;
    right: 0.2rem; 
  }

  .innerProductBox p {
    font-size: 1.7vh !important; 
    font-weight: 600;
  }

  .actionButtons {
    height: 4vh;
    font-size: 0.8rem; 
    padding: 0.3rem 0.6rem;
  }

  .wishlistIconsHeartOutline,
  .wishlistIconsHeart {
    font-size: 1.5rem;
  }

  .discountCont {
    height: 3vh;
    font-size:3.5vw; 
    padding: 0.3rem; 
  }
  .medicine-commonCard-sellingPrice{
    font-size: 3.5vw !important;
  }
}

/* iphone 6s and vivo v5s */
@media only screen and (min-width: 321px) and (max-width: 375px) {
  .innerProductBox {
    width: 100%; 
    height: 30vh; 
  }

  .innerProductBox p {
    font-size: 3.5vw !important; 
  }

.medicine-commonCard-sellingPrice{
  font-size: 3.5vw !important;
}

  .innerProductBox-img-slider {
    height: 9vh;
  }

  .innerProductBox-img-slider > img {
    height: 100%;
    width: 100%; 
  }

  .mrpCut {
    font-size: 3.5vw; 
  }

  .actionButtons {
    font-size: 0.9rem; 
    height: 2rem; 
  }

  .productImageContainer {
    height: 9vh; 
  }

  .productImageContainer > img {
    height: 100%;
    width: 100%; 
  }

  .wishlistIconsHeartOutline,
  .wishlistIconsHeart {
    font-size: 1.8rem; 
  }

  .discountCont {
    font-size: 1rem; 
    padding: 0.4rem;
  }
}

/* Responsive CSS for Vivo V5s */
@media only screen and (min-width: 376px) and (max-width: 414px) {
  .innerProductBox {
    width: 100%; 
    height: 30vh; 
  }

  .innerProductBox p {
    font-size: 1.6vh !important; 
  }

  .innerProductBox-img-slider {
    height: 9vh; 
  }

  .innerProductBox-img-slider > img {
    height: 100%;
    width: 100%; 
  }

  .mrpCut {
    font-size: 2vw; 
  }

  .actionButtons {
    font-size: 1rem; 
    height: 2.2rem; 
  }

  .productImageContainer {
    height: 9vh; 
  }

  .productImageContainer > img {
    height: 100%;
    width: 100%; 
  }

  .wishlistIconsHeartOutline,
  .wishlistIconsHeart {
    font-size: 2rem;
  }

  .discountCont {
    font-size: 1.1rem; 
    padding: 0.5rem; 
  }
}


