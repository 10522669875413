.medical_test_services {
  width: 100%;
  background-color: var(--primaryTextColor);
  padding: 3rem 3rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  position: relative;
}

.medical_test_head {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 60%;
  margin: auto;
}

.medical_test_head h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--buttonColor);
}

.medical_test_head h2 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--buttonColor);
}

.medical_test_head p {
  font-size: 0.938rem;
  font-weight: 400;
  color: var(--buttonColor);
  text-align: center;
}

.medical_test_content {
  height: 50vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 1rem;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  padding: .1rem;
}
.medical_test_content.expanded {
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 1rem;
  padding: 2rem .1rem;
}
.medical_test_card {
  height: 12.5rem;
  width: 100%;
}
.test_card_item_expend {
  width: 100%;
padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  border-radius: 10px;
  box-shadow: 0 2px 3px var(--boxColor);
  cursor: pointer;
}
.test_card_item_expend div:nth-child(1) {
  width: 3.375rem;
  height: 3.816rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.test_card_item_expend div:nth-child(2) {
  width: 60%;
}
.test_card_item_expend p {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--baseColor);
}
.medical_test_card:hover .test_card_item_expend {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.test_card_item {
  padding: 10%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
  border-radius: 10px;
  box-shadow: 0 2px 3px var(--boxColor);
  cursor: pointer;
}
/* img */
/* .medical_test_card_img{
  height: 100px ;
  width: 100px;

} */

.test_card_item div:nth-child(1) {
  width: 3.375rem;
  height: 3.816rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.test_card_item div:nth-child(2) {
  width: 60%;
}

.test_card_item p {
  font-size: 0.968rem;
  font-weight: 600;
  color: var(--baseColor);
}

.medical_test_card:hover .test_card_item {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.medical_test_card:hover .test_card_hide {
  height: 60%;
}

.test_card_hide {
  height: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  background-color: var(--baseColor);
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition: height 0.4s;
}

.test_card_hide p {
  font-size: 1.2rem;
  font-weight: 600;
  color: var(--secondaryTextColor);
}

.test_card_hide button {
  margin: 1rem;
  padding: 0.5rem 0.5rem;
  border-radius: 3.125rem;
  font-size: 1rem;
  font-weight: 500;
  color: var(--primaryTextColor);
  border: 2px solid var(--primaryTextColor);
  cursor: pointer;
}

.test_card_hide button:hover {
  background: linear-gradient(180deg, #4093c5 0%, #a5dcfb 100%);
  color: var(--baseColor);
  box-shadow: 2px 4px 6px 1px var(--secondaryColor);
  border: none;
}

.medical_service_button {
  margin: auto;
  padding: 1.3rem 2rem;
  border-radius: 3.125rem;
  font-size: 1.5rem;
  font-weight: 700;
  color: var(--baseColor);
  border: 2px solid var(--baseColor);
  background-color: var(--primaryTextColor);
  cursor: pointer;
  display: flex;
  justify-self: flex-end;
}

.medical_service_button:hover {
  background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
  color: var(--baseColor);
  box-shadow: 2px 4px 6px 1px var(--secondaryColor);
  border: none;
}

.overlayCss {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent black */
  z-index: 999; /* ensure the overlay is above other content */
  display: flex;
  justify-content: center; /* horizontally center */
  align-items: center; /* vertically center */
}

.process_card_overlay {
  padding: 1rem;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  backdrop-filter: none; /* remove blur effect */
}
/*----- Mobile respnsive ----- */
@media only screen and (max-width: 768px) {
  .medical_test_head {
    width: 100%;
    gap: 0.525rem;
  }
  .medical_test_head h3,
  .medical_test_head p {
    font-size: 0.625rem;
  }
  .medical_test_head h2 {
    font-size: 1rem;
  }
  .medical_test_content.expanded {
    height: 100%;
    /* display: grid;
    grid-template-columns: 1fr 1fr;
    
    padding: 2rem .1rem; */
    display: flex;
    flex-wrap: wrap;
    gap: 0rem;
  }
  .medical_service_button {
    margin: auto;
    padding: 1rem 1rem;
    border-radius: 3.125rem;
    font-size: 1rem;
  }
  .medical_test_content{
    /* height: 100%;
   grid-template-columns: 1fr 1fr; */
   display: flex;
   flex-wrap: wrap;
   gap: 0rem;
  }
  .medical_test_services{
    padding: 2%;
  }

  .medical_test_card:hover .test_card_hide{
    height: 45%;
  }
  .test_card_hide p{
    font-size: .9rem;
  }
  .test_card_hide button{
    margin: .5rem;
  }

  .test_card_item_expend {

  padding: 1rem .1rem;
   
  }

  .process_card_overlay{
    width: 95%;
    padding: 10px;
  }
}
