.signupSection {
  width: 100%;
  height: 90vh;
  background: url("../../Assets/Images/loginSignup/back.png");
  background-repeat: no-repeat;
  background-position: 65px 75%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 999;
}

.signupContainer {
  width: 42%;
  height: fit-content;
  background: var(--commonWhite);
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border: 2px solid var(--primaryTextColor);
  text-align: center;
  padding: 3rem 0;
  position: relative;
}

.signupContainer > h1 {
  color: var(--primaryTextColor);
}

.signupContainer > h1 > span {
  color: var(--secondaryTextColor);
}

.googleSignup {
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem auto;
  padding: 0.5rem 1rem;
  /* border: 1px solid var(--primaryTextColor); */
  font-weight: 500;
  color: var(--primaryTextColor);
  font-weight: 500;
  cursor: pointer;
}

.googleSignup img {
  width: 30px;
  margin-right: 10px;
}

.formContainer {
  width: 100%;
}

.signupWithEmail {
  width: 70%;
  display: flex;
  align-items: center;
  margin: 2rem auto;
  color: #b8b8b8;
}

.signupBorders {
  flex-grow: 1;
  height: 1px;
  background-color: #b8b8b8;
  margin: 0 10px;
}

.formInputs {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  column-gap: 5%;
  row-gap: 1rem;
}

.formInputs input::placeholder {
  color: #b8b8b8;
}

.formInputs > input[type="text"],
.formInputs > input[type="email"],
.formInputs > input[type="number"],
.formInputs > input[type="password"] {
  width: 47%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid var(--primaryTextColor);
  outline: none;
  font-size: 1rem;
  position: relative;
}
.inputPassSec {
  width: 47%;
  position: relative;
}
.inputPassSec > input[type="password"],
.inputPassSec > input[type="text"] {
  width: 100%;
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid var(--primaryTextColor);
  outline: none;
  font-size: 1rem;
}
.inputPassSec span {
  position: absolute;
  right: 4%;
  top: 21%;
  color: var(--primaryTextColor);
  cursor: pointer;
  font-size: 1.2rem;
}

.formInputs > button {
  padding: 1rem 4rem;
  background-color: var(--primaryTextColor);
  font-size: 1rem;
  color: #fff;
  border: none;
  cursor: pointer;
  margin: 1rem;
}

.formContainer > button {
  padding: 1rem 4rem;
  background-color: var(--primaryTextColor);
  font-size: 1rem;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 1rem;
  align-self: center;
}

.formInputs > button:hover {
  background-color: #0056b3;
}
.formContainer p {
  color: #b8b8b8;
  padding-top: 1rem;
  font-size: 0.9rem;
  font-weight: 500;
  cursor: pointer;
}
.formContainer p span {
  color: #0056b3;
}


/* Hide spinners in Chrome, Safari, Edge, and Opera */
.number_input[type="number"]::-webkit-outer-spin-button,
.number_input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Hide spinners in Firefox */
.number_input[type="number"] {
    -moz-appearance: textfield;
}
.login_signup_close_btn{
  position: absolute;
  top: 2%;
  right: 2%;
  cursor: pointer;
  font-size: 1.3rem;
  font-weight: 600;
  color: var(--secondaryTextColor);
}

.login_signup_close_btn:hover{
  background-color: var(--secondaryTextColor);
  color: var(--commonWhite);
}








/*----- respnsive ----- */
@media only screen and (max-width: 768px) {
  .signupSection {
    background-color: red;
    width: 100%;
    height: auto;
    background: #fff;
    /* background: url("../../Assets/Images/loginSignup/back.png"); */
    background-repeat: no-repeat;
    background-position: 10px 94%;
    /* z-index: -9; */
    background-size: 800px 250px;
    padding: 1rem 0 1rem 0;
  }
  .googleSignup {
    /* width: 53vw; */
    cursor: pointer;
    margin: 3vh auto 0vh auto;
  }
  .googleSignup > p {
    padding: 2vw;
    font-size: 2vh !important;
    border: 1px solid var(--primaryTextColor);
  }

  .signupContainer {
    width: 95%;
    height: 85vh;
    background: transparent;
  }
  .inputPassSec span {
    right: 9%;
  }
  form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .formInputs > input[type="text"],
  .formInputs > input[type="email"],
  .formInputs > input[type="number"],
  .formInputs > input[type="password"] {
    width: 83vw;
    height: 6vh;
    font-size: 3.13vw;
  }
  .inputPassSec {
    width: 100%;
    font-size: 0.925rem;
  }
  .formInputs > button {
    padding: 2vh 35vw;
    margin-top: 0;
    width: 83vw;
    font-size: 0.925rem;
  }
  h1 {
    font-size: 10vw;
  }
  .inputPassSec > input[type="password"] {
    width: 83vw;
    height: 6vh;
    font-size: 3.13vw;
  }
  .formContainer p {
    font-size: 3.9vw;
  }
}
