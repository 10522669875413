* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.order-history-container {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  background: transparent
    linear-gradient(319deg, rgb(174, 174, 174) 0%, #03080d73 42%, #112742 100%)
    0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.Order_history_pop_up {
  height: 85vh;
  width: 60vw;
  background-color: var(--baseColor);
  overflow: hidden;
  position: fixed;
}
.nameorder {
  width: 100%;
  height: 6vh;
  background-color: var(--primaryTextColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}
.nameorder h1 {
  font-size: 1.3vw;
  color: var(--baseColor);
}
.nameorder h5 {
  font-size: 4vh;
    color: var(--baseColor);
    cursor: pointer;
    font-weight: 800;
}
.Order_history_pop_up_first_div {
  height: 11vh;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  border: 1px solid rgb(222, 222, 222);
  gap: 2vh;
  border-radius: 5px;
  overflow-x: scroll;
}
.Order_history_pop_up_first_div::-webkit-scrollbar{
  display: none;
}

.order-history-popuplist {
  width: auto;
  height: auto;
  display: flex;
  gap: 1vh;
  flex-direction: column;
}
.orderbox1 {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 1vh;
}

.orderbox1 p {
  font-size: 18px;
}
.order-adress {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
.order-adress p {
  text-align: center;
}
.Order_history_pop_up_first_div div:nth-child(1) {
  width: 50%;
  text-align: center;
}
.Order_history_pop_up_first_div div:nth-child(1) p {
  color: var(--primaryTextColor);
  font-size: 2vh;
  font-weight: 500;
}
.Order_history_pop_up_first_div div:nth-child(1) p span {
  color: var(--secondaryTextColor);
  font-weight: 600;
}

.Order_history_pop_up_first_div div:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  width: 50%;
}
.Order_history_pop_up_first_div div:nth-child(2) p {
  color: var(--primaryTextColor);
  font-size: 0.95rem;
  font-weight: 500;
}
.Order_history_pop_up_first_div div:nth-child(2) p span {
  color: var(--secondaryTextColor);
  font-weight: 600;
}

.Order_history_pop_up_first_div div:nth-child(3) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1vh;
  width: 100%;
  /* padding: 0 2%; */
}
.Order_history_pop_up_first_div div:nth-child(3) p:nth-child(1) {
  color: var(--primaryTextColor);
  font-size: 0.95rem;
  font-weight: 500;
  width: auto;
  text-align: center;
}

.Order_history_pop_up_first_div div:nth-child(3) p:nth-child(2) {
  color: var(--secondaryTextColor);
  font-weight: 700;
  font-size: 0.95rem;
  font-weight: 500;
  width: auto;
  /* padding-top: 8%; */
}

.Order_history_pop_up_first_div div:nth-child(3) p:nth-child(3) {
  display: flex;
  gap: 2%;
  text-align: center;
}

.Order_history_pop_up_second_div {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  height: 80%;
  padding: 2% 4%;
  column-gap: 3%;
  row-gap: 5%;
  justify-content: center;
  overflow: auto;
}

.Order_history_pop_up_second_div::-webkit-scrollbar{
  display: none;
}

.Order_history_card {
  width: 100%;
  height: 30vh;
  padding: 2%;
  border: 1px solid rgb(225, 225, 225);
  border-radius: 0.3125rem;
  box-shadow: 1px 1px 3px var(--boxColor);
 position: relative;
}

.Order_history_card .Order_history_card_img_box {
  width: 100%;
  height: 65%;
  object-fit: contain;
}

.Order_history_card .Order_history_card_img_box_img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.Order_history_card div:nth-child(2) {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* border-top: 1px solid var(--baseColor); */
}

.Order_history_card div:nth-child(2) div {
  display: flex;
  border: none;
}

.Order_history_card div:nth-child(2) p {
  color: var(--primaryTextColor);
  font-size: 2vh;
  font-weight: 500;
  cursor: pointer;
}
.Order_history_card div:nth-child(2) p span {
  color: var(--secondaryTextColor);
  font-size: 13px;
  font-weight: 500;
  cursor: pointer;
}
.Order_history_card div:nth-child(2) p span:hover {
  text-decoration: underline;
}

.pagination button {
  padding: 1%;
  border: none;
  outline: none;
  color: var(--primaryTextColor);
  font-size: 1.25rem;
  font-weight: 400;
  cursor: pointer;
}
.Order_history_card button{
  font-size: 2vh;
  font-weight: 500;
  color: var(--baseColor);
  background-color: var(--primaryTextColor);
  border: none;
  outline: none;
  padding: 2% 0;
  width: 50%;
  margin-right: 0;
  position: absolute;
  right: 2%;
  bottom: 2%;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .Order_history_pop_up {
    width: 95%;
    height: 70vh;
  }
  .Order_history_pop_up_first_div div:nth-child(2) p {
    color: var(--primaryTextColor);
    font-size: 10px;
    font-weight: 500;
  }
  .Order_history_pop_up_first_div div:nth-child(2) p span {
    color: var(--secondaryTextColor);
    font-size: 10px;
    font-weight: 500;
  }
  .Order_history_pop_up_first_div div:nth-child(1) p {
    color: var(--primaryTextColor);
    font-size: 10px;
    font-weight: 500;
  }
  .Order_history_pop_up_first_div div:nth-child(3) p:nth-child(2) {
    font-size: 10px;
  }
  .Order_history_pop_up_first_div div:nth-child(3) p:nth-child(1) {
    font-size: 10px;
    font-weight: 800;
  }

  .nameorder h1 {
    font-size: 2.3vh;
  }

  /* card */
  .Order_history_pop_up_second_div {
    grid-template-columns: repeat(auto-fit, minmax(8.5rem, 1fr));
    padding: 1% 2%;
    column-gap: 3%;
    row-gap: 3%;
    height: 75%;
    justify-content: center;
  }
  .Order_history_card div:nth-child(1) {
    width: 100%;
    height: auto;
    object-fit: contain;
  }
  .Order_history_card div:nth-child(1) img{
    height: 10vh;
  }
  .Order_history_card{
    height: 25vh;
    padding: 3%;
    width: 100%;
  } 
}
