.brandCategories {
    padding: 3%;
    width: 100%;
    height: fit-content;
    background: var(--thirdBackground);
}

.brandCategories>h1 {
    text-align: center;
    color: var(--secondaryTextColor);
}

.brandCategories>h1>span {
    text-align: center;
    color: var(--primaryTextColor);
}

.brandCarousal {
    width: 100%;
    margin: 3rem 0;
}

.brandSlides {
    width: 90%;
    height: 13.188rem;
    padding: 2rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    row-gap: 1rem;
    margin: auto;
    border: 2px solid var(--primaryTextColor);
    cursor: pointer;
}

.brandSlides>div {
    width: 4.375rem;
    height: 4.375rem;
    margin: auto;
    cursor: pointer;
}

.brandSlides>div>img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    mix-blend-mode: multiply;
}

.brandSlides>button {
    width: fit-content;
    margin: auto;
    padding: .5rem 1rem;
    border-radius: 5rem;
    color: var(--primaryTextColor);
    border: 2px solid var(--primaryTextColor);
    font-weight: bold;
    cursor: pointer;
    background: var(--thirdBackground);
}
.brandSlides>button:hover{
    color: white;
    background-color:var(--primaryTextColor) ;
    transition: 1s ease-in-out;
}

.prevbrand{
    left: -1rem !important;
}
.nextbrand{
    right: -1rem !important;
}

/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
    .brandCategories>h1 {
        font-size: 1.5rem;
    }

    .brandCategories {
        padding: 5% 1% 1% 1%;
    }

    .brandSlides {
        height: 10.688rem;
        padding: 1rem;
    }

    .brandSlides>button {
        padding: 0.3rem 0.4rem;
        font-size: 0.625rem;
    }

    .brandCarousal {
        margin: 2rem 0;
    }
    .categoriesSlides{
        padding: 0;
        width: 100%;
    }
    .prevbrand{
        left: 0 !important;
    }
    .nextbrand{
        right: 0 !important;
    }
    .brandSlides p{
        font-size: 1.6vh;
        font-weight: 500;
    }
}