.labPackages {
    width: 100%;
    height: fit-content;
    padding: 1.5vw 0;

}

.labPackages>h1 {
    text-align: center;
    color: var(--primaryTextColor);
    font-size: 2.5vw;

}

.labpackageSection {
    width: 100%;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 1rem;
}

.labPackageBox {
    padding: .5rem;
    background-color: var(--baseColor);
    height: 50vh;
    position: relative;
    border-radius: 0.2vw;
}

.testImage {
    width: 100%;
    height: 35%;
    border-radius: .2vw;
    overflow: hidden;
    position: relative;
}

.labPackageBox:hover .testImage img {
    filter: brightness(70%);
}

.testImage>img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: filter 0.3s;
}

.labPackageBox>h3 {
    color: var(--primaryTextColor);
    font-size: 1vw;
    margin: .4vw 0;

}

.labPackageBox>h5 {
    font-size: .8vw;
    color: var(--secondaryTextColor);

}

.testsLists {
    height: 37%;
    overflow: auto;
    padding: .3vw;
}



.dotBox {
    width: .4vw;
    height: .4vw;
    background-color: var(--primaryTextColor);
}

.testsLists>span {
    display: flex;
    column-gap: .3vw;
    align-items: center;
    font-size: .8vw;
}


.backdropModal {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    z-index: 1000;
    /* Ensure it's above other content */
}

.pricings {
    display: flex;
    align-items: center;
    padding: .5vw 0;
    justify-content: space-between;
  

}

.pricings>button {
    outline: none;
    padding: .3vw 1.5vw;
    font-size: 1vw;
    border: none;
    background-color: var(--primaryTextColor);
    color: var(--commonWhite);
    display: flex;
    column-gap: .2vw;
    align-items: center;
    border-radius: .3vw;
    cursor: pointer;
}

.pricingButton {
    color: var(--primaryTextColor);
    background-color: var(--commonWhite);
    text-align: center;
    cursor: pointer;
    font-size: 1.8vw;
    border-radius: 50%;
    padding: .1vw;
}

.mrpClass {
    font-size: 1vw;
    text-decoration: line-through;
    color: var(--primary2ndColor);
}

.viewMoreButton {
    outline: none;
    padding: .6vw 1.5vw;
    font-size: 1vw;
    border: none;
    background-color: var(--primaryTextColor);
    color: var(--commonWhite);
    display: flex;
    column-gap: .2vw;
    align-items: center;
    border-radius: .3vw;
    cursor: pointer;
    position: absolute;
    right: 1vw;
}


@media only screen and (max-width: 768px) {
    .labPackages>h1 {
        font-size: 2.5vh;
    }

    .labpackageSection {
        width: 100%;
        padding: 1rem;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 1rem;
    }

    .labPackageBox {
        padding: .5rem;
        height: 50vw;
        border-radius: 0.2vh;
    }

    .testImage {
        border-radius: .2vh;
    }

    .labPackageBox>h3 {
        color: var(--primaryTextColor);
        font-size: 1vh;
        margin: .4vh 0;
    }

    .labPackageBox>h5 {
        font-size: .8vh;
    }

    .testsLists {
        overflow: auto;
        padding: .3vh;
    }

    .testsLists>span {
        column-gap: .3vh;
        font-size: .8vh;
    }

    .pricings {
        padding: .5vh 0;
        margin: .8vh 0;
    }

    .mrpClass {
        font-size: 1vh;
        text-decoration: line-through;
        color: var(--primary2ndColor);
    }


    .pricings>button {
        padding: .3vh 1.5vh;
        font-size: 1vh;
        column-gap: .2vh;
        border-radius: .3vh;
    }

    .viewMoreButton {
        font-size: 1.3vh;
        font-weight: 600;
    }

}