.primary-button{
    border: 1px solid var(--primaryTextColor);
    color: var(--primaryTextColor);
    padding: 8px 15px;
    border-radius: 50px;
    font-size: 15px;
    font-weight: 700;
    background: transparent;
    /* height: 40px; */
    cursor: pointer;
}
.primary-button:hover{
    background-color:var(--primaryTextColor);
    color: #fff;
}
    

@media only screen and (max-width: 768px){
.primary-button{
    padding: 8px 13px;
    font-size: 0.625rem;
}
}
@media only screen and (min-width:1920px)
{
    .primary-button{
        height:6vh ;
        width: 60%;
        font-size: 0.9vw;
    }
}
