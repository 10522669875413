.section6main {
  width: 100%;
  margin: 1rem 0 1rem 0;
  display: flex;
  justify-content: space-around;
  /* align-items: center; */
  padding: 1rem;
  /* background-color: rgb(250, 212, 212); */
  gap: 0.625rem;
}

.section6content {
  width: 30%;
  display: flex;
  flex-direction: column;
  /* background-color: rgb(205, 239, 205); */
}

.section6text h1 {
  color: var(--secondaryTextColor);
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 5rem;
  letter-spacing: 0.0125rem;
}

.section6text span {
  color: var(--primaryTextColor);
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 5rem;
  letter-spacing: 0.0125rem;
}

.section6text p {
  color: var(--secondaryTextColor);
  font-family: Poppins;
  font-size: 1rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.875rem;
  letter-spacing: 0.0125rem;
}

.testimonials6 {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.testimonials-photo6 {
  margin-right: -15%;
  z-index: 100;
  height: 20vh;
}

.testimonials-photo6 img {
  height: 100%;
}

.testimonials-content6 {
  height: 33vh;
  width: 787px;
  padding-left: 20%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 50px;
  align-items: flex-start;
  padding-right: 8%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.testimonials-content6 div {
  color: var(--secondaryTextColor);
  font-size: 20px;
  font-weight: 400;
}

.testimonials-content6 h3 {
  margin: 1rem 0;
  color: var(--secondaryTextColor);
}

.testimonials-content6 p {
  color: var(--secondaryTextColor);
}

.testimonials-content6 button {
  margin-top: 1rem;
  width: 140px;
  height: 50px;
  border-radius: 50px;
  border: none;
  color: var(--baseColor);
  background: var(--primary2ndColor);
  font-weight: 600;
  font-size: 1rem;
  cursor: pointer;
}

.testimonials-page-points6 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: -6%;
}

.testimonials-page-point6 {
  width: 18px;
  height: 18px;
  background-color: var(--secondaryColor);
  margin: 10px 0;
  border-radius: 50%;
  cursor: pointer;
}

.testimonials-active-point6 {
  width: 18px;
  height: 50px;
  background-color: var(--primaryTextColor);
  border-radius: 50px;
  cursor: pointer;
}

.section6item {
  display: flex;
  align-items: center;
  gap: 3.6rem;
  margin: 0 0 3rem 0;
  width: 100%;
  /* background-color: rgb(207, 247, 207); */
}

.ratingandreview {
  width: 100%;
  /* background-color: rgb(227, 206, 206); */
}

.hd span {
  color: var(--primaryTextColor);
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 5rem;
  letter-spacing: 0.0125rem;
}

.hd {
  color: var(--secondaryTextColor);
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 5rem;
  letter-spacing: 0.0125rem;
  align-self: flex-start;
  text-align: center;
}

.ratingboard {
  display: grid;
  place-items: center;
}

.rating {
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 2rem;
  justify-content: center;
}

.rating h1 {
  font-size: 6rem;
  color: var(--secondaryTextColor);
}

.rating p {
  letter-spacing: 0.1rem;
}

.rating-average {
  width: 100%;
  /* background: red; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.star {
  position: relative;
  font-size: 2rem;
  display: inline-block;
}

/* .star::before {
  content: "\2605 \2605 \2605 \2605 \2605";
  color: #fac411 ;
} */

.starinner {
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
}

.starinner::before {
  content: "\2605 \2605 \2605 \2605 \2605";
  color: gold;
}

.ratingbar {
  width: 70%;
  display: none;
  gap: 0.5rem;
  flex-direction: column;
}

.ratingbarvalue {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.str {
  font-size: 1.2rem;
  color: gold;
}

.ratingbarvalue p:last-child {
  width: 10%;
}

.progress {
  flex: 1 1 0;
  height: 0.6rem;
  border-radius: 0.5rem;
  background-color: var(--primaryTextColor);
}

.bar {
  height: 100%;
  background-color: var(--primary2ndColor);
  border-radius: 0.5rem;
}

.usershow {
  width: 50%;
  /* height: 550px; */
  /* background-color: rgb(217, 188, 243); */
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
}

.overthreeitem {
  width: 50%;
  height: 550px;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  overflow: hidden;
  overflow-y: scroll;
}

.user_review_text_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  /* background-color: green; */
}
.user_review_text_container h3 {
  font-size: 20px;
  color: #727171;
}
.user_review_text_container select {
  width: 16.75rem;
  height: 2.6875rem;
  padding: 0rem 1rem;
  background-color: #d9d9d9;
  border-radius: 0.3125rem;
  cursor: pointer;
  outline: 0;
}
.buttons_star_filter {
  width: 100%;
  height: auto;
  /* background-color: pink; */
  display: flex;
  gap: 0.625rem;
}

.filter_buttons {
  width: 70px;
  height: 30px;
  background-color: #196ea4;
  cursor: pointer;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  border: none;
}
.activebuttons{
  width: 70px;
  height: 30px;
  background-color: #7dabc8;
  cursor: pointer;
  border-radius: 0.3125rem;
  display: flex;
  justify-content: center;
  gap: 0.5rem;
  align-items: center;
  border: none;
}
.buttons_star_filter button span{
  color: white;
  font-size: 16px;
}

.user-review_container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  gap: 0.3125rem;
  padding: 0.6rem;
  /* background-color: rgb(226, 197, 197); */
}
.user_star_name_container {
  width: 100%;
  height: 60px;
  /* background-color: aqua; */
  display: flex;
  gap: 0.625rem;
  align-items: center;
}

.user_icon {
  width: 31px;
  height: 31px;
  border-radius: 100%;
  background-color: #a5dcfb80;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon_user {
  color: #196ea4;
}


.star_icon {
  width: 15px !important;
  height: 14px !important;
  color: #fac411;
}

 .name-name {
  font-size: 12px !important;
  font-weight: 600;
  color: #196ea4;

}
.date_date_date{
  font-size: 10px !important;
  font-weight: 600;
  color: #196ea4;
}
.star_icon_star{
  width: 27px;
  height: 27px;
  color: #fac411;
}
.user-review_container p {
  font-size: 15px;
  font-weight: 500;
  color: #727171;
  line-height: 30px;
}

@media screen and (max-width: 765px) {
  .hd {
    font-size: 1.5rem;
    text-align: center;
  }
  .hd span {
    font-size: 1.5rem;
  }
  .section6item {
    flex-direction: column;
  }
  .usershow {
    width: 100%;
  }

  .section6main {
    flex-direction: column;
  }
  .section6content{
     width: 100%;
  }
  .overthreeitem {
    width: 100%;
  }
}

