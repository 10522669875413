.formmain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
  width: 100%;
}

.formcontent {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

form {
  display: flex;
  flex-direction: column;
  width: 60%;
}

.formcontent h1 {
  text-align: center;
  color: #196ea4;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-group {
  display: flex;
  gap: 1rem;
}
.first-control {
  width: 48.8%;
  padding: 10px;
  margin: 1rem 0;
  border: 1px solid #196ea4;
  border-radius: 0.325rem;
  outline: none;
  padding: 1rem;
}

.form-control {
  width: 100%;
  padding: 10px;
  margin: 1rem 0;
  border: 1px solid #196ea4;
  border-radius: 0.325rem;
  outline: none;
  padding: 1rem;
}

.message {
  resize: vertical;
}

.btn-submit {
  width: 168px;
  /* height 57px; */
  background-color: #196ea4;
  color: #fff;
  padding: 18px;
  border: none;
  text-align: center;
  align-self: flex-end;
  border-radius: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
}

/*------- media query -------  */
@media only screen and (max-width: 768px) {
  .formcontent h1 {
    font-size: 1.25rem;
  }
  .formcontent {
   padding: 2rem;
  }
  .formmain {
    height: 70vh;
  }
  form {
    width: 90%;
    /* background-color: red; */
  }
  .form-group input{
    height: 48px;
  }
  .btn-submit{
    Width:130px;
    Height:40px;
    background: transparent;
    border: 1px solid #196EA4;
    color: #196EA4;
    font-size: 0.625rem;
  }
 
}


