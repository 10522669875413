.medicine-section {
  padding: 4% 2%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  text-align: center;
  width: 100%;
}

.medicine-section-middle h1 {
  font-family: "MonteCarlo", cursive !important;
  font-weight: 300;
  margin-bottom: 3%;
  margin-top: 3%;
}

.mobile-section-bottom {
  width: 100%;
  height: 50vh;

}

.medicine-section-bottom-box {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.hover-content {
  position: absolute;
  padding: 0 20px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  flex-direction: column;
  margin-top: 10%;
}

.hover-content span {
  color: var(--baseColor);
}

.hover-content-bottom {
  display: flex !important;
  flex-direction: row !important;
  justify-content: center;
  align-items: center;
  margin-top: 16%;
}

.hover-content-bottom-icon {
  width: 2.3rem;
  height: 2.3rem;
  color: var(--baseColor);
  bottom: 0;
  cursor: pointer;
}

.hover-content-bottom div {
  border-top: 1.5px solid var(--baseColor);
  padding-top: 15px;
  padding-bottom: 0px;
  border-radius: 50px;
}

.hover-content-bottom button {
  border: none;
  color: var(--primaryTextColor);
  padding: 8px 10px;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin-top: -10px;
}

.medicine-section-bottom-box p {
  /* font-size: 0.9rem; */
  font-weight: 400;
  color: var(--primaryTextColor);
}


/*/// second card /// */
.second_card {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  width: 11.2rem;
  height: 13.18rem;
  border: 1px solid var(--secondaryTextColor);
  border-radius: 7px;
  padding: 4.2% 0 0 0;
  position: relative;
  box-shadow: 2px 4px 6px var(--boxShadow);
}

.second_card img {
  height: 5.125rem;
  width: 5.125rem;
}

.second_card_btn {
  width: 100%;
  padding: 0 1rem;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 5%;
}

.second_card button {
  border: none;
  color: var(--primaryTextColor);
  padding: 5px 8px;
  border-radius: 50px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin-top: -10px;
}

.second_card p {
  font-size: 0.983rem;
  font-weight: 500;
  color: var(--primaryTextColor);
}

.second_card span {
  font-size: 0.6rem;
  font-weight: 400;
  color: var(--secondaryTextColor);
}

.second_card button {
  border: 1px solid var(--primaryTextColor);
  color: var(--primaryTextColor);
  background: transparent;
  font-size: 0.683rem;
  font-weight: 700;
}

.second_card_btn span {
  color: var(--primaryTextColor);
  font-size: 1rem;
}

.classhe {
  background-color: green;
}

@media only screen and (max-width: 768px) {
  .medicine-section-top h1{
    font-size: 6vw;
  }
  .medicine-section-top,.medicine-section-middle{
    font-size: 7vw;
  }
  .medicine-section-middle h1{
    font-size: 7vw;
  }
}
/* @media only screen and (max-width:1366px) and (max-height:1024px)
{
  .medicine-section-bottom-box{
    height: 40vh;
  }
} */
@media only screen and (min-width:1920px)
{
  .medicine-section-bottom-box{
    height: 40vh;
  }
}
