.filter_pop_up {
    height: 58vh;
    width: fit-content;
    width: 60vw;
    border-radius: 11px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 0;
    transform: translate(30%, -50%);
    flex-wrap: wrap;
    background-color: var(--secondaryColor);
    display: flex;
    padding: 5% 0 5% 21%;
}

.filter_icon {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 0 4% 0 0;
    position: sticky;
    z-index: 0;
    cursor: pointer;
}
.filter_icon_cross{
    font-size: 3vh;
    color: var(--boxColor);  
    font-weight: 900;
}
.filter_icon_cross:hover{
    background-color: var(--secondaryTextColor);
}
.filter_page_list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    height: 88%;
    overflow-y: scroll;
    width: 100%;
}

.filter_page_list::-webkit-scrollbar {
    display: none;
}


.filter_pop_up_box {
    display: flex;
    width: 100%;
    padding: 0 3%;
 gap: 4%;
    align-items: center;
}

.filter_pop_up_box input {
    cursor: pointer;
    height: 2.6vh;
    width: 2vw;
}

.filter_pop_up_box label {
    color: var(--primaryTextColor);
    font-size: 2vh;
    font-weight: 600;
}

@media screen and (max-width: 768px) {
    .filter_pop_up {
        width: 93vw;
        left: -79%;
        height: 65vh;
        padding: 15% 3% 0;
        border: 2px solid var(--primaryTextColor);
    }

    .filter_pop_up {
        padding: 2% 3% 0 11%;
        gap: 0;
        left: -43%;
    }


    .filter_pop_up_box input {
        height: 2.5vh;
        width: 5vw;
    }

    .filter_pop_up_box label {
        font-size: 0.8rem;
        font-weight: 500;
    }

    .filter_pop_up span {
        right: 7%;
    }

    .filter_icon {
        width: 100%;
        position: relative;
        right: 0;
        cursor: pointer;
    }

    .filter_page_list {
        grid-template-columns: repeat(2, 1fr);
    }
}