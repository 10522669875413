
.about-founder {
  height: auto;
  display: flex;
  padding-left: 2%;
  padding-right: 0%;
  align-items: center;
  margin-top: -47px;
}
.about-founder-left {
  width: 65%;
}
.about-founder-left h1 {
  color: var(--primaryTextColor);
  font-size: 3.5vw !important;
}
.about-founder-left h3 {
  font-size: 1.5vw !important;
  font-weight: 500;
  margin: 2% 0;
}
.about-founder-left p {
  color: var(--secondaryTextColor);
  margin-bottom: 2%;
}
.about-founder-left button {
  font-size: 1.25rem;
  font-weight: 500;
  color: var(--primaryTextColor);
  width: 10rem;
  height: 3.75rem;
  border-radius: 3.125rem;
  border: 2px solid var(--primaryTextColor);
  background-color: transparent;
  cursor: pointer;
}
.about-founder-right {
  width: 35%;
}
.about-founder-right img {
  width: 100%;
}


/*..... media query ..... */
@media only screen and (max-width: 768px) {
  .about-founder {
    padding: 8% 4% 0;
    height: auto;
  }
  .about-founder-right {
    display: none;
  }

  .about-founder-left h1 {
    font-size: 5vw !important;
  }
  .about-founder-left h3 {
    font-size: 3.5vw !important;
  }
  .about-founder-left p {
    font-size: 0.625rem;
    padding: 0rem 1rem;
  }
  .about-founder-left {
    width: 100%;
    padding: 9% 0% 9% 0;
  }
  .about-founder-left button {
    font-size: 0.625rem;
    font-weight: 500;
    color: var(--primaryTextColor);
    width: 5.111rem;
    height: 1.758rem;
    border: 1px solid var(--primaryTextColor);
  }
}
