.medical_bottom_container {
  width: 100%;
  padding: 3rem 3rem;
  height: 100%;
}

.medical_bottom_content {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.medical_content_left {
  width: 50%;
}

.medical_content_left h3 {
  font-size: 1.5rem;
  font-weight: 600;
  color: var(--primaryTextColor);
}

.medical_content_left h2 {
  font-size: 3rem;
  font-weight: 700;
  color: var(--primaryTextColor);
}

.medical_content_left h2 span {
  color: var(--secondaryTextColor);
}
/*----- Mobile respnsive ----- */
@media only screen and (max-width: 768px) {
  .medical_bottom_container {
    padding: 1rem 1rem;
  }
  .medical_bottom_content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    gap: 10px;
  }
  .medical_content_left {
    width: 100%;
  }
  .medical_content_left h3 {
    text-align: center;
    font-size: 0.625rem;
  }
  .medical_content_left h2 {
    text-align: center;
    font-size: 1.6rem;
  }
  .medical_content_right {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .medical_content_right img {
    width: 40%;
  }
}
