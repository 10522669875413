.privacyHero-main {
  height: 85vh;
  width: 100%;
  background: rgba(165, 220, 251, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
}
.privacy-content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 5%;
  padding: 3%;
}

.hero-img {
  width: 40%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero-img .tcimg {
  height: 80%;
  object-fit: cover;
}
.hero-img .ppimg {
  width: 95%;
  height: 70%;
  object-fit: cover;
}
.hero-content {
  width: 43%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.p-subcointener {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.hero-content h1 {
  color: var(--secondaryTextColor);
}
.hero-content span {
  color: var(--primaryTextColor);
}
.hero-content p {
  color: var(--secondaryTextColor);
  font-size: 0.9375rem;
  font-weight: 400;
  margin-top: 1rem;
  line-height: normal;
}

/* T&C media Query */
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (max-width: 768px) {
  .privacyHero-main {
    height: auto;
    padding: 9% 0;
    /* margin-top: 1.43rem; */
  }
  .privacy-content {
    flex-direction: column-reverse;
    column-gap: 5%;
    padding: unset;
  }
  .hero-content {
    width: 90%;
    justify-content: center;
    align-items: center;
    margin-top: 0px;
  }
  .hero-content h1 {
    text-align: center;
    font-size: 1.25rem;
    font: bold;
  }
  .hero-content p {
    text-align: center;
    font-size: 0.625rem;
  }

  .hero-img {
    width: 90%;
    /* margin-top: 3rem; */
  }
  .hero-img img {
    width: 100%;
    /* height: 10rem; */
    object-fit: cover;
  }
  .hero-img .tcimg {
    width: 100%;
    height: unset;
    object-fit: cover;
  }
}

.button-p {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* P&P Media query */
@media only screen and (max-width: 768px) {
  .p-cointener {
    flex-direction: row-reverse;
    width: 95%;
    /* background-color: rgb(245, 187, 187); */
  }
  .p-subcointener {
    /* width: 28.125rem;
    height: 18.125rem; */
    /* background-color: rgb(200, 200, 244); */
  }
  .hero-text {
    width: 20rem;
    height: auto;
    /* background-color: red; */
    position: absolute;
    top: 0;
  }
  .p-subcointener .first-p {
    margin-top: 0;
  }
  .p-subcointener .buttonpp {
    /* position: absolute; */
    bottom: 3.75rem;
    right: -9.813rem;
    width: 50%;
    display: none;
  }
  .hero-img .ppimg {
    /* width: 9rem;
    height: 9rem;
    margin-top: -1.25rem; */
    height: 100%;
    width: 100%;
  }
}


