.home-categories {
  background: url("../../Assets/Images/Frame\ 3.png");
  background-size: cover;
  width: 100%;
  height: fit-content;
  padding: 2% 4%;
}

.home-page-heading {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 2rem;
}

.home-page-heading h1 {
  /* display: block; */
  padding-left: 25vw;
  text-align: center;
  color: var(--primaryTextColor);
}

.home-categories-content {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.home-categories-left {
  width: 40%;
  align-self: end;
}

.home-categories-left img {
  width: 100%;
}

.home-categories-right {
  width: 60%;
  position: relative;
  bottom: 4%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.home-categories-right-content {
  width: 20%;
  height: 5rem;
  margin: 8% 5%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
}

.home-categories-right-content img {
  height: 100%;
  width: 100%;
  padding: 1rem;
  object-fit: contain;
  cursor: pointer;
  display: flex;
  margin: auto;
}

.home-categories-right-content h3,
.home-categories-right-content p {
  color: var(--secondaryTextColor);
  text-align: center;
}

.home-categories-right-content h3 {
  margin: 0.8rem 0;
  font-size: 1.3vw;
}

.vertical-slider-buttons {
  height: 56vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 3rem;
}

.vertical-slider-buttons button {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) inset;
  padding: 22.5px 25px;
  border-radius: 50%;
  border: none;
  background: linear-gradient(
    180deg,
    var(--primaryColor) 0%,
    var(--primary2ndColor) 100%
  );
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .home-categories {
    background: none;
    padding: 0;
    height: auto;
  }
  .home-categories-content {
    flex-direction: column;
    height: auto;
  }
 
  .home-categories-left {
    background: url("../../Assets/Images/home/bg.png");
    background-size: contain;
    background-position: center;
  }
  .home-categories-right {
    width: 100%;
    justify-content: space-evenly;
  }
  .home-categories-left img {
    width: 88%;
    margin: 0 1.22rem 1rem;
  }
  .vertical-slider-buttons {
    flex-direction: row;
    gap: 1rem;
    margin-top: 2rem;
    height: auto;
    justify-content: center;
  }
  .vertical-slider-buttons img {
    transform: rotate(-90deg);
  }

  .home-page-heading h1 {
    /* display: block; */
    padding-left:0;
    text-align: center;
    color: var(--primaryTextColor);
  }
  .vertical-slider-buttons button {
    padding: 14.5px 17px;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .home-page-heading h1 {
    font-size: 7vw;
  }
  .home-categories-right-content {
    margin: 2% 5% 6% 2%;
  }
  .home-categories-right-content h3 {
    font-size: 2.8vw;
    margin: 0 0 0.8rem 0;
  }
  .home-categories-right-content img {
    height: 4.438rem;
    object-fit: contain;
  }
  .home-categories-left {
    width: 100%;
    margin: 2rem 0 0 0;
  }
}


@media only screen and (min-width:1920px){
  .home-page-heading h1{
    font-size: 3.5vw;
  }
  .home-categories-right-content h3{
    font-size: 1.5vw;
  }
  .home-categories-right-content img{
    height: 10vh;
    width: 15vw;
  }
}