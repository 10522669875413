.Hero_Container {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0%;
  background-color: var(--thirdBackground);
}
/* ,,,, */
.Hero_Content {
  display: flex;
  gap: 2rem;
  height: 100%;
  width: 100%;
}

.Hero_Left_Div {
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 2rem;
  padding: 3%;
}

.Hero_Left_Div h1 {
  font-family: Montserrat;
  font-size: 6vh;
  font-weight: 700;
  letter-spacing: 1px;
  color: var(--primaryColor);
}

.Hero_Left_Div h1 span {
  color: var(--secondaryTextColor);
}

.Hero_Left_Div p {
  font-size: 2vh;
  color: var(--secondaryTextColor);
}

.Hero_Left_Div button {
  padding: 1rem 2.5rem;
  font-size: 1.3rem;
  font-weight: 500;
  color: var(--primaryTextColor);
  font-family: Montserrat;
  border: 2px solid var(--primaryTextColor);
  border-radius: 50px;
  align-self: self-start;
  transition: 0.5s;
}

.Hero_Left_Div button:hover {
  background: linear-gradient(180deg, #4093c5 0%, #a5dcfb 100%);
  color: var(--baseColor);
  box-shadow: 2px 4px 6px 1px var(--secondaryColor);
  border: none;
}
.hero_right_div_main_section {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-image: url("../../Assets/Images/blogs/Group\ 1891.png");
  background-repeat: no-repeat;
}
.Hero_Right_Div {
  width: 40%;
  height: 100%;
}
.image_container {
  width: 401px;
  height: 351px;
  background-image: url("../../Assets/Images/blogs/Rectangle\ 725.png");
  background-repeat: no-repeat;
  background-size: 401px 351px;
  border-radius: 20px;
}
.image_main_container {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}
.first_section_image {
  width: 10%;
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  position: relative;
}
.card-box-1 {
  width: 13.4375rem;
  height: 5.3125rem;
  background-color: white;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  gap: 0.9375rem;
  align-items: center;
  box-shadow: 0px 4px 4px 0px #00000040;
}
.downloade_icon_container {
  width: 41px;
  height: 38px;
  border-radius: 0.3125rem;
  background-color: #a5dcfb;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon_downloade {
  font-size: 20px;
  color: #196ea4;
}
.p_number {
  font-size: 0.75rem;
  color: #196ea4;
  font: bold;
  font-weight: 400;
}
.p_text {
  font-size: 0.75rem;
  color: #727171;
}
.card-box-3 {
  width: 9.5625rem;
  height: 5.3125rem;
  background-color: white;
  position: absolute;
  z-index: 1;
  left: 0;
  bottom: 0;
  border-radius: 5px;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.9375rem;
}
.card-box-2 {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  background-color: #196ea4;
  position: absolute;
  box-shadow: 0px 4px 4px 0px #00000040;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .Hero_Img {
    width: 98% !important;
    height: 78vh;
    object-fit: cover;
}

.Hero_Img img {
    height: 100% !important;
    width: 100% !important;
    cursor: pointer;
}

.Hero_Content .slick-next{
    display: none !important;
} */
/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .Hero_Container {
    flex-direction: column;
    height: 100%;
    padding: 5% 0;
  }

  .Hero_Content {
    flex-direction: column;
    gap: 0;
  }

  .Hero_Left_Div h1 {
    text-align: center;
    font-size: 1.25rem;
    font-weight: 700;
    line-height: 24.38px;
  }

  .Hero_Left_Div p {
    text-align: center;
    font-size: 0.938rem;
  }

  .Hero_Left_Div {
    width: 100%;
    gap: 1rem;
    justify-content: flex-start;
  }

  .Hero_Left_Div button {
    display: none;
    margin: auto;
  }

  /* .Hero_Right_Div {
        width: 100%;
        margin-top: 2rem;
    } */
  .Hero_Img {
    height: 17.188rem !important;
    object-fit: cover !important;
  }
  .Hero_Right_Div {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: end;
  }
  .hero_right_div_main_section {
    width: 90%;
    height: 100%;
    padding: 3%;
  }
  .image_container {
    width: 14.3125rem;
    height: 12.5rem;
    background-size: 14.3125rem 12.5rem;
  }
  .first_section_image {
    width: 20%;
    height: 12.5rem;
   
  }
  .card-box-1 {
    width: 131px;
    height: 65px;
    top: 10px;
  }
  .card-box-3 {
    width: 132px;
    height: 65px;
    background-color: white;
    left: 110px;
    top: 150px;
  }
  .image_main_container {
    position: relative;
  }
  .card-box-2 {
    bottom: 18px;
}
.downloade_icon_container {
    width: 21px;
    height: 19px;
  }
  .icon_downloade {
    font-size: 10px;
    color: #196ea4;
  }
  .p_text {
    font-size: 0.45rem;
  }

}
