.commonPupUp_heading_div {
    width: 100%;
    height: 6vh;
    background-color: var(--primaryTextColor);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;
}

.commonPupUp_heading_div h1 {
    font-size: 1.3vw;
    color: var(--baseColor);
}

.commonPupUp_heading_div h5 {
    font-size: 4vh;
    color: var(--baseColor);
    cursor: pointer;
    font-weight: 800;
}

.commonPopUp_content_div {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    gap: .625rem;
    /* background-color: rgb(205, 240, 205); */
    padding: 3%;
}
.commonPopUp_content_div p{
    color: var( --primaryTextColor);
    font-size: 3vh;
    font-weight: 600;
    /* border: 1px solid var(--secondaryTextColor); */
    border-radius: 4px;
}

.commonPopUp_content_div span{
    color: var( --secondaryTextColor);
    font-size: 2.7vh;
    font-weight: 500;
}


.commonPupUp_heading_data_div {
    display: flex;
    flex-direction: column;
    background-color: var(--baseColor);
    height: 65vh;
    width: 45vw;
}

@media only screen and (max-width: 768px){
    .commonPupUp_heading_data_div{
        width: 96vw;
        height: fit-content;
    }
    .commonPupUp_heading_div h1{
        font-size: 1.7vh;
    }
}