.allContainer {
    height: 60vh;
    width: 100%;
    position: relative;
   
}

.allProductContent {
    background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
    height: 50vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.productHeading {
    display: flex;
    justify-content: center;
    align-items: center;
}

.productHeading h1 {
    color: var(--baseColor);
    font-family: Montserrat;
    font-size: 3rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: uppercase;
    cursor: pointer;
}

.productSearchBox {
    width: 40%;
    margin: 2rem auto;
    border-radius: 3rem;
    position: relative;
}

.productSearchBox>input {
    width: 100%;
    padding: 1.2rem 3rem;
    outline: none;
    border: none;
    font-size: 1rem;
    border-radius: 3rem;
    background: var(--thirdBackground);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}


.productSearchIcon {
    position: absolute;
    top: 0.3rem;
    right: 0.8rem;
    font-size: 3rem;
    color: var(--primaryColor);
}

.allHeroLeftImg {
    position: absolute;
    left: 3%;
    bottom: 0;
}

.allHeroRightImg {
    position: absolute;
    right: 3%;
    bottom: 0;
}

/* Media Queries for responsiveness */

@media screen and (max-width: 768px) {
    .allContainer {
        height: 43vh;
        /* overflow: hidden; */
    }

    .allProductContent {
        height: 38vh;
    }

    .productSearchBox {
        width: 50%;
        /* Adjust width for smaller screens */
    }

    .productSearchBox>input {
        padding: 0.9rem 0.7rem;
        /* Adjust padding for smaller screens */
        font-size: 0.8rem;
        /* Adjust font size for smaller screens */
    }

    .productSearchBox>input::placeholder {
        font-size: 0.6rem;
    }

    .productSearchIcon {
        font-size: 2rem;
        /* Adjust icon size for smaller screens */
    }

    .productHeading h1 {
        font-size: 1.5rem;
    }

    .allHeroRightImg img {
        height: 6.25rem;
        width: 8rem;
    }

    .allHeroLeftImg img {
        height: 6.25rem;
        width: 8rem;
    }
}

/* @media screen and (max-width: 400px){
    .productSearchBox {
        width: 81%;
    }
    .allProductContent {
        height: 35vh;
    }
    .allContainer{
        height: 38vh;
    }
} */

@media screen and (max-width: 415px) {
    .allContainer {
        height: 40vh;
        /* Adjusted height for smaller screens */
    }

    .allProductContent {
        height: 35vh;
        /* Adjusted height for smaller screens */
    }

    .productSearchBox {
        width: 90%;
        /* padding: 2% 4%; */
    }

    .productSearchBox>input {
        /* padding: 1rem 2rem;  */
        font-size: 0.9rem;
    }

    .productSearchBox>input::placeholder {
        font-size: 0.6rem;
    }

    .productSearchIcon {
        font-size: 2.1rem;
        top: 9%;
        right: 3%;
        /* Adjusted icon size for smaller screens */
    }

    .productHeading h1 {
        font-size: 1.8rem;
        /* Adjusted heading font size for smaller screens */
    }

    .allHeroRightImg img,
    .allHeroLeftImg img {
        height: 5.25rem;
        /* Adjusted image height for smaller screens */
        width: auto;
        /* Adjusted image width for smaller screens */
    }
}