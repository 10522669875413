.productHeromain {
  width: 100%;
  background: var(--thirdBackground);
  display: flex;
  justify-content: center;
  align-items: center;
  height: fit-content;
  text-align: start;
  margin: 0 0 1rem 0;
  padding: 1% 2%;
}

.productherocontent {
  width: 100%;
  display: flex;
  margin: 0 auto;
  gap: 2rem;
}


.productheroimg {
  width: 50%;
  height: 83vh;
  position: relative;
}

.productheroimg .selected-image {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.productherotext {
  width: 50%;
}

.productherotext h1 {
  color: var(--primaryTextColor);
  font-family: Montserrat;
  font-size: 3rem;
  font-weight: 500;
  padding: 0 0 0.5rem 0;
}

.productherotext p {
  color: var(--secondaryTextColor);
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-weight: 400;
  /* margin-bottom: 8px; */
  padding: 0.5rem 0 0.5rem 0;
}

.price-info {
  display: flex;
  align-items: center;
  column-gap: 1rem;
}

.price-info h2 {
  color: var(--primaryTextColor);
}

.discount {
  background: linear-gradient(180deg, #4093c5 0%, #a5dcfb 100%);
  border-radius: 10px;
  padding: 0.3rem;
  color: var(--baseColor);
}

.potency-section .itemmore {
  display: flex;
  align-items: center;
  justify-content: flex-start;

}

.potency-btn {
  width: calc(33.33% - 10px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.potency-btn,
.show-more-btn {
  width: 100px;
  height: 45px;
  line-height: 1rem;
  text-align: center;
  background-color: transparent;
  color: var(--primaryTextColor);
  border: 1px solid rgba(25, 110, 164, 0.5);
  cursor: pointer;
  margin: 5px;
}

.potency-btn p {
  color: var(--primaryTextColor);
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-weight: 400;
  margin-bottom: 0;
  padding: 0.4rem 0 0.1rem 0;
  line-height: 10px;
  text-align: center;
}

.potency-btn p:nth-child(2) {
  font-weight: 700;
  font-size: 1rem;
  padding: 0.2rem 0.2rem 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.show-more-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 700;
  font-size: 1rem;
  padding: 0 0.2rem;
}

.offers-section {
  margin-top: 1rem;
}
.offers-section div{
  display: flex;
  gap: 0.2rem;
  align-items: center;
}
.offers-section div span{
  color: var(--primaryTextColor);
}


/* .offers-section1 {
  margin-top: 1rem;
} */
.offers-section1 div{
  display: flex;
  gap: 0.2rem;
  align-items: center;
}
.offers-section1 div span{
  color: var(--primaryTextColor);
}


.action-buttons {
  display: flex;
  gap: 4px;
  margin-top: 20px;
}

.action-btn {
  flex: 1;
  background-color: transparent;
  color: var(--primaryTextColor);
  border: 3px solid var(--primaryTextColor);
  cursor: pointer;
  border-radius: 50px;
}

.delivery-icon {
  color: var(--primaryTextColor);
  font-size: 1.5rem;
  padding: 0 0.3rem 0 0;
}

.offer-info {
  display: flex;
  align-items: center;
}

.buy-btn {
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 1.2rem 1.3rem;
  transition: 0.5s;
}

.buy-btn:hover {
  background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
  color: var(--baseColor);
  box-shadow: 2px 4px 6px 1px var(--secondaryColor);
  border: none;
}

/* Media Queries for responsiveness */
@media screen and (max-width: 948px) {
  .productHeromain {
    padding: 1rem 0;
  }
  .productheroimg {
    width: 100%;
    height: 40vh;
  }

  .product-info {
    margin: 1rem 0;
  }
  .potency-btn{
    margin: 0;
  }
  .price-info h2 {
    font-size: 0.938rem;
  }
}


@media screen and (max-width: 768px) {
  .potency-btn {
    width: calc(50% - 10px);
  }

  .productherocontent {
    flex-direction: column;
    gap: 0;
  }

  .productherotext {
    width: 100%;
    max-width: 100%;
    padding: 0 3%;
  }

  .productherotext h1 {
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
  }

  .productherotext p {
    font-size: 0.938rem;
    line-height: 16px;
  }

  .ratings {
    display: flex;
    gap: 0.8rem;
    align-items: center;
  }

  .itemmore {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    grid-gap: 10px;
  }

  .potency-btn,
  .show-more-btn {
    width: 108px;
    height: 58px;
  }

  .buy-btn{
    padding: 0.5rem 0.1rem;
    font-size: 14px;
    font-weight: 600;
  }
}

@media screen and (max-width: 465px) {
  .potency-btn p:nth-child(2) {
    padding: 0 0.4rem 0 0;
  }
  .pText{
    font-size: 0.6rem !important;
    left: 3%;
  }
 
}