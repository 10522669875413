.AllBlog_Container{
    width: 100%;
    padding:5% 3%;
}
.AllBlog_Content{
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.AllBlog_Head{
    align-self: center;
}
.AllBlog_Head h2{
    font-family: Montserrat;
    font-size: 3rem;
    font-weight: 500;
    letter-spacing: 1px;
    color: var(--primaryColor);
}
.AllBLog_Middle{
    width: 100%; 
    display: grid;
    column-gap: 3rem;
    row-gap: 3rem;
    grid-template-columns: repeat(auto-fill, minmax(26.688rem, 1fr));
}

.AllBlog_Card{
    width: 100%;
    height: 34rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var( --baseColor);
    transition: 0.3s;
    cursor: pointer;
}

.AllBlog_Card:hover{
    background-color: #fff;
    box-shadow: 0 0 4px #5e5d5d;
}
.AllBlog_Card img{
    height: 18.75rem;
    width: 100%;
    object-fit: cover;
}
.AllBLog_Text{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  padding: 0 1%;
  overflow: hidden;
}

.AllBLog_Text h4{
    font-size: 1.25rem;
    font-weight: 500;
    font-family: Montserrat;
    text-align: center;
    color: var(--primaryTextColor);
}

.AllBLog_Text p{
    font-size: 0.938rem;
    font-weight: 400;
    padding: 0 0.7rem;
    font-family: Montserrat;
    text-align: center;
    color: var(--secondaryTextColor);
}

.AllBLog_Text button{
    font-size: 0.938rem;
    font-weight: 500;
    font-family: Montserrat;
    color: var(--primaryTextColor);
    border-radius: 50px;
    padding: 0.8rem 1.2rem;
    border: 2px solid var(--primaryTextColor);
    cursor: pointer;
}
.AllBLog_Text button:hover{
    background: linear-gradient(180deg, #4093C5 0%, #A5DCFB 100%);
    color: var(--baseColor);
    box-shadow: 2px 4px 6px 1px var(--secondaryColor);
    border: none;
}

@media screen and (max-width: 768px){
    .AllBlog_Content{
        gap: 1rem;
    }
    .AllBLog_Middle {
        grid-template-columns: repeat(auto-fill, minmax(19.688rem, 1fr));
    }
    .AllBlog_Card{
        width: 100%;
        height: 30.5rem;
    }
    .AllBlog_Head h2{
        font-size: x-large;
    }

    .AllBlog_Card img {
        height: 13.75rem;
    }
    .AllBLog_Text button{
        padding: 0.5rem 0.8rem;
    }

    .AllBLog_Text h4 {
        font-size: 2.5vh;
    }
    .AllBLog_Text p {
        font-size: 1.8vh;
        font-weight: 500;
    }
}