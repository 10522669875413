.gallery-hero {
  overflow: hidden;
  /* height: 100vh; */
}


.hero_gallery_content{
  display: flex;
  /* align-items: center; */
  justify-content: center;
  position: relative;
  width: 100%;
  height: 100vh;
}

.hero_gallery_items {
  text-align: left;
  width: 50%;
  padding: 0 0 0 2%;
  align-self: center;
}

.hero_gallery_items h1 {
  color: var(--primaryTextColor) !important;
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.hero_gallery_items-para{
  color: var(--secondaryTextColor);
  font-family: Montserrat;
  word-spacing: 0.1rem;
  margin-top: 2vh;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}


.gallery_hero_img {
  width: 50%;
}
.gallery_hero_img img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@media  screen and (max-width: 768px) {
  .gallery-hero{
    height: 100%;
    padding: 3% 0;
  }
.hero_gallery_content{
  height: 100%;
  flex-direction: column;
}
.hero_gallery_items h1 {
  font-size: 1.25rem;
}
.hero_gallery_items p{
  font-size: 0.6rem;
}
.hero_gallery_items {
  width: 100%;
  text-align: center;
  padding: 0 2%;
}
.gallery_hero_img {
  width: 100%;
}
}

@media only screen and (min-width:1600px){
  .hero_gallery_items-para {
    color: var(--secondaryTextColor);
    font-family: Montserrat;
    font-size: 1.1vw !important;
    word-spacing: 0.1rem;
    margin-top: 2vh;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}