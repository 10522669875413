.section5main {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin:1rem 0 1rem 0;
  padding:0 3rem;
}

.section5content {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.section5text {
  text-align: center;
  margin-bottom: 1rem;
}
.section5text span {
  color: var(--primaryTextColor);
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 5rem; /* 166.667% */
  letter-spacing: 0.0125rem;
}
.section5text h1 {
  color: var(--secondaryTextColor);
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: 5rem;
  letter-spacing: 0.0125rem;
}

.section5item {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.accordionmain {
  width: 100%;
}
.accordion-title {
  cursor: pointer;
  padding: 1rem;
  border: 1px solid var(--secondaryTextColor);
  background-color: var(--secondaryTextColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 5px;
}
.icon-container {
  margin-left: auto;
}

.accordion-title.active {
  background-color: #196ea4;
  color: var(--baseColor);
  border: none;
}
.accordion-title h3 {
  margin-right: auto;
  color: var(--baseColor);

  font-family: Poppins;
  font-size: 2rem;
  font-style: normal;
  font-weight: 600;
  line-height: 3.25rem;
  letter-spacing: 0.0125rem;
}

.accordion-content {
  display: none;
  padding: 10px;
  border: none;
  background: var(--baseColor);
  width: 100%;
  border-radius: 5px;
}
.accordion-content p {
  color: var(--secondaryTextColor);

  font-family: Poppins;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 300;
  line-height: 1.875rem;
  letter-spacing: 0.0125rem;
}

.accordion-title.active + .accordion-content {
  display: block;
}

@media screen and (max-width: 765px){
  .section5main {
    padding: 0 2rem;
  }
  .section5text h1 {
    font-size: 1.5rem;
  }
  .section5text span{
    font-size: 1.5rem;
  }
  .accordion-title {
    padding: 0 0.5rem;
  }
  .accordion-title h3{
    font-size: 0.93rem;
  }
  .accordion-content p {
    font-size: 0.65rem;
  }
}
@media screen and (max-width: 465px){
  .accordion-content p {
    line-height: 10px;
  }
  .section5text h1 {
    line-height: 10px;
  }
  .section5main{
    padding: 0 1rem;
  }
}