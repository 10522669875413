/* .floatingWhatsappIcon {
  position: fixed;
  width: 60px;
  height: 60px;
  top: 50%;
  right: 40px;
  background-color: #25d366;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 30px;
  box-shadow: 2px 2px 3px #999;
  z-index: 100;
  cursor: pointer;
  transition: background-color 0.5s;
} */

.whatsapp_floating-button {
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 1000;
}

.whatsapp_floating-button button {
  background-color: #25d366;
  color: white;
  border: none;
  padding: 15px 20px;
  border-radius: 50px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  font-size: 14px;
}

.whatsapp_floating-button button:hover {
  background-color: #128c7e;
}

@media screen and (max-width: 768px) {
  .whatsapp_floating-button {
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .whatsapp_floating-button button {
    padding: 8px;
    font-size: 14px;
  }
}
