.medicines-by-disease {
  display: flex;
  flex-direction: column;
  padding: 0 4%;
}

.medicines-by-disease h1 {
  text-align: center;
  margin-bottom: 4%;
}

.medicines-by-disease-content {
  display: flex;
  max-width: 100%;
  flex-direction: row;
  height: 73vh;
  position: relative;
}

.medicines-by-disease-content-left {
  width: 50%;
  position: relative;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0.25, 0.25);
}

.medicines-by-disease-content-left img {
  width: 100% !important;
  padding: 2.75rem !important;
  height: 70vh !important;
  object-fit: cover !important;
}
.slick-dots{
  position: absolute !important;
  bottom: 0  !important;
  left: 50%  !important;
  transform: translate(-50%, -50%)  !important;
  display: flex !important;
  width: auto !important;
}
.slick-dots li.slick-active button:before {
  background-color: var(--primaryTextColor) !important;
  width: 18px !important;
  height: 18px !important;
  margin-top: -1.5px !important;
  margin-left: 5px !important;
  color: var(--primaryTextColor) !important;
  border-radius: 50% !important;
}
.slick-dots li button:before{
  color: var(--secondaryColor) !important;
}
.slick-dots li button {
  width: 15px !important;
  height: 15px!important;
  margin: 0 6px !important;
  background-color: var(--secondaryColor) !important;
  color: var(--secondaryColor) !important;
  border-radius: 50% !important;
  cursor: pointer !important;
}

.medicines-by-disease-content-right {
  width: 50%;
  overflow-y: scroll;
  padding-left: 3%;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.medicines-by-disease-content-right::-webkit-scrollbar {
  width: 18px;
  box-shadow: 0px 4px 4px 0px rgba(16, 16, 16, 0.25) inset;
  border-radius: 50px;
}

.medicines-by-disease-content-right::-webkit-scrollbar-thumb {
  background-color: var(--secondaryColor);
  border-radius: 50px;
}

.medicines-by-disease-content-right::-webkit-scrollbar-track {
  border-radius: 10px;
}

.view-medicines {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 5%;
}

.view-medicines div {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.view-medicines img {
  object-fit: contain;
  cursor: pointer;
  height: 4.188rem;
}

.medicines-by-disease-content-right h3 {
  color: var(--primaryTextColor);
  width: 100%;
  font-size: 3vh;
  text-align: center;
}

.dot-navigation {
  position: absolute;
  bottom: 8px;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
}

.dot-navigation span {
  width: 15px;
  height: 15px;
  margin: 0 6px;
  background-color: var(--secondaryColor);
  border-radius: 50%;
  cursor: pointer;
}

.dot-navigation span.active {
  background-color: var(--primaryTextColor);
  width: 18px;
  height: 18px;
  margin-top: -1.5px;
}


/* Responsive styles */
@media only screen and (max-width: 768px) {
  .medicines-by-disease-content {
    flex-direction: column-reverse;
    width: 100%;
    height: fit-content;
    border: 1px solid var(--secondaryColor);
  }

  .view-medicines img {
    height: 2.75rem;
  }

  .medicines-by-disease-content-left {
    width: 100%;
    position: relative;
    padding: .2rem .2rem 1.7rem;
  }

  .medicines-by-disease-content-right {
    width: auto;
    padding: 0;
    margin: 0.8rem;
    padding: 2rem 0;
    height: 50vh;
  }

  .medicines-by-disease h1 {
    font-size: 1.25rem;
    padding: 1rem 0;
  }

  .view-medicines h3 {
    font-size: 4vw;
    text-align: center;
  }

  .dot-navigation {
   bottom: 0;
   left: 50%;
   transform: translate(-50%, -50%);
  }

  .medicines-by-disease-content-left img {
    height: 17.5rem !important;
    box-shadow: none !important;
    border-bottom: none !important;
    padding: 0 !important;
  }
  .slick-dots {
    bottom: -12% !important;
  }
  .medicines-by-disease-content-right::-webkit-scrollbar {
    width: 10px;
  }

  .slick-dots li button  {
    height: 11px !important;
    width: 11px !important;
  }

  .slick-dots li.slick-active button:before{
    width: 15px !important;
    height: 15px !important;
  }
}

@media only screen and (min-width:1920px){
  .medicineBy-HeadingOfSlider{
    font-size: 3vh !important;
  }
  .medicines-by-disease-content-left img {
    width: 100% !important;
    padding: 2.75rem !important;
    margin: 0.6rem;
    height: 70vh !important;
    object-fit: cover !important;
  }
  .view-medicines h3{
    font-size: 3vw;
    text-align: center;
  }
  .medicines-by-disease-content-right h3 {
    font-size: 2.5vh;
    text-align: center;
  }
}
