.sidebar_medicine {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

.sidebar_medicine h4 {
    color: var(--primaryTextColor);
    text-decoration: underline;
}

.sidebar_medicine_text {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
}

.sidebar_medicine_text div {
    width: 100%;
    display: flex;
    gap: 2%;
}

.sidebar_medicine_text div input {
    height: 2.5vh;
    width: 2vw;
}

.sidebar_medicine_text p {
    color: var(--baseColor);
    cursor: pointer;
}

.sidebar_medicine button {
    cursor: pointer;
    color: var(--primaryTextColor);
    font-size: 2vh;
    padding: 0.2vh 1.8vh;
    font-weight: 600;
}

.lablefilter {
    font-size: 0.9rem;
    font-weight: 600;
    color: var(--baseColor);
}
.sidebar_medicine_text div label{
    font-size: 2vh;
}

@media only screen and (max-width: 768px) {
    .sidebar_medicine_text div input {
        height: 3vh;
        width: 4vw;
    }
    .sidebar_medicine{
        gap: 0.5rem;
    }
    .sidebar_medicine_text{
        gap: 1%;
    }
}