.herocontact-main {
  display: flex;
  width: 100%;
  background: rgba(165, 220, 251, 0.2);
  align-items: center;
  justify-content: center;
}

.herocontact-content {
  display: flex;
  width: 90%;
  justify-content: space-between;
  align-items: center;
}

.herocontact-content h1 {
  color: #196ea4;
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  line-height: 5rem;
  margin-bottom: 1rem;
}

.herocontact-content p {
  color: #727171;
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;
}

.herocontact-text {
  width: 45%;
}

.herocontact-lottifile {
  width: 43%;
}
.halfitem {
  display: grid;
  margin-top: 0.5rem;
}
.halfitem span {
  color: #196ea4;
  margin-right: 1rem;
}
.halfitem p {
  margin-left: 2rem;
}
.halfitem div {
  margin-top: 1rem;
  line-height: 3rem;
}
.responsive-view {
  display: none;
}
/*------- media query -------  */
@media only screen and (max-width: 768px) {
  .herocontact-content {
    width: 95%;
    /* background-color: rgb(219, 152, 152); */
    flex-direction: column;
  }
  .herocontact-text {
    width: 100%;
  }
  .herocontact-text h1 {
    text-align: center;
    font-size: 1.25rem;
  }
  .herocontact-lottifile {
    display: none;
  }
  .herocontact-text .p {
    text-align: start;
    font-size: 0.625rem;
    text-align: center;
  }
  .heading-text {
    text-align: center;
  }
  .responsive-view {
    display: block;
  }
  .responsive-view .player-responsive-image {
    width: 15.5rem;
    height: 11.188rem;
  }
  .halfitem {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    /* grid-template-rows: repeat(auto-fit, minmax(200px, 1fr)); */
    column-gap: 15px;
    padding: 12px 2%;
    margin-top: -10%;
  }

  .halfitem div b {
    font-size: 0.625rem;
  }
  .herocontact-content .heading-text {
    color: #727171;
    font-family: Montserrat;
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 0;
    margin-top: -30px;
    text-align: center;
  }

  .halfitem p {
    line-height: 1rem;
    font-size: 0.625rem;
  }
  .halfitem div {
    margin-top: 1rem;
    line-height: 2rem;
  }
  
  
  .lf-player-container{
    width: 100%;
  }
 
}
