.address-form{
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: .4rem;
    margin: .3rem 0;
    
}
.address-form>small{
    color: red;
    margin: .3rem 0;

}

.form-group{
    width: 100%;
}
.form-group>input{
    width: 100%;
    padding: .5rem;
    outline: none;
    /* border: 1px solid var(--secondaryColor); */
}