.profile_order_container {
  width: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
}

.order_header {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.order_header h2 {
  font-size: 1.25rem;
  font-weight: 700;
  color: var(--primaryTextColor);
}

.order_btn {
  width:65%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 1.25rem;
  background-color: var(--baseColor);
  box-shadow: 2px 3px 4px var(--boxColor);
}

.activate {
  background-color: var(--primaryTextColor);
  padding: 1.5rem;
  border-radius: 1.25rem;
  border: none;
  outline: none;
  color: var(--baseColor);
  font-size: 1.25rem;
  font-weight: 500;
  cursor: pointer;
  box-shadow: 2px 3px 4px var(--boxColor);
}

.nonActivate {
  color: var(--primaryTextColor);
  background-color: transparent;
  padding: 1.5rem;
  border: none;
  outline: none;
  font-size: 1.25rem;
  font-weight: 500;
  cursor: pointer;
}

.orderSearchBox {
  width: 27%;
  position: relative;
}

.orderSearchBox > input {
  width: 100%;
  padding: 1.2rem 3rem;
  outline: none;
  border: none;
  font-size: 1rem;
  border-radius: 3rem;
  background: transparent;
  border: 1px solid var(--secondaryTextColor);
}

.orderSearchIcon {
  position: absolute;
  top: 0.42rem;
  right: 0.8rem;
  font-size: 2.7rem;
  color: var(--secondaryTextColor);
}

.address_details {
  display: flex;
  width: 100%;
}

.address_container {
  display: grid;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  grid-template-columns: repeat(4, minmax(0px, 1fr));
  gap: 0.9rem;
  padding: 1% 0;
}

.address_container1 {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2rem;
}

.address_item_fullName {
  font-weight: 700;
}

.address_item {
  position: relative;
  font-weight: 500;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  gap: 0.2rem;
  background-color: var(--thirdBackground);
  width: 100%;
  padding: 1rem 1rem;
  box-shadow: 0 6px 8px var(--secondaryTextColor);
  position: relative;
}

.address_dlt_icon{
  position: absolute;
  right: 2%;
  top: 6%;
  font-size: 3vh;
  color: var(--numColor);
  cursor: pointer;
}

.editButton {
  position: absolute;
  top: 1rem;
  right: 3rem;
  cursor: pointer;
}

.deleteIcon {
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.address_item_1 {
  display: flex;
  background-color: var(--thirdBackground);
  width: 100%;
  padding: 1rem 0.5rem;
  box-shadow: 0 6px 8px var(--secondaryTextColor);
}
.address_item_1 .address_item_1_div {
  width: 100%;
  display: flex;
  align-items: center;
}
.address_item_1 .address_item_1_div p {
  color: var(--primaryTextColor);
  font-weight: 600;
  width: 100%;
  display: flex;
    justify-content: flex-start;
}
.address_item_1 .address_item_1_div p:nth-child(1){
  width: 40%;
}

.address_item_1 .address_item_1_div p span {
  color: var(--secondaryTextColor);
}

.address_item_fullName {
  color: var(--secondaryTextColor);
}

.address_item p {
  color: var(--primaryTextColor);
  font-weight: 500;
  font-size: 2vh;
}

.user-address-font {
  font-weight: 700 !important;
}

.address_item p span {
  color: var(--secondaryTextColor);
  font-size: 2vh;
}

.order_history_view_more {
  text-decoration-thickness: 1px;
  color: var(--baseColor);
  cursor: pointer;
  font-size: 0.625rem;
  font-weight: 500;
}

.addressCityState {
  display: inline-block;
}

.btn-box {
  width: auto;
  height: auto;
  display: flex;
  justify-content: flex-end;
  gap: 0.3125rem;
  width: 15%;
}

.order_history_view_more {
  width: 80px;
  height: 2rem;
  background-color: var(--primaryTextColor);
  border-radius: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.wrappe_data_overlay_Sno{
  width: 8%;
  height: auto;
   /* background-color: rgb(247, 195, 195); */
}
.wrappe_data_overlay{
  width: 20%;
  height: auto;
  /* background-color: rgb(247, 195, 195); */
}
.wrappe_data_overlay_name{
  width: 25%;
  height: auto;
}
.address_item_1_div_p{
  width: 70% !important;
}
@media only screen and (max-width: 768px) {

  .wrappe_data_overlay{
    width: 100%;
  }
  
  .profile_order_container {
    width: 100%;
    padding: 3%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }

  .order_header {
    display: flex;
    flex-direction: column;
    /* Change to column layout for smaller screens */
    gap: 1rem;
    /* Reduce the gap between elements */
    padding-bottom: 20px;
  }

  .order_header h2 {
    font-size: 1rem;
    /* Reduce font size for smaller screens */
  }

  .address_item p {
    color: var(--secondaryTextColor);
    font-size: 1.5vh;
  }

  .address_item_1 p {
    color: var(--secondaryTextColor);
    font-size: 1.5vh;
  }

  .order_btn,
  .activate,
  .nonActivate,
  .orderSearchBox,
  .address_details,
  .address_container,
  .address_container1,
  .address_item {
    width: 100%;
  }
  .address_item_1_div_p{
    width: 100% !important;
  }
  .activate{
    font-size: 1.5vh;
    padding: 8%;
  }
  .nonActivate{
    font-size: 1.5vh;
    padding: 4%;
    font-weight: 700;
  }
  .address_item_1 .address_item_1_div p:nth-child(1){
    width: 100%;
  }
  .address_item_1 .address_item_1_div {
    flex-direction: column;
  }
  .btn-box {
    justify-content: flex-start;
    width: 100%;
  }
  .orderSearchBox {
    width: 100%;
  }

  .user-address-font {
    font-weight: 700 !important;
  }

  .orderSearchBox > input {
    width: 100%;
    padding: 1rem 2rem;
    /* Adjust padding */
    font-size: 0.8rem;
    /* Reduce font size for smaller screens */
  }

  .orderSearchIcon {
    font-size: 2rem;
    right: 0.5rem;
  }

  .address_item {
    flex-direction: column;
    align-items: flex-start;
  }

  .address_item_1 {
    flex-direction: column;
    align-items: flex-start;
  }
  .address_container {
    grid-template-columns: repeat(1, minmax(180px, 1fr)) !important;
  }

  .address_item p {
    margin: 0.2rem 0;
    font-size: 1.8vh;
    font-weight: 500 !important;
    color: var(--primaryTextColor);
  }

  .address_item p span {
    color: var(--secondaryTextColor);
    font-size: 2vh;
    font-weight: 700 !important;
  }

  .address_item_1 p {
    margin: 0.2rem 0;
  }

  .T-btn {
    cursor: pointer;
    font-size: smaller;
    color: var(--primaryTextColor);
  }

  .T-btn2 {
    color: var(--primaryTextColor);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}




@media (max-width: 1180px) {
  .address_container {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }

  .address_container1 {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}
