.my_order_sec {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    width: 100%;
    height: 100%;
    padding: 2% 5%;
}

.my_order_sec_left {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 40%;
}

.my_order_sec_left h2 {
    font-size: 3rem;
    font-weight: 400;
    color: var(--primaryTextColor);
    text-decoration: underline;
    text-decoration-thickness: 0.15rem;
}

.my_order_items {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.my_order_card {
    border: 1px solid var(--primary2ndColor);
    border-radius: 5px;
    height: 10.563rem;
    width: 100%;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.my_order_img {
    height: 8.938rem;
    width: 6.75rem;
    border-radius: 5px;
    border: 1px solid var(--primaryTextColor);
}

.my_order_img img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    object-fit: cover;
}

.my_order_card div:nth-child(2) p:nth-child(1) {
    color: var(--secondaryTextColor);
    font-size: 1.25rem;
    font-weight: 500;
}

.my_order_text {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 55%;
    justify-content: flex-start;
}
.my_order_text>p {
    color: var(--primaryTextColor) !important;
    cursor: pointer;
}
.my_order_text>p:hover {
    text-decoration: underline;
}

.my_order_price {
    color: var(--primaryTextColor) !important;
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
}

.my_order_qut {
    display: flex;
    gap: 1rem;
}

.my_order_qut_btn {
    border: 1px solid var(--primaryTextColor);
    border-radius: 4px;
    overflow: hidden;
    cursor: pointer;
}

.my_order_qut_btn span {
    height: 1rem;
    width: 1rem;
    padding: 0.2rem 0.8rem;
}

.my_order_qut_btn span:nth-child(2) {
    background-color: var(--primaryTextColor);
    color: var(--commonWhite);
}

.my_order_dlt_icon {
    justify-self: flex-end;
    height: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    cursor: pointer;
}

.my_order_dlt_icon p {
    background-color: var(--secondaryColor);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
    border-radius: 50%;
    height: 2rem;
    width: 2rem;
    font-size: 1.3rem;
    color: var(--primaryTextColor);
}


/* Pricing css  */
.my_order_pricing {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    padding: 0.8rem 0;
    border-top: 2px solid var(--primary2ndColor);
    border-bottom: 2px solid var(--primary2ndColor);
}

.my_order_pricing h3 {
    color: var(--primaryTextColor);
    font-size: 1.25rem;
    font-weight: 600;
    padding-left: 0.5rem;
}

.my_order_pricing .my_order_price_info {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
}

.my_order_pricing .my_order_price_info span {
    color: var(--secondaryTextColor);
    font-size: 1.1rem;
    font-weight: 500;
}

.my_order_pricing .my_order_price_info span:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
}

.my_order_price_total {
    color: var(--primaryTextColor);
    padding: 0.8rem 0.5rem 0 0.5rem;
    font-size: 1.2rem;
    font-weight: 600;
    display: flex;
    justify-content: space-between;
    border-top: 2px solid var(--primary2ndColor);
}
.my_order_price_total span:nth-child(2){
    display: flex;
    justify-content: center;
    align-items: center;
}

.my_order_sec_right {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
    width: 45%;
}

.my_order_right_heading {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
    padding-bottom: 0.8rem;
}

.my_order_right_heading h2 {
    color: var(--primaryTextColor);
    font-size: 2.5rem;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-thickness: 1.5px;
}

.my_order_right_heading P {
    color: var(--secondaryTextColor);
    font-size: 1.25rem;
    font-weight: 500;
    text-decoration: underline;
    text-decoration-thickness: 1.2px;
}

.my_order_form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}


.my_order_right_input {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.my_order_right_input label {
    font-size: 0.938rem;
    font-weight: 500;
    color: var(--secondaryTextColor);
}

.my_order_right_input input {
    width: 100%;
    font-size: 0.938rem;
    font-weight: 500;
    padding: 1.4rem 1rem;
    border-radius: 5px;
    border: 1px solid var(--primaryColor);
}

.my_order_two_input {
    display: flex;
    gap: 1rem;
}

.my_order_two_input .my_order_right_input {
    width: 50%;
}
.switch_button{
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2%;
}
.switch_button button{
    padding: 2% 3%;
    font-size: 1.25rem;
    font-weight: 500;
    background-color: var(--secondaryColor);
    color: var(--primaryTextColor);
    border-radius: 50px;
    border: none;
    outline: none;
    cursor: pointer;
}
.switch_button button:hover{
    box-shadow: 2px 4px 8px var(--primary2ndColor);
}


/* payment order css */

.my_order_payment {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.my_order_payment h1 {
    color: var(--primaryTextColor);
    font-size: 2.8rem;
    font-weight: 400;
    text-decoration: underline;
    text-decoration-thickness: 1.2px;
}

.my_order_payment_input {
    background-color: var(--baseColor);
    padding: 1rem 0.8rem;
    display: flex;
    align-items: center;
    gap: 0.4rem;
    border-radius: 5px;
}

.my_order_payment_input input {
    height: 1.3rem;
    width: 1.3rem;
    cursor: pointer;
}

.my_order_payment_input label {
    font-size: 1.1rem;
    font-weight: 600;
    color: var(--primaryTextColor);
}
.my_order_payment_input label span{
    font-size: 0.8rem;
    color: var(--secondaryTextColor);
}

.my_order_payment button {
    width: 100%;
    padding: 0.8rem 0;
    font-weight: 500;
    font-size: 1.25rem;
    border: none;
    border-radius: 2px;
    color: var(--baseColor);
    background-color: var(--primaryTextColor);
    cursor: pointer;
}

.address_form_add_btn{
    padding: 2% 3%;
    background-color: var(--primaryTextColor);
    color: var(--baseColor);
    border: none;
    font-size: 1rem;
    font-weight: 400;
    cursor: pointer;
}

@media  screen and (max-width: 768px) {
    .my_order_sec{
        flex-direction: column;
    }
    .my_order_sec_left h2{
        font-size: 1.25rem;
        text-decoration-thickness: 0.1rem;
    }
    .my_order_sec_left {
        width: 100%;
        gap: 1rem;
    }
    .my_order_img {
        height: 5.938rem;
        width: 4.75rem;
        display: flex;
    justify-content: center;
    align-items: center;
    }
    .my_order_card {
        padding: 0.6rem;
        height: auto;
        align-items: self-start;
    }
    .my_order_qut{
        gap: 0.2rem;
    }
    .my_order_qut_btn span{
        padding: 0.2rem 0.5rem;
    }
    .my_order_card div:nth-child(2) p:nth-child(1) {
        font-size: 0.9rem;
    }
    .my_order_text{
        height: auto;
    }
    .my_order_pricing h3 {
        font-size: 0.9rem;
    }
    .my_order_pricing .my_order_price_info span {
        font-size: 0.8rem;
    }
    .my_order_price_total{
        font-size: 0.9rem;
    }
    .switch_button{
        width: 100%;
        margin: 2% 0;
    }
    .switch_button button {
        font-size: 0.9rem;
    }
    .address_container2 {
        top: 45% !important;
        height: 50vh !important;
    }


    .my_order_sec_right{
        width: 100%;
        gap: 0;
    }

    .my_order_right_heading h2{
        font-size: 1.25rem;
    }
    .my_order_right_heading P {
        font-size: 0.9rem;
    }
    .my_order_right_input label{
        font-size: 0.6rem;
    }
    .my_order_right_input input{
        padding: 3.3% 3%;
        border-radius: 5px;
        font-size: 0.9rem;
    }
    .my_order_right_input input::placeholder{
        font-size: 0.6rem;
    }
    .my_order_payment h1 {
        font-size: 1.25rem;
    }
    .my_order_payment_input label{
        font-size: 0.9rem;
    }
    .my_order_payment_input input{
        height: 0.9rem;
        width: 0.9rem;
    }
    .my_order_payment_input{
        padding: 0.7rem 0.5rem;
    }
    .my_order_payment button {
        padding: 1rem 0;
        font-size: 0.9rem;
    }

}