.lab_services_container {
  width: 100%;
  padding: 0 3rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1.5rem;
  overflow: hidden;
}

.lab_services_head h2 {
  font-size: 3rem;
  font-weight: 600;
  color: var(--primaryTextColor);
}

.lab_services_head h2 span {
  color: var(--secondaryTextColor);
}

.lab_services_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}

.lab_services_btn button {
  font-size: 1.25rem;
  font-weight: 700;
  background-color: var(--primaryTextColor);
  color: var(--baseColor);
  padding: 0.8rem 1.5rem;
  border-radius: 50px;
  border: none;
  outline: none;
}

.isActiveBtn {
  background: linear-gradient(180deg, #4093c5 0%, #a5dcfb 100%);
  color: var(--baseColor);
  box-shadow: 2px 4px 6px 1px var(--secondaryColor);
}

.lab_services_item {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  grid-column-gap: 2rem;
  grid-row-gap: 2rem;
  align-items: center;
  justify-items: center;
}

.lab_services_card {
  height: 21.438rem;
  width: 21.438rem;
  background: linear-gradient(180deg, #a5dcfb 100%, #a5dcfb 100%);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: inset 2px 2px 3px var(--boxColor);
  transform: background 0.2s;
  cursor: pointer;
}

.lab_services_card:hover {
  background: linear-gradient(360deg, #4093c5 102%, #a5dcfb 0%);
}

.lab_service_card_content {
  position: absolute;
  z-index: 3;
  inset: 28px;
  border-radius: 50%;
  background: linear-gradient(360deg, #6cb5e1 0%, #196ea4 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  box-shadow: inset 2px 2px 3px var(--boxColor);
  cursor: pointer;
}


.withoutHover>h3 {
  font-size: 0.938rem;
  font-weight: 600;
  color: var(--baseColor);
  width: 67%;
  text-align: center;
}

.lab_services_card:hover .withoutHover {
  display: none;
}

.lab_services_card:hover .withHover {
  display: flex;
}

.withHover {
  display: none;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 1rem;
}

.withHover>h4 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--baseColor);
  opacity: 0;
}

.lab_services_card:hover h4 {
  opacity: 1;
}

.lab_service_btn {
  margin: 1rem;
  padding: 0.5rem 0.5rem;
  border-radius: 3.125rem;
  font-size: 1rem;
  font-weight: 500;
  background: transparent;
  color: var(--baseColor);
  border: 2px solid var(--baseColor);
  cursor: pointer;
  opacity: 0;
}

.lab_services_card:hover button {
  opacity: 1;
}

/*----- Mobile respnsive ----- */
@media only screen and (max-width: 768px) {
  .lab_services_head h2 {
    font-size: 1.5rem;
  }

  .lab_services_btn button {
    font-size: 0.625rem;
    padding: 0.8rem 1rem;
  }

  .lab_services_card {
    height: 9.438rem;
    width: 9.438rem;
  }

  .lab_services_btn {
    gap: 1rem;
  }

  .withoutHover {
    row-gap: 0.625rem;
  }

  .withoutHover>h3 {
    width: 100%;
    font-size: 0.638rem;
  }

  .withoutHover>img {
    width: 3rem;
    height: 3rem;
  }

  .withHover {
    row-gap: 0.425rem;
  }

  .withHover>h4 {
    font-size: 0.625rem;
  }

  .lab_service_btn {
    font-size: 0.5rem;
    margin: 0;
    padding: .3rem;
  }

  .lab_services_item {
    overflow: hidden;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 1rem;
    grid-row-gap: 1rem;

  }

  .lab_services_container {
    position: relative;
    padding: 1rem;

  }
}