.blogDescription_container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 2%;
}

.blogDescription_content {
  display: flex;
  justify-content: space-between;
  gap: 1%;
  overflow: auto;
}

.blogDescription_left_div {
  width: 65%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.blogDescription_left_div div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1.5rem;
}

.blogDescription_left_div h2 {
  font-size: 3rem;
  font-weight: 700;
  font-family: Montserrat;
  line-height: 4rem;
  color: var(--primaryColor);
  align-self: flex-start;
}

.blogDescription_left_div p {
  font-family: Montserrat;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.1rem;
  color: var(--secondaryTextColor);
  overflow: auto;
}

.blogDescription_right_div {
  /* width: 30%; */
}

.blogDescription_right_div img {
  height: 25.25rem;
  width: 30.875rem;
}

.yt-container {
  width: 100%;
  height: 80vh;
  display:flex;
  justify-content: center;
  align-items: center;
  /* background-color: rgb(220, 181, 181); */
  /* padding: 1rem 0; */
}
.yt_video{
    width:70%;
    height:75vh;
    border-radius:10px;
}

/* Media Queries for responsiveness */

@media screen and (max-width: 768px) {
  .yt-container {
    height: 65vh;
   
  }
  .yt_video{
    width:95%;
    height:50vh;
    border-radius:10px;
}
  .blogDescription_container {
    padding: 3%;
  }

  .blogDescription_content {
    flex-direction: column;
    align-items: center;
    gap: 2%;
  }

  .blogDescription_left_div {
    width: 100%;
    padding: 0;
  }

  .blogDescription_right_div {
    width: 100%;
  }

  .blogDescription_right_div img {
    height: auto;
    width: 100%;
  }

  .blogDescription_left_div h2 {
    font-size: 2.2vh;
    text-align: center;
    align-self: center;
    line-height: 2rem;
  }

  .blogDescription_left_div p {
    text-align: center;
    font-size: 1.5vh;
    font-weight: 500;
  }

  .blogDescription_left_div div {
    gap: 0;
  }

  .blogDescription_content:nth-child(2) .blogDescription_left_div {
    order: -1;
  }
}

/* .... desktop responsive ...... */

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199px) {
  .yt_video{
    width:80%;
    height:75vh;
    border-radius:10px;
}
}
