.OrderConfirmed-cntainer {
  width: 100%;
  height: 100vh;
}
.orderconcheck {
  width: 100%;
  height: 80vh;
  background: var( --baseColor) 0% 0% no-repeat padding-box;
  display: flex;
  justify-content: center;
  align-items: center;
}
.box-ordercon {
  width: 50%;
  height: 70vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.box-ordercon h1 {
  text-align: center;
  font: normal normal bold 4.75rem/5.8125rem Montserrat;
  letter-spacing: 0rem;
  color: var( --primaryTextColor);
  opacity: 1;
}

.ceckorder-anim1 {
  width: 12.5rem;
  height: 12.5rem;
  border-radius: 100%;
  border: .1875rem solid rgba(23, 86, 126, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1.25rem rgba(23, 86, 126, 0.5);
  animation:  zoomOut 2s ease forwards;

}
.ceckorder-anim2 {
  width: 10rem;
  height: 10rem;
  border-radius: 100%;
  border: .1875rem solid rgba(23, 86, 126, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1.875rem rgba(23, 86, 126, 0.5);
  animation:  zoomOut 1.5s ease forwards;
}
.ceckorder-anim3 {
  width: 6.875rem;
  height: 6.875rem;
  border-radius: 100%;
  border: .1875rem solid rgba(23, 86, 126, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1.875rem rgba(23, 86, 126, 0.5);
  animation:  zoomOut 1s ease forwards;
}
.check-order-bt {
  width: 3.75rem;
  height: 3.75rem;
  border-radius: 100%;
  background-color: var( --primaryTextColor);
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 1.875rem rgba(23, 86, 126, 0.5);
  animation:  zoomOut 0.5s ease forwards;
}
.visi{
  visibility: visible;
}
.check-check {
  font-size: 2rem;
  color: var(--baseColor);
}
.box-ordercon h3 {
  text-align: center;
  font: normal normal 600 2.875rem/3.5625rem Montserrat;
  letter-spacing: 0rem;
  color: var( --primaryTextColor);
  text-transform: capitalize;
  opacity: 1;
}

.para-p {
  width: 60%;
  height: auto;
}
.para-p p {
  text-align: center;
  font: normal normal medium 1.25rem/1.5rem Montserrat;
  letter-spacing: 0rem;
  color: var( --secondaryTextColor);
  text-transform: capitalize;
  opacity: 1;
}

.order-button-con {
  width: 100%;
  height: 10vh;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.25rem;
}
.or-btn1 {
  width: 12.5rem;
  height: 2.8125rem;
  font: normal normal bold 1.25rem/0rem "Montserrat";
  letter-spacing: 0rem;
  background-color: var(--primaryTextColor);
  opacity: 1;
  border: none;
  color: var(--baseColor);
  border-radius: 1.25rem;
  cursor: pointer;
  cursor: pointer;
}
.or-btn2 {
  width: 17.5rem;
  height: 2.8125rem;
  font: normal normal bold 1.25rem/0rem "Montserrat";
  letter-spacing: 0rem;
  background-color: transparent;
  opacity: 1;
  border: .0625rem solid var( --primaryTextColor);
  color: var(--baseColor);
  border-radius: 1.25rem;
  color: var( --primaryTextColor);
  cursor: pointer;
  cursor: pointer;
}



@keyframes zoomOut {
 
    from {
      transform: scale(0);
    }
    to {
      transform: scale(1);
    }
  
}




/* Media queries for responsiveness */
@media screen and (max-width: 768px) {
  .OrderConfirmed-cntainer {
    width: 100%;
    height: auto;
    padding: 0 0 1rem 0;
  }
  .box-ordercon {
    width: 95%;
    height: auto;
  }
  .or-btn1 {
    width: 8.125rem;
    height: 2.8125rem;
    font: normal normal bold 1rem/0rem "Montserrat";
  }
  .or-btn2 {
    width: 12.5rem;
    height: 2.8125rem;
    font: normal normal bold 1rem/0rem "Montserrat";
  }
  .para-p {
    width: 95%;
    height: auto;
}
.para-p p {
  font-size: 2vh;
}
.box-ordercon h3 {
  font: normal normal 600 1.75rem / 3.5625rem 'Montserrat';
}
.box-ordercon h1 {
  font: normal normal bold 3.125rem / 5.8125rem 'Montserrat';
}

}