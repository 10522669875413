.search_item {
    position: relative;
}

.search_item_search_div {
    position: relative;
    width: 40vw;
}

.search_icon {
    position: absolute;
    color: var(--secondaryTextColor);
    top: 49%;
    font-size: 3vh;
    font-weight: 500;
    right: 5%;
}

.search_icon_two {
    position: absolute;
    color: var(--secondaryTextColor);
    top: 3%;
    font-size: 4vh;
    font-weight: 500;
    right: 5%;
}

.search_items_component {
    position: fixed;
    left: 0;
    top: 16%;
    transform: translate(50%, 50%);
    z-index: 10000;
}

.search_item_search_div input {
    border-radius: 3.125rem;
    background: var(--baseColor);
    box-shadow: var(--boxShadow);
    width: 100%;
    height: 7vh;
    border: none;
    outline: none;
    font-size: 3vh;
    padding: 0% 5% 1% 8%;
    margin-top: 5%;
    color: var(--boxShadow);
}

.search_item_search_div input::placeholder {
    font-size: 2vh;
    font-weight: 500;
}

.search_dropdown p {
    font-size: 1rem;
    font-weight: 400;
    color: var(--primaryTextColor);
    cursor: pointer;
    border-bottom: 1px solid var(--boxColor);
}

.search_pop_up h5 {
    color: var(--primaryTextColor);
    cursor: pointer;
    position: absolute;
    top: 2%;
    right: 2%;
    background-color: var(--baseColor);
    font-size: 3vh;
    font-weight: 600;
    padding: 0 1%;
    border-radius: 2px;
}


.search_pop_up {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    z-index: 100;
    animation: slideIn 0.5s ease forwards;
    background-color: var(--boxColor);
}

@keyframes slideIn {
    0% {
        transform: translateY(100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.search_pop_up_div {
    width: 50vw;
    height: 100%;
    position: relative;
    z-index: 10000;
    animation: slideOut 1s ease forwards;
    animation-delay: 0.5s;
    opacity: 0;
}

.search_pop_up_input {
    border-radius: 3.125rem;
    background: var(--baseColor);
    box-shadow: var(--boxShadow);
    width: 100%;
    height: 7vh;
    border: none;
    outline: none;
    padding-left: 8%;
    margin-top: 0;
    color: var(--boxShadow);
    font-size: 3vh;
    font-weight: 600;
}
.search_pop_up_input::placeholder{
    font-size: 3vh;
    font-weight: 500;
}

@keyframes slideOut {
    0% {
        transform: translateY(-100%);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.search_dropdown {
    position: absolute;
    box-shadow: 2px 3px 4px #000;
    padding: 2%;
    background-color: var(--commonWhite);
    width: 50vw;
    border-radius: 10px;
    max-height: 33vh;
    overflow-y: scroll;
    margin-top: 1%;
    z-index: 101;
}
.search_dropdown p{
    font-size: 3vh;
}

.search_dropdown::-webkit-scrollbar {
    width: 12px;
    box-shadow: 0px 4px 4px 0px rgba(16, 16, 16, 0.25) inset;
    border-radius: 50px;
}

.search_dropdown::-webkit-scrollbar-thumb {
    background-color: var(--secondaryColor);
    border-radius: 50px;
}

.search_dropdown::-webkit-scrollbar-track {
    border-radius: 10px;
}


@media screen and (max-width:768px) {
    .search_pop_up_div {
        width: 80vw ;
        display: block;
    }

    .search_icon {
        font-size: 1.5rem;
        top: 43%;
    }

    .search_pop_up_input {
        height: 6vh;
        font-weight: 500;
        padding-right: 22% ;
        padding-left: 6%;
        font-size: 2vh;
        font-weight: 500;
    }

    .search_item_search_div {
        width: 68vw;
    }

    .search_item_search_div input::placeholder {
        font-size: 2vh;

    }

    .search_pop_up_input::placeholder {
        font-size: 2vh;

    }

    .search_items_component {
        transform: translate(13%, 26%);
    }

    .search_icon_two {
        top: 28%;
        font-size: 3vh;
    }

    .search_item {
        width: 100%;
        padding: 0 6%;
        display: flex;
        justify-content: center;
    }

    .search_item_search_div input {
        height: 2.5rem;
    }
    .search_item_list p {
        font-size: 0.8rem;
    }

    .search_item_list::-webkit-scrollbar {
        width: 8px;
    }

    .search_dropdown {
        width: 100%;
        height: 32vh;
        z-index: 101;
        padding: 0;
    }

    .search_dropdown p {
        font-size: 1.5vh;
        padding: 0.5rem;
        text-align: center;
    }
}

@media screen and (min-width:2560px) {
        .search_icon {
        top: 58% ;
    }
}

@media only screen and (min-width:1024px) {
    .search_icon_two {
        top: 22%;
    }
    .search_icon {
        top: 44%;
    }
}

@media only screen and (min-width:1280px) {
    .search_icon {
        top: 54%;
    }
}

@media only screen and (min-width:1380px) {
    .search_icon {
        top: 58%;
    }
}
@media only screen and (min-width:1440px) {
    .search_icon {
        top: 53%;
    }
}

@media only screen and (min-width:1500px) {
    .search_icon {
        top: 55%;
    }
}


@media only screen and (min-width:1920px) {
    .search_item_search_div input::placeholder {
        font-size: 1.2vw;
    }
    .search_icon {
        top: 55%;
    }
}