.section2main {
  width: 100%;
  height: 100%;
  background-color: var(--BackgroundWithOpacity);
  padding: 3rem 0 ;
  margin:0 0 1rem 0;
}

.section2main h1 {
  color: var(--primaryTextColor);
  text-align: center;
  font-family: Montserrat;
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 0 0 2rem 0;
}

.section2main h1 span {
  color: var(--secondaryTextColor);
}

.section2content {
  display: grid;
  width: 90%;
  margin: auto;
  justify-items: center;
  gap: 1.5rem;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.usefulCard {
  width: 278px;
  height: 206px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.6rem;
  padding:2rem 1rem;
  border-radius: 10px;
  background-color: var(--baseColor);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.usefulCard .usefulImg {
  width: 6.875rem;
  height: 6.875rem;
  border-radius: 50%;
 position: relative;
  bottom: 0;
}

.usefulImg img {
  height: 5rem;
  width: 5rem;
  position: absolute;
  bottom: 9%;
  right: 15%;
  border-radius: 50%;
  background-color: var(--primaryTextColor);
  padding: 1%;
  object-fit: contain;
}


.usefulText p {
  color: var(--secondaryTextColor);
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}