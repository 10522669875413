.productCategory {
    width: 100%;
    height: 90vh;
    background: var(--thirdBackground);
    display: flex;
    align-items: center;
    justify-content: center;
}

.heroContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

}

.textContainer {
    flex: 1;
    padding: 0 3rem;
}
.textContainer p:first-child {
    color: var(--primaryTextColor);
    font-weight: 500;
    font-size: 1rem;

}

.textContainer h1 {
    margin: 1rem 0;
    color: var(--primaryTextColor) !important;
    font-size: 3vw;
}
.textContainer p:nth-child(2){
    color: var( --secondaryTextColor);
}
.imageContainer {
    margin-top: 2rem;
    width: 39vw;
}

.imageContainer img {
    max-width: 100%;
    height: auto;
}


/* Media query for screens smaller than 768px */
@media only screen and (max-width: 768px) {
    .productCategory{
        height: 60vh;
    }
    .heroContent {
        flex-direction: column;
        align-items: center;
    }

    .textContainer {
        padding: 1rem;
        text-align: center;
    }
    .imageContainer{
        display: none;
    }
    .textContainer h1 {
        font-size: 1.5rem;
    }
    .textContainer p:first-child {
        font-size: 0.8rem;
    }
}

/* Media query for screens smaller than 480px */
@media only screen and (max-width: 480px) {
    .productCategory{
        height: 50vh;
    }
    .textContainer {
        padding: 0.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}