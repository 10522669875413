.footerMain{
  position: relative;
}
.footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  background: linear-gradient(var(--secondaryColor), var(--primaryColor));
  color: var(--baseColor);
  height: fit-content;
  width: 100%;
  padding: 2rem 2.2rem;
}

.footerSections {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footerSection1 {
  width: 50%;
  row-gap: 2rem;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.footerSection1 .footerSection1_first_div {
  width: 92%;
}

.footerSection1 .footerSection1_first_div img {
  width: 100%;
}

.footerSection1 p {
  letter-spacing: 0.05rem;
  font-size: 1.1vw;
  font-weight: 500;
}

.footer-email {
  position: relative;
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
}

.footer-email h2 {
  font-size: 1.25rem;
  font-weight: 600;
}

.footer-email .footer-email-div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.footerSection1 input {
  width: 27vw;
  height: 2.7rem;
  background-color: var(--secondaryColor);
  padding-left: 5%;
  color: var(--primaryTextColor);
  outline: none;
  border-radius: 4px;
  border: none;
  outline: none;
}

.footerSection1 input::placeholder {
  color: var(--primaryTextColor) !important;
  font-weight: 600 !important;
  font-size: 0.9rem !important;
}

.footer-email button {
  color: var(--baseColor);
  background-color: var(--primaryTextColor);
  padding: 0.9rem 1.4rem;
  font-weight: 500;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}

.footer-email button:hover {
  background: linear-gradient(var(--secondaryColor), var(--primaryColor));
}

.footer-right-sec {
  display: flex;
  gap: 1rem;
}

.footerSection2 {
  width: 100%;
}

.footerSection2 ul {
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footerSection2 ul li h3 {
  color: var(--primaryTextColor);
}

.footerSection2 ul li {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.footerSection2 ul li img {
  margin-right: 10px;
}

.footerSection3 {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.footerSection2-Quick-Links,
.footerSection2-contactus-heading {
  font-size: 1.6vw;
  color: var(--primaryTextColor);
  cursor: default;
}

.footerSection2-Charlie {
  color: rgba(184, 189, 187, 0.042) !important;
  opacity: 0;
}

/* .footerSection2-Quick-Links{
  margin-bottom: 1vh;
} */
.footerSection3 img {
  margin: 0 12%;
}

.more {
  cursor: pointer;
  display: none;
}

.res-view {
  display: none;
}

.res-mobile {
  display: block;
}

.copyright {
  position: absolute;
  /* bottom: 0; */
  cursor: pointer;
  transform: translate(-50%, -50%);
  left: 50%;
  background: var(--thirdBackground);
  width: 100%;
  height: 4vh;
  margin: auto 0;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--primaryColor);
}

.copyright>span {
  cursor: pointer;
}

/* -- media query --- */
@media only screen and (max-width: 768px) {
  .footer {
    height: auto;
    width: 100%;
    padding: unset;
    padding: 0.625rem;
    padding-bottom: 3rem;
  }

  .footerSection2 ul li h3 {
    font-size: 3.5vw;
  }

  .footerSection2 ul li p {
    font-size: 2.5vw;
  }

  .footerSection2-Charlie {
    color: rgba(184, 189, 187, 0.042) !important;
    display: none !important;
  }

  .footerSections {
    width: 95%;
    display: flex;
    flex-direction: row-reverse;
    height: auto;
  }

  .footerSection2 {
    width: 100%;
    display: flex;
  }



  .footerSection1 .footerSection1_first_div img {
    position: absolute;
    left: 15px;
    width: 90%;
  }


  .footer-right-sec {
    width: 30%;
    display: flex;
    flex-direction: column;
    margin-top: 2%;
    margin-top: 30px;
  }

  .footerSection1 {
    width: 50%;
    margin-right: unset;
  }

  .footerSection1 p {
    display: none;
  }

  .footer-email h2 {
    text-align: start;
    font-size: 12px;
    font-weight: 500;
  }

  .footer-email-div input {
    width: 100%;
  }

  .footer-email button {
    display: none;

  }

  .footer-email {
    margin-top: 130px;
  }

  #re-hide {
    display: none;
    margin-top: -20px;
  }

  .more {
    font-size: 2.2vw !important;
    display: block;
    font-weight: 500;
  }

  .res-view {
    width: auto;
    height: auto;
    display: flex;
    gap: 1rem;
  }

  .button-res {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    background-color: #92CEF1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .icon-res {
    font-size: 20px;
  }

  .res-mobile {
    display: none;
  }

  .a-res {
    text-decoration: none;
    color: white;
    margin-top: 0.225rem;
  }

  .footerSection1 input::placeholder {
    font-weight: 400 !important;
    font-size: 0.625rem !important;
  }

  .copyright {
    /* position: absolute;
    bottom: 0vw;
    cursor: pointer;
    transform: translate(-50%, -50%);
    left: 50%;
    margin: -1vh 0;
    width: 56%; */
    display: block;
    text-align: center;
    padding: 2% 0;
    padding-bottom: 100px;
}
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px) {

  .footer {
    gap: 2rem;
  }

  .footerSection1 {
    width: 80%;
  }

  .footerSection1 img {
    width: 90%;
  }

  .footerSection1 p {
    width: 400px;
  }
}

@media only screen and (max-width:1280px){
  .footer{
    padding:2rem;
    height: fit-content;
  }
  .footerSections p{
    font-size: 1.25vw !important;
    word-spacing: 0.2rem;
  }
}
@media only screen and (min-width:2560px){
  .footer{
    padding:3rem;
    height: fit-content;
  }
  .footerSections p{
    font-size: 1vw;
  }
} */