.section3main {
  display: flex;
  width: 100%;
  margin:0 0 1rem 0;
  padding: 0 3rem;
  align-items: center;
  justify-content: center;
}
.section3content {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
}
.section3img{
    width: 50%;
}
.section3img img{
    width: 100%;
    object-fit: cover;
}
.section3text{
    width: 38%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
}
.section3text span {
  color: var(--primaryTextColor);
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.section3text h1 {
  color: var(--secondaryTextColor);
  font-family: Montserrat;
  font-size: 3rem;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.section3text p {
  color: var(--secondaryTextColor);
  font-family: Montserrat;
  font-size: 0.9375rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.section3Button{
  align-self: center;
  align-self: center;
  padding: 0.9rem 1.3rem;
  border-radius: 14px;
  border: none;
  outline: none;
  background-color: var(--primaryColor);
  color: var(--baseColor);
  font-size: 1rem;
  font-weight: 400;
  cursor: pointer;
}

@media screen and (max-width: 765px){
  .section3content{
    flex-direction: column;
    gap: 2rem;
  }
  .section3img{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .section3text {
    width: 100%;
    order: -1;
    align-items: center;
    gap: 0.8rem;
  }
  .section3text h1{
    font-size: 1.5rem;
  }
  .section3text span {
    font-size: 1.5rem;

  }
  .section3text p {
    font-size: 0.65rem;
    text-align: center;
  }
  .section3Button {
    padding: 0.5rem 0.7rem
  }
  .section3img img {
    height: 18rem;
    width: 21.5rem;
  }
}