.loader {
    position: absolute;
    z-index: 100;
    height: 100%;
    width: 100%;
    display: flex;
    opacity: 80%;
    background-color: #F2EFEF;
    justify-content: center;
    align-items: center;
    color: #4093C5;
}

@keyframes TbLoaderAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  .TbLoader {
    width: 50px;
    height: 50px;
    animation: TbLoaderAnimation 2s linear infinite;
  }

@media screen and (max-width: 600px) {
    .loader div img {
        height: 8rem;
        width: 10rem;
    }
}