.return-order-container {
  width: 100%;
  height: 100vh;
  background: transparent linear-gradient(319deg, rgb(174, 174, 174) 0%, #03080d73 42%, #112742 100%) 0% 0% no-repeat padding-box;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
}

.return-order-card {
  width: 50%;
  height: 35rem;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  position: relative;
}

.header-container {
  width: 100%;
  height: 4rem;
  background-color: var(--primaryTextColor);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
}

.header-container h1 {
  font-size: 20px;
  color: var(--baseColor);
}

.header-container h5 {
  font-size: 30px;
  color: var(--baseColor);
  cursor: pointer;
}

.return-op {
  width: 100%;
  height: 80%;
  display: flex;
  flex-direction: column;
  gap: 100px;
}

.return-op p {
  font-size: 20px;
  margin-left: 2rem;
  color: var(--secondaryTextColor);
}

.text {
  width: 100%;
  height: auto;
  padding: 2%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
}

.text textarea {
  width: 100%;
  height: 7rem;
  padding: 0.6%;
  border: none;
  border-radius: 5px;
  outline: 0;
  font-size: 1.125rem;
  border: 1px solid rgb(209, 209, 209);
}

.image_upload_container {
  display: flex;
  width: 100%;
  padding: 4% 0%;
  column-gap: 1%;
}

.button-container {
  width: 95%;
  height: auto;
  display: flex;
  justify-content: end;
  align-items: center;
}

.button-container button {
  padding: 2% 2%;
  cursor: pointer;
  border: none;
  font-weight: 600;
  background-color: var(--primaryTextColor);
  color: var(--baseColor);
  width: 22%;
  position: absolute;
    bottom: 2%;
    right: 2%;
}

.return_order_img {
  height: 19vh;
  width: 12vw;
  object-fit: contain;
}

.return_order_img_first {
  height: 19vh;
  width: 12vw;
  object-fit: contain;
}

.return_img_iup_div {
  width: 15%;
  height: 13vh;
  border: 2px solid var(--primaryTextColor);
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.return_img_iup_div_plush_sign{
  font-size: 6vh;  
  font-weight: 600;
}

.return_img_iup_div>img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.select-products-container {
  padding: 1vw;
  height: 79%;
}

.select-products-container h3 {
  color: var(--primaryTextColor);
}

.select-products-container>button {
  padding: 2% 2%;
  cursor: pointer;
  border: none;
  font-weight: 600;
  background-color: var(--primaryTextColor);
  color: var(--baseColor);
  width: 22%;
  position: absolute;
  right: 2%;
  bottom: 2%;
}

.productNameReturn,
.quantityReturn,
.sellingPriceReturn {
  display: flex;
  align-items: start;
  flex-direction: row;
  gap: 0.3vw;
  justify-content: start;
}

.productValueDiv {
  color: var(--primaryTextColor);
  font-weight: 500;
  font-size: 1vw;
}

.products-list {
  display: flex;
  justify-content: flex-start;
  column-gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
  padding: 1vw 0;
  row-gap: 2vh;
  height: 55vh;
  overflow: auto;
}

.product-item {
  display: flex;
  align-items: start;
  flex-direction: column;
  justify-content: space-between;
  padding: 1vw;
  width: 30%;
  height: 200px;
  border: 1px solid green;
  position: relative;
}

.productImageReturn {
  height: 6vw;
  width: 6vw;
  overflow: hidden;
}

.productImageReturn>img {
  height: 100%;
  width: 100%;
}

.product-item>input {
  width: 1.5vw;
  position: absolute;
  top: 0.1vh;
  right: 0.6vw;
}
.Order_history_card input{
  position: absolute;
  top: 2%;
  left: 2%;
}

@media only screen and (max-width: 768px) {
  .return-order-card {
    width: 95%;
    height: 80vh;
  }
  .return_img_iup_div {
    width: 27%;
  }

  .products-list {
    grid-template-columns: repeat(1, minmax(200px, 1fr));
    display: grid;
    justify-content: flex-start;
    column-gap: 1rem;
    width: 100%;
    padding: 1vw 0;
    row-gap: 2vh;
    height: 72vh;
    overflow: auto;
  }

  .product-item {
    margin: auto;
    display: flex;
    align-items: start;
    flex-direction: column;
    justify-content: space-between;
    padding: 3vw;
    width: 90%;
    height: 22vh;
    border: 1px solid green;
    position: relative;
  }

  .select-products-container {
    height: 84vh;
  }

  .select-products-container>h3 {
    padding: 0 2vh;
  }

  .return-op p {
    font-size: 1.25rem;
    margin-left: 1rem;
    margin-top: 1rem;
  }

  .productImageReturn {
    height: 12vh;
    width: 12vh;
    overflow: hidden;
  }

  .productImageReturn>img {
    height: 100%;
    width: 100%;
  }

  .productValueDiv {
    color: var(--primaryTextColor);
    font-weight: 500;
    font-size: 2vh;
  }

  .product-item>input {
    width: 3vh;
    position: absolute;
    top: 0.1vh;
    right: 1vh;
  }

  .select-products-container>button {
    padding: 1.3vh 3vh;
    margin: 0.6vh 2vh;
  }
}