.profile_screen_pup_up {
    position: absolute;
    background-color: var(--secondaryColor);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 2%;
    border-radius: 1.1rem;
}

.profile_screen_pup_up input {
    height: 5.8vh;
    border-radius: 1.1rem;
    background: var(--thirdBackground);
    border: 2px solid var(--primaryTextColor);
    font-weight: 500;
    padding: 1rem;
    outline: 0;
}
.profile_screen_pup_up input::placeholder{
    font-weight: 500;
}

.profile_screen_pup_up_input{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 3%;
}

.profile_screen_pup_up button {
    padding: 2.4% 3%;
    border-radius: 1rem;
    border: 2px solid var(--primaryTextColor);
    outline: none;
    background: none;
    color: var(--primaryTextColor);
    font-weight: 600;
    font-size: 1vw;
    cursor: pointer;
}

.profile_screen_pup_up_cross_btn{
    position: fixed;
    top: 5%;
    right: 4%;
    font-size: 1.6vw;
    font-weight: 600;
    color: var(--primaryTextColor);
    cursor: pointer;
}

@media only screen and  (max-width: 768px){
    .profile_screen_pup_up{
        width: 75vw;
        height: 18vh;
    } 
    .profile_screen_pup_up_cross_btn {
        font-size: 2.6vh;
    }
    .profile_screen_pup_up input {
        height: 4.8vh;
    }
    .profile_screen_pup_up button {
        padding: 2% 5%;
        font-size: 2vh;
    }
    .profile_screen_pup_up_input {
        gap: 1%;
    }
}
