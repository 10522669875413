.home-hero {
  height: 100vh;
  display: flex;
  align-items: center;
  padding-left: 3%;
  width: 100%;
  justify-content: space-between;
  background-repeat: no-repeat;
  background-position-x: 70vw;
}

.home-hero-left {
  width: 50%;
  height: 100%;
  justify-content: center;
  display: flex;
  flex-direction: column;
}

.home-hero-left h1 {
  font-weight: 500;
  font-size: 8vh;
  padding-bottom: 2%;
}

.home-hero-left h2 span {
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-hero-left {
  color: var(--boxColor);
  font-family: Montserrat;
  font-size: 0.938rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.home-hero-left p {
  margin-top: 2%;
}

.home-hero-search {
  position: relative;
}

.home-hero-right {
  width: auto;
  height: 100vh;
}

.home-hero-right img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* Responsive styles */
@media only screen and (max-width: 768px) {
  .home-hero {
    height: 100%;
    position: relative;
    padding: 10rem 0;
    background-position-x: 0vw;
    background: none;

  }

  .home-hero-left {
    width: 100%;
    position: absolute;
    text-align: center;
    justify-content: center;
    padding: 10% 0 0 0;
  }

  .home-hero-right {
    display: none;
  }

  .home-hero-right img {
    margin-left: 100%;
  }

  .home-hero .home-hero-left p {
    font-size: 1.6vh;
    font-weight: 600;
  }

  .home-hero-left p {
    font-size: 0.5rem;
  }

  .home-hero-search img {
    top: 35%;
    right: 20%;
    height: 2.2rem;
  }

  .home-hero-left h1 {
    font-size: 1rem;
    padding: 0;
  }

  .home-hero-left h2 span {
    font-size: 1.2rem;

  }
}

/* Additional media queries for smaller devices like 6.2-inch phones */
@media only screen and (max-width: 415px) {
  .home-hero-left h1 {
    font-size: 1.5rem;
    padding: 0;
  }

  .home-hero-left h2 span {
    font-size: 1.2rem;
  }
}

@media only screen and (min-width:1920px) {
  .home-hero-left .search_by_medicine_text {
    font-size: 2vw;
  }
}
@media only screen and (max-width:1900px) {
  .home-hero-left .search_by_medicine_text {
    font-size: 1.8vw;
    font-weight: 500;
  }
}