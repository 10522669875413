.exploreprincipleC-main {
  width: 100%;
  height: fit-content;
  background: rgba(165, 220, 251, 0.20);
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
}
.exploreprinciple-content .heading {
  color: var(--secondaryTextColor);
  font-size: 1.25rem;
  font-weight: 500;
  width: 45%;
  margin-bottom: 2rem;
}
.exploreprinciple-content .heading span {
  color: var(--primary2ndColor);
}
.exploreprinciple-content p {
  margin-top: 4%;
  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--secondaryTextColor);
}
.exploreprinciple-content .authorHeading {
  color: var(--primary2ndColor);
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 6%;
}

.exploreprincipleC-main {
  width: 100%;
  height: fit-content;
  background: rgba(114, 113, 113, 0.1);
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: center;
}
.exploreprinciple-content {
  width: 100%;
  margin: 6% 0;
  padding: 0 2rem;
}
.exploreprinciple-content p.heading{
  margin-top: 0rem;
}
.exploreprinciple-content .heading {
  color: var(--secondaryTextColor);
  font-size: 1.25rem;
  font-weight: 500;
  width: 45%;
  margin-bottom: 4%;
}
.exploreprinciple-content .heading span {
  color: var(--primary2ndColor);
}
.exploreprinciple-content p {
  margin-top: 2%;
  font-size: 0.9375rem;
  font-weight: 400;
  color: var(--secondaryTextColor);
}
.exploreprinciple-content .authorHeading {
  color: var(--primary2ndColor);
  font-size: 1.25rem;
  font-weight: 500;
  margin-top: 4%;
}
/* ------- media query ------ */
@media only screen and (max-width: 768px) {
  .exploreprinciple-content{
    padding: 0 0.625rem;
  }
  .exploreprinciple-content .heading {
    width: 100%;
    font-size: 0.625rem;
    text-align: center;
  }
  .exploreprinciple-content p {
    font-size: 0.625rem;
    text-align: center;
  }
  .exploreprinciple-content .authorHeading {
    font-size: 0.625rem;
    text-align: center;
  }
}