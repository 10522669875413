.about-hero {
  height: 80vh;
  display: flex;
  align-items: center;
  padding: 0 2%;
  background: var(--BackgroundWithOpacity);
}
.about-hero-left {
  width: 64%;
}


.about-hero-left h1 {
  font-size: 3.5vw;
  font-weight: 500;
}
.about-hero-left h3 {
  font-weight: 1.25rem;
  font-weight: 500;
  margin: 4% 0;
}
.about-hero-left p {
  font-size: 1vw;
  font-weight: 400;
  color: var(--secondaryTextColor);
  /* margin-bottom: 3%; */
}
.about-hero-right {
  width: 40%;
}
.about-hero-right img {
  width: 100%;
  height: 100%;
}

.mobileview {
  display: none;
}

/*..... media query ..... */
@media only screen and (max-width: 768px) {
  .about-hero {
    flex-direction: column;
    padding: 4% 0 3% 0;
    height: auto;
   
  }
  .about-hero-left {
    width: 90%;
  }
  .about-hero-left h1 {
    text-align: center;
    font-size: 1.5rem;   
  }
  .about-hero-left h3 {
    text-align: center;
    font-size: 3vw;
  }
  /* div.about-us-secondHeading-Clinic-esta{
    font-size: 6vw !important;
  } */
  .about-hero-left p {
    font-size: 2.5vw;
    word-spacing: 0.1rem;
    letter-spacing: 0.02rem;
    padding:0 1rem;
    text-align: start;
  }
  .about-hero-right {
    display: none;
  }
  .mobileview {
    display: block;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4% 0 0;
  }
  .mobileview img {
    width: 11.313rem;
    height: 10.438rem;
  }
  .about-partners-bottom{
    display: none;
  }
  
}

@media screen and (min-width:1600px){
  .about-hero-left h1{
    font-size: 3.5vw !important;
  }
  .about-us-secondHeading-Clinic-esta{
    font-size: 1.5vw !important;
  }
}
